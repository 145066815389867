import { getAllTimezones, getOffsetHours, timezone } from "../../../../utils/timezones";
import i18next from "../../../../i18next";
const { t } = i18next;

export default function TimeZone(props) {
  const handleChangeTimezone = (e) => {
    props.update_fn({
      ...props.state,
      time_zone : e.target.value
    });
  }

  return (
    <div>
      <div className="py-2">
        <div className="my-2">{t("what_time_zone_is_your_organization_in")}</div>
        <div className="flex">
          <div className="flex-auto m-1">
            <select type="" name="time_zone" onChange={handleChangeTimezone} className="w-full border border-slate-300 rounded-md">
              {
                getAllTimezones().map((zone, index) => {
                  return <option selected={props.state.time_zone === zone.name} key={`${zone.name}-${index}}`} value={zone.name}>{zone.name} ({getOffsetHours(zone.offset)})</option>
                })
              }
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

import i18next from "../i18next";
const { t } = i18next;

function getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
}

export const createCalendarDates = (month, year) => {
    let daysInMonth = getDaysInMonth(month, year);
    //console.log("Days in month are ", daysInMonth);
    let firstDay = daysInMonth[0].getDay();
    let lastDay = daysInMonth[daysInMonth.length - 1].getDay();
    let daysBefore = [];
    let daysAfter = [];
    let days = [];
    let dayBefore = new Date(year, month, 0);
    let dayAfter = new Date(year, month + 1, 1);

    //console.log(`Day before ${dayBefore}`);
    //console.log(`Day after ${dayAfter}`);

    for (let i = 0; i < firstDay; i++) {
      daysBefore.push(new Date(dayBefore));
      dayBefore.setDate(dayBefore.getDate() - 1);
    }

    for (let i = 0; i < 6 - lastDay; i++) {
      daysAfter.push(new Date(dayAfter));
      dayAfter.setDate(dayAfter.getDate() + 1);
    }
    
    //console.log("Days before are ", daysBefore);
    //console.log("Days after are ", daysAfter);

    //days = [...daysBefore.reverse(), ...daysInMonth, ...daysAfter];
    days = daysInMonth;
    return days.map((day) => {
      const small_date = day.toISOString().split('T')[0];
      return day = {
        date: small_date,
        isCurrentMonth: (new Date(small_date)).getMonth() === month,
        isToday: small_date === new Date().toISOString().split('T')[0],
        posts_number : 1,
        day_of_week : day.getDay()
      };
    });
  
  }

  export const getNameOfMonth = (month) => {
    // const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const months = [t('january'), t('february'), t('march'), t('april'), t('may'), t('june'), t('july'), t('august'), t('september'), t('october'), t('november'), t('december')];
    return months[month];
  }
import icon from '../../res/img/icon-transparent.png';

import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin, } from '@react-oauth/google';
import { FacebookProvider, LoginButton, useLogin } from 'react-facebook';
import { get_user_info, signin_with_email } from '../../utils/api';
import { useEffect, useState } from 'react';
//const { OAuth2Client } = require('google-auth-library');
import { LinkedIn, useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import linkedinIcon from '../../res/img/linkedin-icon.png';
import { openLinkedinLogin } from '../../utils/channels';
import i18next from '../../i18next';
import { NavLink, useParams } from 'react-router-dom';
import { Dot } from 'react-animated-dots';
import AuthLayout from './AuthLayout';

const { t } = i18next;

export default function Login() {

  const [display_loading, set_display_loading] = useState("none");
  const [waiting_for_google, set_waiting_for_google] = useState(true);

  const { referral_code } = useParams();

  if (referral_code) {
    localStorage.setItem("referral_code", referral_code);
  }


  if (localStorage && localStorage.getItem("token")) {
    window.location.href = "/";
  }

  const [sign_in_info, set_sign_in_info] = useState({
    email: "",
    password: ""
  });
  const [signing_in, set_signing_in] = useState(false);

  const handle_input_change = (e) => {
    const { name, value } = e.target;
    set_sign_in_info({ ...sign_in_info, [name]: value });
  }

  const handle_email_login = async (e) => {
    e.preventDefault();
    try {
      set_signing_in(true);
      const res = await signin_with_email(sign_in_info);
      set_signing_in(false);
      if (res && res.token) {
        localStorage.setItem('token', res.token);
        localStorage.setItem('user', JSON.stringify(res.user));
        if (res && res.user && res.user.language) i18next.changeLanguage(res.user.language);

        window.location.href = '/';
      } else {
        if (res && res.error) {
          alert(res.error);
          return;
        }
      }

    } catch (e) {
      console.log(e);
      alert("Failed to login");
      set_signing_in(false);
    }
  }

  function getCodeFromQueryString() {
    const match = window.location.search.match(/code=([^&]+)/);
    return match ? match[1] : null;
  }

  const getFacebookLoginURL = () => {
    const FB_GRAPH_VERSION = "v15.0";
    const base_url = `https://www.facebook.com/${FB_GRAPH_VERSION}/dialog/oauth`;
    const client_id = "837281664196995";
    //http 
    const redirect_uri = "http://localhost:3000/auth/fb";
    const scope = "pages_manage_posts";

    const url = `${base_url}?client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&response_type=code`;
    return url;
  }


  const handleFacebookLogin = async () => {
    console.log(getFacebookLoginURL());
    const url = getFacebookLoginURL();
    window.location.href = url;
  }

  function LinkedInPage() {
    const { linkedInLogin } = useLinkedIn({
      clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
      redirectUri: `${window.location.origin}/linkedin/connect`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
      onSuccess: async (credentialResponse) => {
        set_display_loading("block");
        let pay_load_sign_up = credentialResponse;
        if (localStorage.getItem("referral_code")) {
          pay_load_sign_up = { ...pay_load_sign_up, referral_code: localStorage.getItem("referral_code") };
        }
        const res = await get_user_info(pay_load_sign_up);
        set_display_loading("none");
        if (res && res.token) {
          localStorage.removeItem("referral_code");
          localStorage.setItem('token', res.token);
          localStorage.setItem('user', JSON.stringify(res.user));
          if (res && res.user && res.user.language) i18next.changeLanguage(res.user.language);

          window.location.href = '/';
        } else {
          alert("Failed to login");
        }
        console.log(res);
      },
      onError: (error) => {
        console.log(error);
      },
    });

    return (
      <img
        onClick={linkedInLogin}
        src={linkedin}
        alt={t("sign_in_with_linked_in")}
        style={{ maxWidth: '180px', cursor: 'pointer' }}
      />
    );
  }

  const GoogleLoginButton = () => {
    const handleGoogleLogin = useGoogleLogin({
      // autoSelect: true,
      clientId: "691955201473-9k0kgv03ns75j0aqe5itblijmnffh1ib.apps.googleusercontent.com", // process.env.REACT_APP_GOOGLE_CLIENT_ID,
      onSuccess: async credentialResponse => {
        console.log(credentialResponse);
        set_display_loading("block");
        let pay_load_sign_up = credentialResponse;
        if (localStorage.getItem("referral_code")) {
          pay_load_sign_up = { ...pay_load_sign_up, referral_code: localStorage.getItem("referral_code") };
        }
        const res = await get_user_info(pay_load_sign_up);
        set_display_loading("none");
        if (res && res.token) {
          localStorage.removeItem("referral_code");
          localStorage.setItem('token', res.token);
          localStorage.setItem('user', JSON.stringify(res.user));
          if (res && res.user && res.user.language) i18next.changeLanguage(res.user.language);

          window.location.href = '/';
        } else {
          alert("Failed to login");
        }
        console.log(res);
      },
      // flow: 'auth-code'
    });
    return (
      <GoogleOAuthProvider clientId="691955201473-9k0kgv03ns75j0aqe5itblijmnffh1ib.apps.googleusercontent.com">
        <GoogleLogin
          auto_select={true}
          onSuccess={async credentialResponse => {
            console.log(credentialResponse);
            set_display_loading("block");
            let pay_load_sign_up = credentialResponse;
            if (localStorage.getItem("referral_code")) {
              pay_load_sign_up = { ...pay_load_sign_up, referral_code: localStorage.getItem("referral_code") };
            }
            const res = await get_user_info(pay_load_sign_up);
            // return;
            set_display_loading("none");
            if (res && res.token) {
              localStorage.removeItem("referral_code");
              localStorage.setItem('token', res.token);
              localStorage.setItem('user', JSON.stringify(res.user));
              if (res && res.user && res.user.language) i18next.changeLanguage(res.user.language);

              window.location.href = '/';
            } else {
              alert("Failed to login");
            }
            console.log(res);
          }}
          onError={() => {
            console.log('Login Failed');
          }}
          useOneTap
        />
      </GoogleOAuthProvider>
    );
  }

  useEffect(() => {
    setTimeout(() => {
      set_waiting_for_google(false);
    }, 4000);
  }, []);

  return (
    <>
      <AuthLayout title={t("sign_in_to_your_account")}>


        <div className='grid grid-cols-2 my-4 space-x-2 flex'>
          <div className='flex-auto overflow-hidden'>
            <GoogleOAuthProvider clientId="691955201473-9k0kgv03ns75j0aqe5itblijmnffh1ib.apps.googleusercontent.com">
              <GoogleLoginButton clientId="691955201473-9k0kgv03ns75j0aqe5itblijmnffh1ib.apps.googleusercontent.com" />
            </GoogleOAuthProvider>

            {/* <GoogleOAuthProvider clientId="691955201473-9k0kgv03ns75j0aqe5itblijmnffh1ib.apps.googleusercontent.com">
                  <GoogleLogin
                    auto_select={true}
                    onSuccess={async credentialResponse => {
                      console.log(credentialResponse);
                      set_display_loading("block");
                      let pay_load_sign_up = credentialResponse;
                      if (localStorage.getItem("referral_code")) {
                        pay_load_sign_up = { ...pay_load_sign_up, referral_code: localStorage.getItem("referral_code") };
                      }
                      const res = await get_user_info(pay_load_sign_up);
                      // return;
                      set_display_loading("none");
                      if (res && res.token) {
                        localStorage.removeItem("referral_code");
                        localStorage.setItem('token', res.token);
                        localStorage.setItem('user', JSON.stringify(res.user));
                        if (res && res.user && res.user.language) i18next.changeLanguage(res.user.language);

                        window.location.href = '/';
                      } else {
                        alert("Failed to login");
                      }
                      console.log(res);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                    useOneTap
                  />
                </GoogleOAuthProvider> */}
          </div>

          {!waiting_for_google && <div className='flex-auto'>
            <button onClick={openLinkedinLogin} className='px-4 w-full py-2 pt-3 bg-linkedin text-white text-sm rounded-md relative'><img src={linkedinIcon} alt="Linkedin icon" className="h-6 inline absolute left-3 bottom-2" /> {t("LinkedIn")}</button>
          </div>}
        </div>

        <div className='my-2'>

        </div>

        {/* Beautiful zone showing by signing in, you agree with our terms and conditions that link to https://pubcm.com/privacy */}


        <div className='mt-4 text-center'>

          <div role="status" style={{ display: display_loading }} className='text-center'>
            <svg aria-hidden="true" className="mr-2 w-8 h-8 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
          </div>

        </div>

        <div className="mt-8">
          <div>
            <div>
              {/* Sign in with Google */}

            </div>

            <div>

            </div>

            <div className="relative mt-6">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-white px-2 text-gray-500">{t("or_continue_with")}</span>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <form action="#" method="POST" onSubmit={handle_email_login} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {t("email_address")}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={handle_input_change}
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-pubcm2 focus:outline-none focus:ring-pubcm2 sm:text-sm"
                  />
                </div>
              </div>

              <div className="space-y-1">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {t("password")}
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    onChange={handle_input_change}
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-pubcm2 focus:outline-none focus:ring-pubcm2 sm:text-sm"
                  />
                </div>
                {/* Add show/hide password toggle */}


              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {/* <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                        {t("remember_me")}
                      </label> */}
                </div>

                <div className="text-sm">
                  <NavLink to="/auth/ask_reset/otp" className="font-medium text-pubcm2 hover:text-pubcm1">
                    {t("forgot_password")}
                  </NavLink>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={signing_in}
                  className="flex w-full justify-center rounded-md border border-transparent bg-pubcm2 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-pubcm1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  {signing_in ? <span>{t("signing_in")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span> : t("sign_in")}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div>
          <NavLink
            to="/auth/signup"
            disabled={signing_in}
            className="mt-2 flex w-full justify-center rounded-md border border-1 border-slate-200 py-2 px-4 text-sm font-medium shadow-sm hover:text-pubcm1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {t("sign_up")}
          </NavLink>
        </div>

        <div className='mt-4 text-sm text-slate-600'>
          {t("by_signing_or_up_in_you_agree_with_our_terms")} <a href="https://www.pubcm.com/privacy" className='text-pubcm2'>{t("here")}</a>.
        </div>
      </AuthLayout>
    </>
  )
}

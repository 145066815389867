import React, { useEffect, useRef, useState } from 'react';
import i18next from '../../i18next';
import DashboardLayout from '../../components/DashboardLayout';
import { Stage, Layer, Image, Text, Rect, Transformer } from 'react-konva';
import './photo_editor.css';
import poster_design_obj from "./poster_design.json";

const { t } = i18next;

export function EditPhoto(props) {
  const [image, setImage] = useState(null);
  const transformerRef = useRef(null);
  const imageRef = useRef(null);

  const stageRef = useRef(null);
  const [text, setText] = useState('Double-click to edit');
  const [isEditing, setIsEditing] = useState(false);
  const [position, setPosition] = useState({ x: 50, y: 50 });


  useEffect(() => {
    loadImage('https://images.pexels.com/photos/6446708/pexels-photo-6446708.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940').then(setImage);
  }, []);

  const loadImage = (image_url) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = image_url;
    });
  }
  
  const get_gradient_color = (colors_array) => {
    var gradient_color = 'linear-gradient(to right, ';
    for (var i = 0; i < colors_array.length; i++) {
      gradient_color += colors_array[i] + ', ';
    }
    gradient_color = gradient_color.slice(0, -2);
    gradient_color += ')';
    // alert(gradient_color);
    return gradient_color;
  }

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleBlur = () => {
    // Hide the input and show the Konva Text
    setIsEditing(false);
  };

  const handleDoubleClick = (e) => {
    const stage = stageRef.current;
    const textPosition = e.target.getAbsolutePosition();

    // Position for the input
    setPosition(textPosition);

    // Hide the Konva Text
    setIsEditing(true);
  };



  const render_poster_image = (design_json) => {
    for (var i = 0; i < design_json.length; i++) {
      const design_elt = design_json[i];

      console.log(design_elt);

      switch (design_elt.type) {
        case "image":
          break;
        case "text":
          break;
        case "rectangle":
          break;
        default:
          break;
      }
    }
  }

  useEffect(() => {
    if (transformerRef.current && imageRef.current) {
      // Update Transformer to attach to the current image
      transformerRef.current.nodes([imageRef.current]);
      transformerRef.current.getLayer().batchDraw();

      render_poster_image(poster_design_obj);
    }
  }, [image]);

  return (
    <DashboardLayout>
      <div className="col-span-12 bg-white text-center px-4 md:px-8 py-2 pt-7 text-left">
        <div>
          <Stage id="image_editor" className='' width={1024} height={1024}>
            <Layer className='border'>
              {poster_design_obj.map((design_elt, index) => {
                switch (design_elt.type) {
                  case "image":
                    return <Image
                      key={index + "_image"}
                      image={image}
                      x={design_elt.x}
                      y={design_elt.y}
                      width={design_elt.size.width}
                      height={design_elt.size.height}
                      ref={imageRef}
                      draggable
                    />;
                  case "text":
                    return <Text
                      key={index + "_text"}
                      text={design_elt.content}
                      x={design_elt.position.x}
                      y={design_elt.position.y}
                      fontSize={design_elt.properties.fontSize}
                      fontFamily={design_elt.properties.fontFamily}
                      fill={design_elt.properties.fillColor}
                      
                      stroke={design_elt.properties.strokeColor}
                      draggable
                      wrap='word'
                      width={design_elt.text_area.max_width}
                      onDblClick={handleDoubleClick}
                    />
                  case "rectangle":
                    return <Rect
                      key={index}
                      x={design_elt.position.x}
                      y={design_elt.position.y}
                      width={design_elt.size.width}
                      height={design_elt.size.height}
                      // fill={get_gradient_color(design_elt.properties.fillColor)}
                      fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                      fillLinearGradientEndPoint={{ x: 1024, y: 1024 }}
                      fillLinearGradientColorStops={[0, design_elt.properties.fillColor[0], 1,design_elt.properties.fillColor[1]]} 
                      shadowBlur={0}
                      // opacity={design_elt.opacity}
                      draggable
                      onDragEnd={(e) => {
                        // this.setState({
                        //   x: e.target.x(), 
                        //   y: e.target.y(),
                        // });
                      }}
                    />;
                  default:
                    return null;
                }
              })}
            </Layer>
          </Stage>
          {/* <Stage id="image_editor" className='' width={600} height={600}>
            <Layer className='border'>
              {image && <Image
                image={image}
                x={0}
                y={0}
                width={600}
                height={512}
                ref={imageRef}
                draggable
              />}
              <Text
                text="Draggable Text"
                onDblClick={handleDoubleClick}
                draggable
                onDragStart={() => {
                  // this.setState({
                  //   isDragging: true,
                  // });
                }}
                onDragEnd={(e) => {
                  // this.setState({
                  //   isDragging: false,
                  //   x: e.target.x(),
                  //   y: e.target.y(),
                  // });
                }}
              />

              
              <Rect
                x={0}
                y={500}
                width={600}
                height={100}
                fill="#000"
                shadowBlur={0}
                opacity={0.4}
                draggable
                onDragEnd={(e) => {
                  // this.setState({
                  //   x: e.target.x(),
                  //   y: e.target.y(),
                  // });
                }}
              />

              <Rect
                x={0}
                y={0}
                width={350}
                height={600}
                fill="#000"
                shadowBlur={0}
                opacity={0.3}
                draggable
                onDragEnd={(e) => {
                  // this.setState({
                  //   x: e.target.x(),
                  //   y: e.target.y(),
                  // });
                }}
              />

              <Transformer
                ref={transformerRef}
                keepRatio
                centeredScaling
              />
            </Layer>

            <Layer>
              <Text
                text={text}
                x={50}
                y={50}
                fontSize={24}
                draggable
                onDblClick={handleDoubleClick}
              />
            </Layer>
          </Stage> */}

          {isEditing && (
            <input
              style={{
                position: 'absolute',
                top: position.y + 'px',
                left: position.x + 'px',
                width: 'auto',
              }}
              type="text"
              value={text}
              onChange={handleInputChange}
              onBlur={handleBlur}
              autoFocus
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

import DashboardLayout from "../../components/DashboardLayout";

export default function AdminLayout(props){
    const user = JSON.parse(localStorage.user);

    if(user.role !== "superadmin"){
        window.location.href = "/";
    }

    return <DashboardLayout>{props.children}</DashboardLayout>;
}
import icon from '../../res/img/icon-transparent.png';

import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin, } from '@react-oauth/google';
import { FacebookProvider, LoginButton, useLogin } from 'react-facebook';
import { get_user_info, request_pwd_reset_otp, resend_otp, sign_with_email, signup_with_email, verify_email } from '../../utils/api';
import { useEffect, useState } from 'react';
//const { OAuth2Client } = require('google-auth-library');
import { LinkedIn, useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import linkedinIcon from '../../res/img/linkedin-icon.png';
import { openLinkedinLogin } from '../../utils/channels';
import i18next from '../../i18next';
import { NavLink, useParams } from 'react-router-dom';
import { Dot } from 'react-animated-dots';
import AuthLayout from './AuthLayout';

const { t } = i18next;

export default function AskResetOTP() {

  const [display_loading, set_display_loading] = useState("none");
  const [waiting_for_google, set_waiting_for_google] = useState(true);
  const [verifying, set_verifying] = useState(false);

  const { referral_code } = useParams();
  const [verifying_email_info, set_verifying_email_info] = useState({
    email: ""
  });

  const handle_input_change = (e) => {
    set_verifying_email_info({
      ...verifying_email_info,
      [e.target.name]: e.target.value,
    });
  }

  const [asking_otp, set_asking_otp] = useState(false);
  const handle_the_ask_otp_code = async (e) => {
    e.preventDefault();
    set_asking_otp(true);
    try {
      const res = await request_pwd_reset_otp(verifying_email_info);
      console.log(res);
      if (res && res.error) {
        alert(res.error);
        set_asking_otp(false);
        return;
      }
      set_asking_otp(false);
      if(res && res.action && res.action === 'sent'){
        localStorage.action = 'reset_pwd';
        localStorage.reset_email = verifying_email_info.email;
        window.location.href = "/auth/reset/pwd";
      }

    } catch (err) {
      console.log(err);
      set_asking_otp(false);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      set_waiting_for_google(false);

    }, 4000);
  }, []);



  return (
    <>
      <AuthLayout title={t("reset_your_password")}>
        <div className='text-slate-400'>
          {t("please_enter_your_email_address_to_reset_your_password")}
        </div>
        <div className="mt-6">
          <form action="#" method="POST" onSubmit={handle_the_ask_otp_code} className="space-y-6">
            <div className="space-y-1">
              <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                {t("email_address")}
              </label>
              <div className="mt-1">
                <input
                  id="email_address"
                  name="email"
                  type="text"
                  onChange={handle_input_change}
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-pubcm2 focus:outline-none focus:ring-pubcm2 sm:text-sm"
                />
              </div>


            </div>

            <div>
              <button
                type="submit"
                disabled={verifying}
                className="flex w-full justify-center rounded-md border border-transparent bg-pubcm2 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-pubcm1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {asking_otp ? <span>{t("requesting_the_otp_code")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span> : t("request_otp_code")}
              </button>

              <div className='py-4'>
                <NavLink to="/" className="font-medium text-pubcm2 hover:text-pubcm1">
                  {t("i_remember_my_password_login")}
                </NavLink>
              </div>

            </div>
          </form>
        </div>
      </AuthLayout>
    </>
  )
}

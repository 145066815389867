import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon, ClockIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import { get_ls_checkout_url } from '../../utils/api'
import PopupChoosePyt from './PopupChoosePyt'

import i18next from '../../i18next';
const { t } = i18next;

const frequencies = [
    { value: 'month', label: t("monthly"), priceSuffix: t('month').toLowerCase() },
    { value: 'trimester', label: t("3_months"), priceSuffix: t('trimester').toLowerCase() },
    { value: 'semester', label: t("6_months"), priceSuffix: t('semester').toLowerCase() },
    { value: 'annually', label: t("annually"), priceSuffix: t('year').toLowerCase() },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// let plans_variants = [];
// const _get_plans_variants = ( plan_type ) => {

// }

export default function Pricing(props) {
    const [frequency, setFrequency] = useState(frequencies[3]);
    const [loading_pyt_url, set_loading_pyt_url] = useState(-1);
    const [loading_plans, set_loading_plans] = useState(false);
    const [show_choose_pyt_method, set_show_choose_pyt_method] = useState(false);
    const [pyt_visa_payload, set_pyt_visa_payload] = useState({});

    let { tiers, schedule_id } = props;
    const { org_id, org_name } = props;
    if (tiers && tiers.length) {
        for (let i = 0; i < tiers.length; i++) {
            console.log(tiers[i]);
            
            const trimester_variant = tiers[i].variants.filter(v => v.attributes.interval_count === 3)[0];
            const semester_variant = tiers[i].variants.filter(v => v.attributes.interval_count === 6)[0];
            const annually_variant = tiers[i].variants.filter(v => v.attributes.interval_count === 12)[0];
            const month_variant = tiers[i].variants.filter(v => v.attributes.interval_count === 1)[0];

            //tiers.variants[0]
            tiers[i].price = {
                trimester: trimester_variant ? '$' + (trimester_variant.attributes.price / 3 / 100).toFixed(2) : '',
                semester: semester_variant ? '$' + (semester_variant.attributes.price / 6 / 100).toFixed(2) : '',
                annually: annually_variant ? '$' + (annually_variant.attributes.price / 12 / 100).toFixed(2) : '',
                month: month_variant ? '$' + (month_variant.attributes.price / 100).toFixed(2) : ''
            };

            tiers[i].totalPrice = {
                trimester: trimester_variant ? '$' + (trimester_variant.attributes.price / 100).toFixed(2) : '',
                semester: semester_variant ? '$' + (semester_variant.attributes.price / 100).toFixed(2) : '',
                annually: annually_variant ? '$' + (annually_variant.attributes.price / 100).toFixed(2) : '',
                month: month_variant ? '$' + (month_variant.attributes.price / 100).toFixed(2) : ''
            };
        } 
    }

    const handle_payment = async (tier, frequency, index) => {
        // alert("Paying");
        set_show_choose_pyt_method(true);
        set_pyt_visa_payload({
            org_id,
            org_name,
            schedule_id,
            frequency,
            tier,
            redirection_url: `${window.location.origin}/ls_pyt/${org_id}/${org_name}`,
            title: t("subscription_for_org_name_to_pubcm",{org_name}),
            description: t("payement_for_generation_of_posts_per_month", {tier_posts_per_month : tier.posts_per_month, tier_title : tier.title}),
        });
        return;
        
    }

    const number_of_tiers = tiers && tiers.length ? tiers.length : 1;

    return (
        <div className="bg-white">
            <PopupChoosePyt visa_payload={pyt_visa_payload} setOpen={set_show_choose_pyt_method} open={show_choose_pyt_method}/> 
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mt-4 flex justify-center">
                    <RadioGroup
                        value={frequency}
                        onChange={setFrequency}
                        className={`grid grid-cols-4 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200`}
                    >
                        <RadioGroup.Label className="sr-only">{t("payement_frequency")}</RadioGroup.Label>
                        {frequencies.map((option) => (
                            <RadioGroup.Option
                                key={option.value}
                                value={option}
                                className={({ checked }) =>
                                    classNames(
                                        checked ? 'bg-pubcm2 text-white' : 'text-gray-500',
                                        'cursor-pointer rounded-full py-1 px-2.5'
                                    )
                                }
                            >
                                <span>{option.label}</span>
                            </RadioGroup.Option>
                        ))}
                    </RadioGroup>
                </div>
                <div className={`isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-${number_of_tiers}`}>
                    {tiers && tiers.map((tier, index) => (
                        <div
                            key={tier.id}
                            className={classNames(
                                tier.mostPopular ? 'ring-2 ring-pubcm2' : 'ring-1 ring-gray-200',
                                'rounded-3xl p-8 xl:p-10'
                            )}
                        >
                            <div className="flex items-center justify-between gap-x-4">
                                <h3
                                    id={tier.id}
                                    className={classNames(
                                        tier.mostPopular ? 'text-pubcm2' : 'text-gray-900',
                                        'text-lg font-semibold leading-8'
                                    )}
                                >
                                    {tier.attributes.name}
                                </h3>
                                {tier.mostPopular ? (
                                    <p className="rounded-full bg-pubcm2/10 py-1 px-2.5 text-xs font-semibold leading-5 text-pubcm2">
                                       {t("most_popular")}
                                    </p>
                                ) : null}
                            </div>
                            <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
                            <p className="mt-6 flex items-baseline gap-x-1">
                                <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.price[frequency.value]}</span>
                                <span className="text-sm font-semibold leading-6 text-gray-600">
                                    {/* {frequency.priceSuffix} */}
                                    /{t("month")}
                                </span>
                            </p>
                            <div className='my-2 text-xs text-slate-500'>{t("billed_total_price_every_frequency",{total_price: tier.totalPrice[frequency.value], frequency : frequency.priceSuffix.toLowerCase()})}</div>
                            {tier.available ? <a
                                onClick={() => handle_payment(tier, frequency, index)}
                                aria-describedby={tier.id}
                                className={classNames(
                                    tier.mostPopular
                                        ? 'bg-pubcm2 text-white shadow-sm hover:bg-pubcm1'
                                        : 'text-pubcm2 ring-1 ring-inset ring-pubcm1 hover:ring-sky-300',
                                    'mt-6 cursor-pointer block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pubcm2'
                                )}
                            >
                                
                                {loading_pyt_url === index ? t("loading") + ' ...' : <span>{t("buy_plan")}</span>}
                            </a> :
                                <div className='text-center py-2 mt-6 text-slate-500'>
                                    <ExclamationTriangleIcon className='h-4 inline' /> {t("not_available")}
                                    <div className="text-xs text-red-600"><small>{t("your_schedule_plans_monthly_posts_per_month",{posts_per_month : props.monthly_posts})}</small></div>
                                    <div className="block pt-1"><Link className="px-2 text-xs rounded-md border py-2 px-2 mt-2" to={`/schedule/setup/${org_id}/${org_name}`}> <ClockIcon className="h-4 w-4 inline" /> {t("update_schedule")}</Link></div>
                                </div>}

                            <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                                {tier.features && tier.features.map((feature) => (
                                    <li key={feature} className="flex gap-x-3">
                                        <CheckIcon className="h-6 w-5 flex-none text-pubcm2" aria-hidden="true" />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
                <div className='text-center my-10 text-md'>
                    * {t("feature_is_coming_soon")}
                </div>
            </div>
        </div>
    )
}

import { io } from "socket.io-client";

export function connect_socket(on_connect, on_disconnect, on_message, on_error){
    const socket = io(process.env.REACT_APP_BACK_END_API,{
        extraHeaders : {
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "organization": localStorage.getItem("selected_org_id"),
        },
        
    });
    socket.on("connect", () => {
        console.log("connected");
        console.log(socket.id); // x8WIv7-mJelg7on_ALbx
        if(typeof on_connect === "function") on_connect(socket);
    });
    socket.on("disconnect", () => {
        console.log("disconnected");
        if(typeof on_disconnect === "function") on_disconnect(socket);
    });
    socket.on("message", (msg) => {
        console.log(msg);
        if(typeof on_message === "function") on_message(msg);
    });
    socket.on("error", (err) => {
        console.log(err);
        if(typeof on_error === "function") on_error(err);
    });

    return socket;
}
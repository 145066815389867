import { useEffect, useRef, useState } from "react";

import DashboardLayout from "../../components/DashboardLayout";
import { useParams } from "react-router-dom";
import { setDocumentHeaders } from "../../utils/document";

import i18next from "../../i18next";

import axios from "axios";
import { CheckCircleIcon, PhotoIcon, XMarkIcon } from "@heroicons/react/20/solid";

import { extractColors } from "extract-colors";

// import getColors from "get-image-colors";
import { get_file_upload_url, get_organization_details_info, save_org_branding_info } from "../../utils/api";
import { Dot } from "react-animated-dots";

const { t } = i18next;


export default function Branding() {
    const canvasRef = useRef(null);
    const colorInputRefs = useRef([]);
    setDocumentHeaders(t("edit_organization_pubcm"), t("edit_organization_pubcm"));
    const { org_id } = useParams();
    const [global_state, set_global_state] = useState({
        org: {},
        schedule: {},
        channels: []
    });

    const [branding_obj, set_branding_obj] = useState({
        branding_colors: []
    });

    const [logo_url, set_logo_url] = useState(null);
    const [saving_logo_info, set_saving_logo_info] = useState(false);
    const [loading_logo_info, set_loading_logo_info] = useState(false);

    const handle_save_branding_data = async () => {
        try {
            set_saving_logo_info(true);
            const res = await save_org_branding_info(org_id, {
                branding_colors: branding_obj.branding_colors,
                logo_url
            });

            set_saving_logo_info(false);

            if(res && res.org){
                set_saved_branding(true);
            }

            console.log(res);
            
            // set_branding_obj({
            //     ...branding_obj,
            //     logo_url: "https://pubcm.s3.us-east-1.amazonaws.com/b989441a-e061-444f-9ac7-c716834caf85.png"
            // });
        } catch (err) {
            set_saving_logo_info(false);
            console.log(err);
        }
    }

    const colors_names = ["primary", "secondary", "tertiary", "quaternary", "quinary", "senary"];

    const extract_image_colors = async (image_url) => {
        const colors = await extractColors(image_url, {
            amount: 3,
            type: "image",
            size: 100,
            ignore: []
        });
        set_branding_obj({
            ...branding_obj,
            branding_colors: colors.slice(0, 4).map((color) => {
                // console.log(color);
                return {
                    name: colors_names[colors.indexOf(color)],
                    value: color
                }
            })
        });
    }

    const [is_uploading, set_is_uploading] = useState(false);
    const [upload_progress, set_upload_progress] = useState(0);
    const [saved_branding, set_saved_branding] = useState(false);
    const [files_meta_obj, set_files_meta_obj] = useState({
        total: 0,
        page: 1,
        pages: 0
    });

    const [upload_error, set_upload_error] = useState({
        show: false,
        type: "warning",
        title: "",
        message: ""
    });

    const [selected_logo, set_selected_logo] = useState(null);

    const display_selected_image = (e) => {
        // console.log(e.target.files);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
            // console.log(e.target.result);
            //set_selected_logo as base64 image
            set_selected_logo(e.target.result);
            extract_image_colors(e.target.result);

        }
        reader.readAsDataURL(file);
    }


    const handleFileUploading = (e) => {
        console.log(e.target.files);
        const file = e.target.files[0];
        display_selected_image(e);

        // return;
        get_file_upload_url({
            file_name: file.name,
            file_type: file.type,
            org_id
        }).then(async (res) => {
            if (res.signedUrl) {
                console.log(res);
                const { signedUrl, fileName } = res;
                set_is_uploading(true);
                const options = {
                    headers: {
                        'Content-Type': file.type,
                    },
                    onUploadProgress: (progressEvent) => {
                        console.log(progressEvent.loaded);
                        console.log(progressEvent.total);
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        console.log(percentCompleted);
                        set_upload_progress(percentCompleted);
                    }
                }

                axios.put(signedUrl, file, options).then((res_upload) => {
                    console.log(res_upload);
                    set_is_uploading(false);

                    // console.log(branding_obj);

                    set_logo_url(res.fileUrl);
                }).catch((err) => {
                    set_is_uploading(false);
                    set_upload_error({
                        show: true,
                        type: "error",
                        title: "Upload failed",
                        message: "Please try again later."
                    });
                    console.log(err);
                });
            } else if (res.error) {
                set_upload_error({
                    show: true,
                    title: res.error.title,
                    message: res.error.message,
                    type: res.error.type
                });
            }
        }).catch((err) => {
            console.log(err);
        });

    }

    const handle_get_organization_info = async () => {
        try {
            const org_info = await get_organization_details_info(org_id);
            if(org_info && org_info.org && org_info.org.logo){
                set_selected_logo(org_info.org.logo);
            }
            if(org_info && org_info.org && org_info.org.branding_colors){
                set_branding_obj({
                    ...branding_obj,
                    branding_colors: org_info.org.branding_colors
                });

                set_logo_url(org_info.org.logo);
            }
            console.log(org_info);
        } catch (err) {
            console.log(err);
        }
    }

    const branding_colors = [
        { name: "primary", value: "#0F4321" },
        { name: "secondary", value: "#1F43F1" },
        { name: "tertiary", value: "#BF43F9" },
        { name: "primary", value: "#0F4321" },
        { name: "secondary", value: "#1F43F1" },
        { name: "tertiary", value: "#BF43F9" }
    ];

    useEffect(() => {
        handle_get_organization_info();
    }, []);

    return (
        global_state && global_state.org && <DashboardLayout>
            <div className="col-span-12 min-h-80 text-left bg-white p-2 shadow rounded-md text-center">
                <div>
                    <div className="my-4">
                        <label htmlFor="file-upload-add" className="inline-flex items-center border bg-slate-500 hover:bg-pubcm1 text-white rounded-md px-4 py-3 my-4">
                            <PhotoIcon className="h-6 w-6" aria-hidden="true" />
                            <span className='ml-2'>{t("select_logo_image")}</span>
                            <input type="file" onChange={handleFileUploading} id="file-upload-add" accept=".png, .jpg, .jpeg, .gif" className='hidden' />
                        </label>
                    </div>
                    <div>
                        <canvas ref={canvasRef} style={{ display: 'none' }} />
                    </div>
                    <div>
                        {/* Uploading simple spining animation */}
                        {is_uploading && <div>
                            <div className="flex items-center justify-center">
                                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-pubcm2"></div>
                            </div>

                            <div className="my-4 text-slate-700 text-l">{t("uploading_the_logo_please_wait")}</div>
                        </div>}

                    </div>
                    {!selected_logo && <div>
                        <div className="my-4 text-slate-700 text-l bg-slate-100 inline-block h-40 w-60 border"></div>
                    </div>}
                    {selected_logo && <div>
                        <img src={selected_logo} alt="Logo" className="w-80 border inline rounded-md" />
                    </div>}

                    {/* <div>{logo_url}</div> */}


                </div>

                <div className="lg:flex lg:justify-center border lg:space-x-3 w-full">
                    {
                        branding_obj.branding_colors.map((color, index) => {
                            return <div key={index} className="">
                                <div className="flex py-4" onClick={() => colorInputRefs.current[index].click()}>

                                    <div 
                                        className="w-10 h-10 rounded-md mx-2 border border-slate-300" 
                                        style={{ backgroundColor: color.value.hex }}
                                    ></div>
                                    <div className="flex flex-col py-2">
                                        {/* <div className="text-sm font-bold">{t(color.name)}</div> */}
                                        <div className="text-sm font-bold">{color.value.hex}</div>
                                    </div>
                                    <div className="flex-grow"></div>
                                </div>
                                <div className="text-left">
                                    <div style={{width : '0px', height : '0px', overflow : 'hidden'}}>
                                    <input type="color"
                                        ref={el => colorInputRefs.current[index] = el}
                                        onChange={(e) => {
                                            const newColors = [...branding_obj.branding_colors];
                                            newColors[index] = {
                                                ...color,
                                                value: {
                                                    hex: e.target.value
                                                }
                                            };
                                            set_branding_obj({
                                                ...branding_obj,
                                                branding_colors: newColors
                                            });
                                        }}
                                        value={color.value.hex}
                                        style={{ display: '', width : '0px', height : '0px' }} />
                                    </div>
                                    {/* Delete color */}
                                    <div className="flex justify-left">
                                        <button className="text-red-500 hover:text-red-700 text-xs my-2 rounded-md hover:font-bold px-2 py-2 border" onClick={() => {
                                            set_branding_obj({
                                                ...branding_obj,
                                                branding_colors: branding_obj.branding_colors.filter((color) => color.name !== colors_names[index])
                                            });
                                        }}><XMarkIcon className="h-5 w-5 inline" /> {t("delete_color")}</button>
                                    </div>
                                </div>
                            </div>
                        }
                        )
                    }
                </div>



                {<div>
                    <button disabled={logo_url ? false : true} onClick={handle_save_branding_data} className={`${!logo_url ? 'bg-slate-100 text-slate-400 cursor-not-allowed' : 'bg-pubcm2 text-white  hover:bg-pubcm1'}  rounded-md px-4 py-3 my-4`}>
                        {saving_logo_info ? <span>{t("saving_branding_info")}<Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span> : t("save_branding_info")}
                    </button>
                </div>}

                {saved_branding ? <div className="text-green-500"> <CheckCircleIcon className="h-5 mb-1 inline"/> {t("saved_branding_info_successful")}</div> : ''}
            </div>
        </DashboardLayout>
    );
}


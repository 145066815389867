import { getAllTimezones, getOffsetHours, timezone } from "../../../../utils/timezones";
import i18next from "../../../../i18next";
const t = i18next.t;
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const notificationMethods = [
  { id: 'email', title: 'Morning' },
  { id: 'sms', title: 'Afternoon' },
  { id: 'push', title: 'Evening' },
  { id: 'night', title: 'Night' },
]


export default function TimeToPost(props) {
  const handleTimeChange = (e, index) => {
    const newTimeToPost = [...props.state.time_to_post];
    newTimeToPost[index] = { ...newTimeToPost[index], time: e.target.value };
    props.update_fn({ ...props.state, time_to_post: newTimeToPost });
  }

  const getNumberOfCheckedDays = () => {
    let count = 0;
    props.state.day_time_to_post.forEach(day => {
      if (day.checked) count++;
    });
    return count;
  }

  const handleCheckDay = (e, index) => {
    const newDayTimeToPost = [...props.state.day_time_to_post];
    newDayTimeToPost[index] = { ...newDayTimeToPost[index], checked: e.target.checked };
    props.update_fn({ ...props.state, day_time_to_post: newDayTimeToPost });
  }

  const handleDayTimeChange = (e, index, time_index) => {

    const newDayTimeToPost = [...props.state.day_time_to_post];
    newDayTimeToPost[index].time[time_index] = { ...newDayTimeToPost[index].time[time_index], time: e.target.value };
    props.update_fn({ ...props.state, day_time_to_post: newDayTimeToPost });
  }

  const handleDayTimeClick = (e, index, time_index) => {
    //Check the day checkbox if it is not checked
    if (!props.state.day_time_to_post[index].checked) {

      if (getNumberOfCheckedDays() === props.state.number_of_posts) return;

      const newDayTimeToPost = [...props.state.day_time_to_post];
      newDayTimeToPost[index] = { ...newDayTimeToPost[index], checked: true };
      props.update_fn({ ...props.state, day_time_to_post: newDayTimeToPost });
    }
  }

  const update_full_schedule = () => {
    props.update_fn({
      ...props.state,
      use_full_schedule : !props.state.use_full_schedule
    });
    
  }

  let found_offset = false;

  return (
    <div>
      <div className="my-2">When do you want to post?</div>
      {
        props.state.period === "day" ?

          <div className="flex">
            {
              props.state.time_to_post.map((time, index) => {
                console.log(index);
                return (
                  <div className="flex-auto mx-1" key={index}>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-500">
                      {time.label}
                    </label>
                    <div className="mt-1">
                      <input
                        type="time"
                        onChange={(e) => handleTimeChange(e, index)}
                        name={time.name}
                        id={time.label}
                        value={time.time}
                        className="shadow-sm focus:ring-pubcm2 focus:border-pubcm2 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                )
              })
            }
          </div>
          :
          <div className="mt-4 grid grid-cols-7">
            {
              props.state.day_time_to_post.map((day, index) => {
                return (
                  <div className="col-span-1" key={index}>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      {day.abbr}
                    </label>
                    <div className="mt-1">
                      <input
                        type="checkbox"
                        name={day.id}
                        id={day.id}
                        checked={day.checked}
                        onChange={(e) => handleCheckDay(e, index)}
                        className="shadow-sm  h-6 checked:bg-pubcm2 hover:bg-pubcm1  focus:ring-pubcm2 focus:bg-pubcm2 focus:border-pubcm2 block w-full sm:text-sm border-gray-300 rounded-md"
                        disabled={getNumberOfCheckedDays() === props.state.number_of_posts && !day.checked}
                      />

                      {day.time.map((time, i) => {
                        return (
                          <div>
                            <input type="time"
                              name={time.name}
                              id={time.name}
                              value={time.time}
                              onChange={(e) => handleDayTimeChange(e, index, i)}
                              onClick={(e) => handleDayTimeClick(e, index, i)}
                              className="shadow-sm focus:ring-pubcm2 focus:border-pubcm2 checked:accent-pubcm2 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        )
                      })}

                    </div>
                  </div>
                )
              })
            }
          </div>
      }
      <div>

        <div className="ml-3 text-sm leading-6 py-2 cursor-pointer" onClick={update_full_schedule}>
          <input
            id="use_full_schedule"
            aria-describedby="use-full-description"
            name="candidates"
            checked={props.state.use_full_schedule}
            type="checkbox"
            className="shadow-sm checked:bg-pubcm2 hover:bg-pubcm1  focus:ring-pubcm2 focus:bg-pubcm2 focus:border-pubcm2 border-gray-300 rounded-md"
          /> 
          <label htmlFor="" className="font-medium text-gray-900">
            &nbsp;{t("use_full_schedule")}
          </label>
          <p id="use-full-description" className="text-gray-500">
            {props.state.use_full_schedule ? t("disable_this_to_use_not_fill_full_schedule") : t("enable_this_use_the_full_schedule")}
          </p>
        </div>
      </div>
    </div>
  )
}

import { Link } from "react-router-dom";
import i18next from "../../i18next";
import flowers_img from "../../res/img/flowers.svg";

const { t } = i18next;

export default function DontHavePlan(){
    return (
        <div className='col-span-12'>
            <div className='text-center'>
                <div className="my-4">{<img style={{width:`300px`,display:'inline', borderRadius:`20px`}} src={flowers_img} alt="Flowers"/>}</div>
                <div className='text-2xl'>{t("you_dont_have_any_org_yet")}</div>
                <div className='text-sm'>{t("please_add_one_org_to_get_started")}</div>
                <div className='mt-4'>
                    <Link to="/new/organization" className='bg-pubcm2 text-white hover:bg-pubcm1 px-4 py-2 rounded-md'>{t("get_started")}</Link>
                </div>
            </div>
        </div>
    );
}
import { useEffect, useState } from "react";

import DashboardLayout from "../../components/DashboardLayout";
import { get_facebook_pages, get_ig_pages, save_facebook_page, save_ig_channel, save_organization } from "../../utils/api";
import { handleAjoutFacebookChannel } from "../../utils/channels";
import failed_pyt from "../../res/img/fall_coming.svg";
import instagram_icon from "../../res/img/comm_channels/instagram.png";
import { Link, useParams } from "react-router-dom";
import { Twitter } from "./components/Twitter";
import { Facebook } from "./components/Facebook";
import i18next from "../../i18next";
const { t } = i18next;

let pages_loaded = false;

export function AfterSocialInstagram() {
    const [global_state, set_global_state] = useState({ error: {}, loading_pages: false, pages: [], facebook_org_name: "", channel_id: null });
    const [loading, set_loading] = useState(false);
    const [ig_pages, set_ig_pages] = useState([]);
    const [saving_page, set_saving_page] = useState(false);

    const { social_network } = useParams();



    const fb_pages = async (payload) => {
        if (payload.code && payload.state && payload.state.org) {
            try {

                pages_loaded = true;
                set_global_state({
                    ...global_state,
                    loading_pages: true,
                    facebook_org_name: payload.state.org_name
                });

                // await handleAjoutFacebookChannel(payload.state.org);
                const pages = await get_facebook_pages({ code: payload.code, org_id: payload.state.org });
                console.log("The pages are ", pages);

                set_global_state({
                    ...global_state,
                    loading_pages: false,
                    pages: pages.pages,
                    channel_id: pages.channel_id
                });
            } catch (ex) {
                console.log(ex);
                pages_loaded = false;
                set_global_state({ ...global_state, loading_pages: false });
            }

        }

    }

    const selectFbPage = async (index) => {
        try {
            set_saving_page(true);
            console.log(index);
            console.log(global_state.pages[index]);

            const channel = {
                channel_id: global_state.channel_id,
                facebook_page: global_state.pages[index]
            }

            console.log(channel);
            const res = await save_facebook_page(channel);
            window.location.href = "/";
            set_saving_page(false);
        } catch (ex) {
            console.log(ex);
            set_saving_page(false);
        }
    }

    const handle_get_ig_page = async (code) => {
        console.log("Show IG pages");
        const res = await get_ig_pages(code);
        set_ig_pages(res);
    }

    const handleSelectOrg = async (channel, index) => {
        console.log(channel);
        console.log(index);
        const url = new URL(window.location.href);
        const code = url.searchParams.get("code");
        const state = url.searchParams.get("state") ? JSON.parse(url.searchParams.get("state")) : null;
        const res = await save_ig_channel(state.org, channel);
        console.log(res);
        window.location.href = "/";
    }

    useEffect(() => {
        if (!pages_loaded) {
            try {
                const url = new URL(window.location.href);
                const code = url.searchParams.get("code");
                console.log("The code is ", code);
                const state = url.searchParams.get("state") ? JSON.parse(url.searchParams.get("state")) : null;
                const error = url.searchParams.get("error");
                if (error) {
                    // === "access_denied"
                    const error_description = url.searchParams.get("error_description");
                    const error_reason = url.searchParams.get("error_reason");
                    set_global_state({ ...global_state, error: { error, error_description, error_reason } });
                } else {
                    console.log("GETTING IG PAGES");
                    // fb_pages({ code, state });
                    handle_get_ig_page(code);
                    console.log("The code is ");
                    console.log(code);

                }
            } catch (ex) {
                console.log(ex);
            }
        }

        // handle_get_ig_page(498);
    }, []);

    return (
        <DashboardLayout>
            {global_state.error && global_state.error.error ? <div className="col-span-12 text-center">
                <div className=" ml-auto mr-auto">
                    <div className=" text-center bg-white px-2 py-4 rounded-md my-10 py-20">
                        <div><img src={failed_pyt} alt="Adding page failed" className="w-72 inline" /></div>

                        <div className="text-xl">{t("there_was_issue_adding_the_page_please_try_again")}</div>
                        <div className="mt-6">{global_state.error.error_description}</div>
                        <div>
                            <Link to="/">
                                <button className="mt-4 cursor-pointer font-medium bg-pubcm2 hover:bg-pubcm1 text-white rounded-md px-4 py-2">
                                    {t("go_to_dashboard")}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div> : <div className="col-span-12 text-center">
                <div className="pt-4 border-b  mt-4">
                    <div><img src={instagram_icon} className="h-10 inline" /></div>
                    {t("connect_your_ig_account")}
                    {/* <div className="text-xs text-gray-500">Uses the connection you previously made to LinkedIn</div> */}
                    <div>
                        <ul className="w-80 inline-block mt-3 divide-y divide-gray-200">
                            {(ig_pages && ig_pages.length) && ig_pages.map((page, index) => <li className="flex hover:bg-slate-100">
                                <div className="flex-1 px-4 py-4">{page['name']}</div>
                                <div className="flex-1 px-4 py-2">
                                    <button disabled={saving_page} onClick={() => handleSelectOrg(page, index)} className="bg-sky-400 hover:bg-sky-700 text-white px-4 py-2 rounded-md text-xs">{saving_page ? t("saving_channel") : t("connect")}</button>
                                </div>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>
            }
        </DashboardLayout>
    );
}
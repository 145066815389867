import { useState } from "react";
import { activate_tester_plan } from "../../../utils/api";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/20/solid";

export default function ActivateTesterMembership() {
    const [activating_membership, setActivatingMembership] = useState(false);
    const [membership_activated, setMembershipActivated] = useState(false);

    const _activate_tester_membership = async () => {
        try {
            setActivatingMembership(true);
            let res = await activate_tester_plan({
                org_id: localStorage.selected_org_id
            });
            setActivatingMembership(false);
            if (res && res.payement) {
                setMembershipActivated(true);
            } else {
                alert("An error occurred when activating the tester membership. Please try again later.");
            }
        } catch (err) {
            setActivatingMembership(false);
            console.log(err);
            alert("An error occurred when activating the tester membership. Please try again later.");
        }

    }

    return <div className="space-y-4 bg-white px-6 py- mt-1 mb-2 relative border-2 border-green-400">
        {membership_activated ? <>
            <div className="py-2 text-center">
                <div className="text-green-600"><CheckCircleIcon className="w-10 inline"/></div>
                Membership activated, you can add another more time after refreshing the page.</div>
        </> : <>
            <div className="text-sm text-gray-600 my-2 font-bold mt-3">Tester special feature</div>
            <div className="pb-3">
                <div className="pb-2 text-sm">Activate 1 week Pro subscription to access all the features of PubCM</div>
                <button disabled={activating_membership} className="border bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-400" onClick={_activate_tester_membership}>
                    {activating_membership ? 'Activating subscription ...' : 'Activate subscription'}

                </button>
            </div>
        </>}
    </div>;
}
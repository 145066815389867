exports.well_known_people = [
    "Albert Einstein",
    "Isaac Newton",
    "Galileo Galilei",
    "Leonardo da Vinci",
    "Thomas Edison",
    "Marie Curie",
    "Charles Darwin",
    "Stephen Hawking",
    "Nikola Tesla",
    "Benjamin Franklin",
    "Winston Churchill",
    "Martin Luther King Jr.",
    "Nelson Mandela",
    "Mahatma Gandhi",
    "Mother Teresa",
    "Pope Francis",
    "Pope John Paul II",
    "Queen Elizabeth II",
    "Barack Obama",
    "Bill Clinton",
    "George W. Bush",
    "Donald Trump",
    "Joe Biden",
    "Hillary Clinton",
    "Kamala Harris",
    "Michelle Obama",
    "Oprah Winfrey",
    "Ellen DeGeneres",
    "Taylor Swift",
    "Beyoncé",
    "Lady Gaga",
    "Rihanna",
    "Kanye West",
    "Justin Bieber",
    "Selena Gomez",
    "Adele",
    "Bruno Mars",
    "Drake",
    "Eminem",
    "Jay-Z",
    "Kendrick Lamar",
    "Michael Jackson",
    "Prince",
    "Elvis Presley",
    "The Beatles",
    "Bob Dylan",
    "Jimi Hendrix",
    "David Bowie",
    "Madonna",
    "Whitney Houston",
    "Celine Dion",
    "Mariah Carey",
    "Jennifer Lopez",
    "Meryl Streep",
    "Tom Hanks",
    "Robert De Niro",
    "Johnny Depp",
    "Morgan Freeman",
    "Denzel Washington",
    "Sylvester Stallone",
    "Clint Eastwood",
    "Jackie Chan",
    "Bruce Lee",
    "Muhammad Ali",
    "Mike Tyson",
    "Serena Williams",
    "Venus Williams",
    "Roger Federer",
    "Rafael Nadal",
    "Michael Jordan",
    "Kobe Bryant",
    "LeBron James",
    "Steph Curry",
    "Shaquille O'Neal",
    "Usain Bolt",
    "Lionel Messi",
    "Cristiano Ronaldo",
    "Pele",
    "Diego Maradona",
    "Zlatan Ibrahimovic",
    "David Beckham",
    "Tom Brady",
    "Tiger Woods",
    "Rory McIlroy",
    "Manny Pacquiao",
    "Floyd Mayweather Jr.",
    "Conor McGregor",
    "Tony Hawk",
    "Shaun White",
    "Kelly Slater",
    "Laird Hamilton",
    "Elon Musk",
    "Mark Zuckerberg",
    "Jeff Bezos",
    "Bill Gates",
    "Steve Jobs",
    "Tim Cook",
    "Satya Nadella",
    "Larry Page",
    "Sergey Brin",
    "Travis Kalanick",
    "Jack Dorsey",
    "Reed Hastings",
    "Susan Wojcicki",
    "Bob Iger",
    "Chris Evans",
    "Scarlett Johansson",
    "Ryan Reynolds",
    "Dwayne Johnson",
    "Jason Statham",
    "Vin Diesel",
    "Leonardo DiCaprio",
    "Robert Pattinson",
    "Kristen Stewart",
    "Jennifer Lawrence",
    "Emma Watson",
    "Daniel Radcliffe",
    "Maggie Smith",
    "Helena Bonham Carter",
    "Ian McKellen",
    "Patrick Stewart",
    "Benedict Cumberbatch",
    "Tom Hiddleston",
    "Idris Elba",
    "Hugh Jackman",
    "Chris Pratt",
    "Chris Pine",
    "Chris Hemsworth",
    "Mark Ruffalo",
    "Elizabeth Olsen",
    "Tom Holland",
    "Zendaya",
    "Michael B. Jordan",
    "Chadwick Boseman",
    "Glenn Close",
    "Cate Blanchett",
    "Kate Winslet",
    "Helen Mirren",
    "Judi Dench",
    "Viola Davis",
    "Emma Stone",
    "Julia Roberts",
    "Mila Kunis",
    "Natalie Portman",
    "Keira Knightley",
    "Anne Hathaway",
    "Rachel McAdams",
    "Charlize Theron",
    "Jennifer Aniston",
    "Angelina Jolie",
    "Brad Pitt",
    "George Clooney",
    "Matt Damon",
    "Mark Wahlberg",
    "Christian Bale",
    "Robert Downey Jr.",
    "Tom Cruise",
    "Will Smith",
    "Samuel L. Jackson",
    "Bruce Willis",
    "Arnold Schwarzenegger",
    "Jet Li",
    "Keanu Reeves",
    "Nicolas Cage",
    "Jim Carrey",
    "Seth Rogen",
    "Jonah Hill",
    "Adam Sandler",
    "Tina Fey",
    "Amy Poehler",
    "Kristen Wiig",
    "Maya Rudolph",
    "Melissa McCarthy",
    "Jimmy Fallon",
    "Conan O'Brien",
    "David Letterman",
    "Jay Leno",
    "Stephen Colbert",
    "James Corden",
    "John Oliver",
    "Trevor Noah",
    "Jerry Seinfeld",
    "Chris Rock",
    "Dave Chappelle",
    "Eddie Murphy",
    "Louis C.K.",
    "Aziz Ansari",
    "Kevin Hart",
    "Amy Schumer",
    "Wanda Sykes",
    "Tig Notaro",
    "Joan Rivers",
    "Sarah Silverman",
    "Chelsea Handler",
    "Kathy Griffin",
    "Whitney Cummings",
    "Patton Oswalt",
    "Ricky Gervais",
    "Russell Brand",
    "Adam Carolla",
    "Joe Rogan",
    "Howard Stern",
    "Rachel Ray",
    "Martha Stewart",
    "Dr. Oz",
    "Dr. Phil",
    "Suze Orman",
    "Tyra Banks",
    "Gordon Ramsay",
    "Anthony Bourdain",
    "Bobby Flay",
    "Emeril Lagasse",
    "Giada De Laurentiis",
    "MKBHD",
    "Linus Tech Tips",
    "Unbox Therapy",
    "Marques Brownlee",
    "Jonathan Morrison",
    "Andrew Tate",
    "Khaby Lame",
    "Cardi B",
    "Steve Harvey",
    "Gims",
    "Dadju",
    "Ninho",
    "Damso",
    "Fred Musa",
    "Maeva Ghennam"
]

exports.humors = [
    // {
    //     "type": "Discomfort",
    //     "description": "The result of humor that is awkward, uncomfortable, or inappropriate and triggers a negative emotional response in the form of discomfort or unease.",
    //     "emoji": "😬",
    //     "polarity": "negative",
    //     "polarity_score": 0
    // },
    {
        "type": "Angry",
        "description": "The result of humor that is insulting, offensive, or mean-spirited and triggers a negative emotional response in the form of anger or frustration.",
        "emoji": "😡",
        "polarity": "negative",
        "polarity_score": 0
    },
    {
        "type": "Connection",
        "description": "The result of humor that is empathetic, inclusive, or relatable and triggers a sense of connection or belonging with others.",
        "emoji": "🤝",
        "polarity": "positive",
        "polarity_score": 20
    },
    {
        "type": "Insight",
        "description": "The result of humor that is thought-provoking, clever, or ironic and triggers a new perspective or understanding about a situation or topic.",
        "emoji": "🤔",
        "polarity": "neutral",
        "polarity_score": 40
    },
    {
        "type": "Relaxation",
        "description": "The result of humor that is calming, reassuring, or silly and triggers a sense of ease and comfort.",
        "emoji": "😌",
        "polarity": "positive",
        "polarity_score": 60
    },
    {
        "type": "Smiling",
        "description": "The result of humor that is amusing, clever, or charming and triggers a positive emotional response in the form of a smile.",
        "emoji": "🙂",
        "polarity": "positive",
        "polarity_score": 80
    },
    {
        "type": "Laughter",
        "description": "The result of humor that is amusing, entertaining, or absurd and triggers a physical response in the form of laughter.",
        "emoji": "😂",
        "polarity": "positive",
        "polarity_score": 100
    },
];


exports.experts = [
    "Physician",
    "Teacher",
    "Footballer",
    "Engineer",
    "Artist",
    "Writer",
    "Scientist",
    "Programmer",
    "Lawyer",
    "Chef",
    "Psychologist",
    "Accountant",
    "Entrepreneur",
    "Architect",
    "Journalist",
    "Musician",
    "Designer",
    "Marketer",
    "Actor",
    "Investor",
    "Athlete",
    "Photographer",
    "Consultant",
    "Financial analyst",
    "Nurse",
    "Veterinarian",
    "Pharmacist",
    "Social worker",
    "Graphic designer",
    "Fitness trainer",
    "Economist",
    "Philosopher",
    "Singer",
    "Management consultant",
    "Physiotherapist",
    "Researcher",
    "Public speaker",
    "Mathematician",
    "Video editor",
    "IT specialist",
    "Environmental scientist",
    "Human resources manager",
    "Real estate agent",
    "Travel writer",
    "Web developer",
    "Mechanical engineer",
    "Product manager",
    "Data analyst",
    "Business analyst",
    "Fashion designer",
    "Biomedical engineer",
    "Clinical psychologist",
    "Neuroscientist",
    "Chemist",
    "Historian",
    "Geologist",
    "Political scientist",
    "Social media manager",
    "Copywriter",
    "Blogger",
    "Market research analyst",
    "Physical therapist",
    "Technical writer",
    "User experience designer",
    "Game designer",
    "Interior designer",
    "Brand strategist",
    "Construction manager",
    "Civil engineer",
    "Aerospace engineer",
    "Entrepreneurship coach",
    "Public relations specialist",
    "Speech therapist",
    "Art director",
    "Industrial designer",
    "Product designer",
    "Media planner",
    "Sales manager",
    "Product marketing manager",
    "Customer service representative",
    "Content strategist",
    "Environmental engineer",
    "Civil rights lawyer",
    "Corporate lawyer",
    "Immigration lawyer",
    "Patent lawyer",
    "Family therapist",
    "Marriage counselor",
    "Child psychologist",
    "Sports coach",
    "Fitness instructor",
    "Personal trainer",
    "Tour guide",
    "Translator",
    "Interpreter",
    "Event planner",
    "Hotel manager",
    "Restaurant manager",
    "Mechanic",
    "Electrician",
    "Plumber",
    "Construction worker",
    "Carpenter",
    "Welder",
    "Machinist",
    "Auto mechanic",
    "Landscaper",
    "Gardener",
    "Animal trainer",
    "Dog trainer",
    "Pet groomer",
    "Horse trainer",
    "Videographer",
    "Cinematographer",
    "Sound designer",
    "Recording engineer",
    "Radio host",
    "TV host",
    "News anchor",
    "Sports broadcaster",
    "Podcast host",
    "Voice actor",
    "Animator",
    "Storyboard artist",
    "Visual effects artist",
    "Cartoonist",
    "Comic book artist",
    "Illustrator",
    "Fashion model",
    "Fitness model",
    "Print model",
    "Runway model",
    "Voiceover artist",
    "Screenwriter",
    "Film director",
    "Film producer",
    "Film editor",
    "Stunt performer",
    "Dancer",
    "Choreographer",
    "Songwriter",
    "Music producer",
    "Music director",
    "Music teacher",
    "Music therapist"
];
  
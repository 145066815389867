import { useState, useEffect } from "react";
import { get_organization_posts } from "../../../utils/api";
import byMoto from '../../../res/img/by_moto.svg';
import human from "human-time";

import i18next from "../../../i18next";
import { share_post_on_media } from "../../../utils/share";
import { ShareIcon } from "@heroicons/react/20/solid";

const { t } = i18next;

export default function PostsZone(props){
    const [latest_posts, set_latest_posts] = useState({
      docs:[],
      loading_posts : true
    });

    let loaded_posts = false;

    const get_all_posts = async () => { 
      loaded_posts = true;   
        console.log(props.org_id);
        set_latest_posts({
          ...latest_posts,
          loading_posts : true
        });     
        const posts = await get_organization_posts(props.org_id,1,10);
        console.log(posts);
        set_latest_posts({
          ...latest_posts,
          loading_posts : false,
          docs : posts
        });  
        console.log(posts);
    }

    useEffect(() => {
       !loaded_posts && get_all_posts();
    }, [props.org_id]);

    console.log("Reloaded");
    
    //if(!latest_posts.docs.length) get_all_posts();

    return(
      <>
      <h2 className="text-lg font-semibold text-gray-900 mt-4">{t("published_posts")}</h2>
        <div className='grid grid-cols-12'>
           { latest_posts.loading_posts ? <div>{t("loading")}</div> : 
            !latest_posts.docs.docs.length  ? <div className='text-center mt-4 col-span-12 mb-32'><div className='text-center'><img className='w-40 inline mb-4' src={byMoto} alt={t("by_my_car")}/></div>{t("no_published_posts_yet")} <div><small>{t("once_you_have_some_posts_they_will_be_displayed_here")}</small></div></div> : latest_posts.docs.docs.map((post, i) => {
              console.log(post);
              let post_image = null;

              if(post.images_suggestions && post.images_suggestions.photos && post.images_suggestions.photos.length > 0){
                post_image = post.images_suggestions.photos[post.selected_image].src.large;
              }

              return (
              
                <div key={post._id} className='col-span-12 md:col-span-6  shadow px-4 py-2 mx-2 my-4 bg-blue-50 rounded-md'>
                  {/*<div className='text-sm mb-4'>{post.title}</div>*/}
                  {/* <div className="max-h-100 overflow-hidden"><img className="" src={`https://oaidalleapiprodscus.blob.core.windows.net/private/org-lvgm4VotuOv71Z9rrCuOnecI/user-vipbevUywVsmf4dyqBdQBK2F/img-AYoXQhCTFeQwjnIg4fc5ke2H.png?st=2023-02-12T19%3A19%3A10Z&se=2023-02-12T21%3A19%3A10Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-02-11T21%3A38%3A44Z&ske=2023-02-12T21%3A38%3A44Z&sks=b&skv=2021-08-06&sig=cJp3EsXMw6VBXnq/DlkbwW3fSypIeOTO9Qu5yiVOIU8%3D`} alt="Img"/></div> */}
                    {post.images_suggestions && post.images_suggestions.photos && post.images_suggestions.photos.length > 0 && <div className="h-30 relative">
                      <img className="w-full object-cover h-72" src={post.images_suggestions.photos[post.selected_image].src.large} alt="img" />
                    </div>
                    }
                    
                  <div>{post.body}</div>
                  <div className='text-xs mt-2'>{human(new Date(post.createdAt))}</div>
                  <div>
                    {navigator && navigator.share && <button className='border text-xs px-2 py-1 bg-slate-500 hover:bg-slate-600 text-white rounded-md' onClick={() => share_post_on_media( post.body, post_image)}>
                      <ShareIcon className='inline h-3' /> {t("share")}
                  </button>}
                  </div>
                </div>
              );
            })
          }
        </div>
      </>
    )
}
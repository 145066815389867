import icon from '../../res/img/icon-transparent.png';

import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin, } from '@react-oauth/google';
import { FacebookProvider, LoginButton, useLogin } from 'react-facebook';
import { get_user_info, resend_otp, sign_with_email, signup_with_email, verify_email } from '../../utils/api';
import { useEffect, useState } from 'react';
//const { OAuth2Client } = require('google-auth-library');
import { LinkedIn, useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import linkedinIcon from '../../res/img/linkedin-icon.png';
import { openLinkedinLogin } from '../../utils/channels';
import i18next from '../../i18next';
import { NavLink, useParams } from 'react-router-dom';
import { Dot } from 'react-animated-dots';
import AuthLayout from './AuthLayout';

const { t } = i18next;

export default function VerifyEmail() {

  const [display_loading, set_display_loading] = useState("none");
  const [waiting_for_google, set_waiting_for_google] = useState(true);
  const [verifying, set_verifying] = useState(false);
  const user_obj = JSON.parse(localStorage.getItem("user"));

  const email_address = user_obj.email;

  const { referral_code } = useParams();
  const [verifying_email_info, set_verifying_email_info] = useState({
    email: email_address,
    verification_code: "",
  });

  const handle_input_change = (e) => {
    set_verifying_email_info({
      ...verifying_email_info,
      [e.target.name]: e.target.value,
    });
  }

  const handle_email_verification = (e) => {
    e.preventDefault();
    set_verifying(true);
    verify_email(verifying_email_info).then((res) => {
      console.log(res);
      if (res && res.error) {
        alert(res.error);
        set_verifying(false);
        return;
      }
      set_verifying(false);
      localStorage.setItem("user", JSON.stringify(res.user));
      window.location.href = "/";
    }).catch((err) => {
      console.log(err);
      set_verifying(false);
    });
  }

  const [resending, set_resending] = useState(false); // [resending, set_resending
  const [resend_obj, set_resend_obj] = useState({
    email: email_address,
  });

  const resend_the_otp_code = () => {
    set_resending(true);
    resend_otp(resend_obj).then((res) => {
      console.log(res);
      if (res && res.error) {
        alert(res.error);
        set_resending(false);
        return;
      }
      set_resending(false);
      // localStorage.setItem("user", JSON.stringify(res.user));
      // window.location.href = "/";
    }).catch((err) => {
      console.log(err);
      set_resending(false);
    });
  }

  const change_email_address = () => {
    if (window.confirm(t("are_you_sure_you_want_to_change_the_email_address_registration_will_start"))) {
      localStorage.clear();
      window.location.href = "/auth/signup";
    }
  }

  useEffect(() => {
    setTimeout(() => {
      set_waiting_for_google(false);

    }, 4000);
  }, []);



  return (
    <>
      <AuthLayout title={t("validate_otp_code")}>
        <div className='text-slate-400'>
          {t("please_validate_your_account_filling_the_otp_code", { email: email_address })}
        </div>
        <div className="mt-6">
          <form action="#" method="POST" onSubmit={handle_email_verification} className="space-y-6">
            <div className="space-y-1">
              <label htmlFor="otp_code" className="block text-sm font-medium text-gray-700">
                {t("otp_code")}
              </label>
              <div className="mt-1">
                <input
                  id="otp_code"
                  name="verification_code"
                  type="text"
                  maxLength={6}
                  minLength={6}
                  onChange={handle_input_change}
                  autoComplete="current-password"
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-pubcm2 focus:outline-none focus:ring-pubcm2 sm:text-sm"
                />
              </div>
              {/* Add show/hide password toggle */}


            </div>

            <div>
              <button
                type="submit"
                disabled={verifying}
                className="flex w-full justify-center rounded-md border border-transparent bg-pubcm2 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-pubcm1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {verifying ? <span>{t("validating_my_email")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span> : t("validate_my_email")}
              </button>

              <div className='py-4'>
                <button
                  type="button"
                  onClick={resend_the_otp_code}
                  disabled={resending}
                  className="mt-2 flex w-full justify-center rounded-md border border-1 border-slate-200 py-2 px-4 text-sm font-medium shadow-sm hover:text-pubcm1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  {resending ? <span>{t("sending_code")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span> : t("resend_code")}
                </button>

                <button onClick={change_email_address} className="mt-2 flex w-full justify-center rounded-md border border-1 border-slate-200 py-2 px-4 text-sm font-medium shadow-sm hover:text-pubcm1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  {t("change_the_email")}
                </button>

              </div>
            </div>
          </form>
        </div>
      </AuthLayout>
    </>
  )
}

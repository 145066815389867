import i18next from "../../../../i18next";
const { t } = i18next;

export default function NumberWords(props){
    const updateNumberWords = (e) => {
        props.update_fn({
            ...props.state,
            words_number : e.target.value
        })
    }

    return (
        <div>
            <div className="py-2">{t("how_many_words_per_post")}</div>
            <input min="20" max="72" value={props.state.words_number} step="1" onChange={updateNumberWords} type="range" className="w-full my-4 accent-pubcm2"/>
            <div>{t("number_words", {number : props.state.words_number})}</div>
        </div>
    )
}
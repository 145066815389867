import { get_back_end_url } from "./api";

function getFileExtension(url) {
    const fileName = url.split('/').pop();
    const extension = fileName.split('.').pop();
    return extension;
}

export const share_post_on_media = async (textToShare, imageToShareURL) => {
    const imageURL = get_back_end_url("proxy?file=" + imageToShareURL);
    const file_extension = "."+getFileExtension(imageToShareURL);

    fetch(imageURL)
        .then((response) => {
            return response.blob();
        })
        .then((imageBlob) => {
            console.log(imageBlob);
            const fileName = 'f'+file_extension;
            const imageFile = new File([imageBlob], fileName, { type: imageBlob.type });
            if (navigator.share) navigator.share({
                text: textToShare,
                files: [imageFile]
            })
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}
import { ArchiveBoxIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, CloudIcon, PhotoIcon, PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";
import { Dot } from "react-animated-dots";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../components/DashboardLayout";
import Loading from "../../components/Loading";
import { delete_product, get_objectives, get_product_info, get_product_usage, list_products, save_new_product, save_objective, update_product } from "../../utils/api";

import i18next from "../../i18next";
import SlideOverPickFile from "../../components/SlideOverPickFile";
import Alert from "../../components/Alert";
const { t } = i18next;

export default function Products() {
    const { org_id, org_name } = useParams();
    const [saving_product, set_saving_product] = useState(false);
    const [loading_products, set_loading_products] = useState(false);
    const [show_add_product_form, set_show_add_product_form] = useState(false);
    const [products, set_products] = useState([]);
    const [deleting_product, set_deleting_product] = useState(false);
    const [loading_product_details, set_loading_product_details] = useState(false);
    const [saving_error, set_saving_error] = useState({
        type: '',
        title: '',
        message: ''
    });

    

    const handle_close_alert = () => {
        set_saving_error({
            type: '',
            title: '',
            message: ''
        });
    }

    const product_type_ref = useRef(null);

    const [products_meta_obj, set_products_meta_obj] = useState({
        total: 0,
        page: 1,
        pages: 0
    });


    const [new_product, set_new_product] = useState({
        '_id': null,
        'product_type': 'product',
        'product_url': '',
        'product_name': '',
        'product_description': '',
        'product_tags': '',
        'product_availabity': 'available',
        'product_price_type': 'paid-one-time',
        'payement_freq': 'month',
        'product_price': '0.0',
        'product_currency': '$',
        'has_discounts': 'no',
        'picture': ''
    });

    const handle_product_change = (e) => {
        set_new_product((prev_val) => {
            return {
                ...new_product,
                [e.target.name]: e.target.value
            }
        })
    }

    const handle_submit_new_product = async (e) => {
        e.preventDefault();

        try {
            console.log(new_product);
            set_saving_error({
                type: '',
                title: '',
                message: ''
            });
            set_saving_product(true);
            if (new_product._id === null) {
                const res = await save_new_product(org_id, new_product);
                set_saving_product(false);

                console.log(res);

                if (res.product) {
                    // prepend res.product to product list
                    set_products((prev_products) => {
                        return [res.product, ...prev_products];
                    });

                    set_new_product({
                        '_id': null,
                        'product_type': 'product',
                        'product_url': '',
                        'product_name': '',
                        'product_description': '',
                        'product_tags': '',
                        'product_availabity': 'available',
                        'product_price_type': 'paid-one-time',
                        'payement_freq': 'month',
                        'product_price': '0.0',
                        'product_currency': '$',
                        'has_discounts': 'no',
                        'picture': ''
                    });

                    set_product_usage({
                        ...product_usage,
                        used_product: product_usage.used_product + 1
                    });
                } else {
                    if (res.message) {
                        set_saving_error({
                            type: 'error',
                            title: t("an_error_occured"),
                            message: res.message
                        });
                    }
                }
            } else {
                const res = await update_product(org_id, new_product._id, new_product);
                set_saving_product(false);
                // update product in product list
                if (res.product) {
                    set_products((prev_products) => {
                        return prev_products.map((prev_product) => {
                            if (prev_product._id === res.product._id) {
                                return res.product;
                            }
                            return prev_product;
                        });
                    });
                } else {
                    if (res.message) {
                        set_saving_error({
                            type: 'error',
                            title: t("an_error_occured"),
                            message: res.message
                        });
                    }
                }
            }

        } catch (ex) {
            set_saving_product(false);
            alert("Error saving product");
        }
    }

    const handle_cancel_new_product = () => {
        // set_show_add_product_form(false);
        set_saving_error({
            type: '',
            title: '',
            message: ''
        });

        set_new_product({
            '_id': null,
            'product_type': 'product',
            'product_url': '',
            'product_name': '',
            'product_description': '',
            'product_tags': '',
            'product_availabity': 'available',
            'product_price_type': 'paid-one-time',
            'payement_freq': 'month',
            'product_price': '0.0',
            'product_currency': '$',
            'has_discounts': 'no',
            'picture': ''
        });
    }

    const handle_previous_page = () => {
        if (products_meta_obj.page - 1 > 0) {
            const previous_page = products_meta_obj.page - 1 < 1 ? 1 : products_meta_obj.page - 1;
            listProducts(previous_page);
        }

        // handle_get_files(previous_page);

    }

    const handle_next_page = () => {
        console.log(products_meta_obj.page, products_meta_obj.pages, products_meta_obj.page + 1 <= products_meta_obj.pages);
        if (products_meta_obj.page + 1 <= products_meta_obj.pages) {
            console.log(products_meta_obj.page + 1)
            const next_page = products_meta_obj.page + 1 > products_meta_obj.pages ? products_meta_obj.pages : products_meta_obj.page + 1;
            listProducts(next_page);
        }
    }

    const listProducts = async (page_number) => {
        try {
            set_loading_products(true);
            const res = await list_products(org_id, page_number || products_meta_obj.page);
            set_loading_products(false);
            console.log(res);
            if (res && res.products) {
                set_products(res.products);
                set_products_meta_obj({
                    total: parseInt(res.total),
                    page: parseInt(res.page),
                    pages: parseInt(res.pages)
                });
            }
        } catch (ex) {
            console.log(ex);
            set_loading_products(false);
        }
    }

    let linking_file_post_index = 0;
    const [origin_post, set_origin_post] = useState({});
    const [show_pick_file, set_show_pick_file] = useState(false);

    const handleUpdatePostFile = async (post, file, post_index) => {
        console.log(post, file, post_index);
        set_new_product((prevState) => {
            return {
                ...new_product,
                'picture': file.url
            }
        });
    }

    const handle_show_pick_file = (index, post) => {
        if (post) {
            linking_file_post_index = index;
            set_origin_post(post);
        }
        set_show_pick_file(!show_pick_file);
    }

    const handle_delete_product = async (product) => {
        if (window.confirm(t("are_you_sure_delete_product"))) {
            try {
                set_deleting_product(true);
                const res = await delete_product(org_id, product._id);
                set_deleting_product(false);
                if (res && res.product) {
                    set_products((prev_products) => {
                        return prev_products.filter((prev_product) => prev_product._id !== product._id);
                    });
                    set_product_usage({
                        ...product_usage,
                        used_product: product_usage.used_product - 1
                    });
                }
            } catch (ex) {
                console.log(ex);
                set_deleting_product(false);
            }
        }
    }

    const handle_edit_product = async (product) => {
        set_show_add_product_form(true);
        const price_arr = product.price && product.price.length ? product.price[product.price.length - 1].split(' ') : [0.0, '$'];
        const price = price_arr[0] ? parseFloat(price_arr[0]) : 0.0;
        const currency = price_arr[1] ? price_arr[1] : '$';
        set_new_product({
            '_id': product._id,
            'product_type': product.type,
            'product_url': product.url,
            'product_name': product.name,
            'product_description': product.description,
            'product_tags': product.tags,
            'product_availabity': product.availability,
            'product_price_type': product.price_type,
            'payement_freq': product.membership_payement_freq,
            'product_price': price,
            'product_currency': currency,
            'has_discounts': product.has_discounts,
            'picture': product.picture
        });

        product_type_ref.current.focus();
    }

    const handle_blur_product_url = async (e) => {
        const url = e.target.value;
        if (url && url.length) {
            try {
                set_loading_product_details(true);
                const res = await get_product_info({ url });
                set_loading_product_details(false);

                // console.log(prod_info);
                if (res && res.result) {
                    const product = res.result;
                    const price_arr = product.price && product.price.length ? product.price[product.price.length - 1].split(' ') : [0.0, '$'];
                    const price = price_arr[0] ? parseFloat(price_arr[0]) : 0.0;
                    const currency = price_arr[1] ? price_arr[1] : '$';
                    set_new_product((prevState) => {
                        return {
                            ...new_product,
                            'product_type': product.product_type,
                            'product_name': product.product_name,
                            'product_description': product.product_description,
                            'product_tags': product.product_tags.join(','),
                            'product_availabity': product.product_availabity,
                            'product_price_type': product.product_price_type,
                            'payement_freq': product.payement_freq,
                            'product_price': price,
                            'product_currency': currency,
                            'has_discounts': product.has_discounts,
                            'picture': product.picture
                        }
                    });

                }

            } catch (ex) {
                set_loading_product_details(false);
                console.log(ex);
            }
        }
    }

    const [product_usage, set_product_usage] = useState({
        used_product: 0,
        max_products: 1
    });

    const handle_get_product_usage = async () => {
        try {
            const product_usage_res = await get_product_usage(org_id);
            if (product_usage_res && product_usage_res.response) {
                set_product_usage({
                    used_product: product_usage_res.number_existing_products,
                    max_products: product_usage_res.max_num_of_products
                });

                
                
            }

        } catch (err) {
            console.log(err);
        }
    }

    const product_service_label = new_product.product_type === "product" ? t("product") : t("service");

    useEffect(() => {
        handle_get_product_usage();
        listProducts();
    }, []);

    const common_design = `rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-pubcm1`;
    const used_pctg = product_usage.used_product / product_usage.max_products * 100;
    let usage_progress_color = "bg-pubcm1";
    if (used_pctg > 75 && used_pctg < 95){
        usage_progress_color = "bg-yellow-500";
    } else if(used_pctg >= 95){
        usage_progress_color = "bg-red-500";
    }

    return <DashboardLayout>
        {loading_products && <div className="max-w-7xl bg-white col-span-12 pt-2 pb-24 px-6 lg:px-8"><div className="text-center mt-4 text-gray-600 text-md py-8">
            <div>
                <Loading style={{ display: "inline-block" }} />
            </div>
            <div className="pt-6 text-xl">{t("loading_your_products")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></div>
        </div>
        </div>}
        {!loading_products && <>
            <div className={!show_add_product_form ? `max-w-7xl hidden lg:block bg-white col-span-4 pt-2 pb-8 px-2 lg:px-4` : `max-w-7xl bg-white col-span-4 pt-2 pb-8 px-2 lg:px-4`}>
                <form className="my-2" onSubmit={handle_submit_new_product}>
                    <SlideOverPickFile post_index={linking_file_post_index} post={origin_post} handle_select={handleUpdatePostFile} fn_show={handle_show_pick_file} show={show_pick_file} org_id={org_id} />
                    <div className="text-center">
                        <div><img className="w-72 inline mt-16" src={new_product.picture || ''} alt={new_product.product_name} /></div>
                        <button onClick={handle_show_pick_file} className="text-gray-400 border border-gray-400 hover:bg-slate-100 px-4 py-2 rounded-md my-2"><PhotoIcon className="h-5 inline " /> Select image </button>
                    </div>
                    <div className=" space-y-2">

                        <div className={`${common_design}`}>
                            <label htmlFor="product_type" className="block text-xs font-medium text-gray-900">
                                {t("type")}
                            </label>
                            <select
                                name="product_type"
                                required
                                ref={product_type_ref}
                                onChange={handle_product_change}
                                value={new_product.product_type}
                                className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            >
                                <option value="product">{t("product")}</option>
                                <option value="service">{t("service")}</option>
                            </select>
                        </div>

                        <div className={`${common_design}`}>
                            <label htmlFor="product_url" className="block text-xs font-medium text-gray-900">
                                {t("product_service_link", {type : product_service_label})}
                            </label>
                            <input
                                type="url"
                                name="product_url"
                                disabled={loading_product_details}
                                onChange={handle_product_change}
                                onBlur={handle_blur_product_url}
                                value={new_product.product_url}
                                className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder={t("enter_the_product_service_link", {type : product_service_label.toLowerCase()})}
                            />

                        </div>

                        {loading_product_details && <div className="text-center py-16">
                            <Loading style={{ display: "inline-block" }} />
                            {<div className="text-slate-700">
                                {t("loading_product_details")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot>
                                <div><small>{t("please_wait")}</small></div>
                            </div>}
                        </div>}

                        {!loading_product_details && <div className="space-y-2">
                            <div className={`${common_design}`}>
                                <label htmlFor="product_name" className="block text-xs font-medium text-gray-900">
                                    {t("product_service_name", {type : product_service_label})}
                                </label>
                                <input
                                    type="text"
                                    required
                                    name="product_name"
                                    onChange={handle_product_change}
                                    value={new_product.product_name}
                                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    placeholder={t("enter_the_product_service_name", {type: product_service_label.toLowerCase()})}
                                />
                            </div>



                            <div className={`${common_design}`}>
                                <label htmlFor="product_description" className="block text-xs font-medium text-gray-900">
                                {t("product_service_description", {type : product_service_label})}
                                </label>
                                <textarea
                                    type="text"
                                    required
                                    maxLength={300}
                                    name="product_description"
                                    onChange={handle_product_change}
                                    value={new_product.product_description}
                                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    placeholder={t("enter_the_product_service_description", {type: product_service_label.toLowerCase()})}
                                />
                            </div>

                            <div className={`${common_design}`}>
                                <label htmlFor="product_tags" className="block text-xs font-medium text-gray-900">
                                    {t("product_service_tags", {type : product_service_label})}
                                </label>
                                <input
                                    type="text"
                                    name="product_tags"
                                    onChange={handle_product_change}
                                    value={new_product.product_tags}
                                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    placeholder={t("enter_the_product_service_associated_tags", {type: product_service_label.toLowerCase()})}
                                />
                            </div>

                            <div className={`${common_design}`}>
                                <label htmlFor="product_availabity" className="block text-xs font-medium text-gray-900">
                                    {t("availability")}
                                </label>
                                <select
                                    name="product_availabity"
                                    required
                                    onChange={handle_product_change}
                                    value={new_product.product_availabity}
                                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                >
                                    <option value="available">{t("available")}</option>
                                    <option value="unavailable">{t("unavailable")}</option>
                                </select>
                            </div>

                            <div className={`${common_design}`}>
                                <label htmlFor="product_price_type" className="block text-xs font-medium text-gray-900">
                                    {t("pricing_type")}
                                </label>
                                <select
                                    name="product_price_type"
                                    required
                                    onChange={handle_product_change}
                                    value={new_product.product_price_type}
                                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                >
                                    <option value="free">{t("free")}</option>
                                    <option value="paid-one-time">{t("paid_one_time")}</option>
                                    <option value="membership">{t("membership")}</option>
                                </select>
                            </div>

                            {new_product.product_price_type === "membership" && <div className={`${common_design}`}>
                                <label htmlFor="payement_freq" className="block text-xs font-medium text-gray-900">
                                    {t("payment_frequency")}
                                </label>
                                <select
                                    name="payement_freq"
                                    required
                                    onChange={handle_product_change}
                                    value={new_product.payement_freq}
                                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                >
                                    <option value="day">{t("day")}</option>
                                    <option value="week">{t("week")}</option>
                                    <option value="month">{t("Month")}</option>
                                    <option value="3_months">{t("3_months")}</option>
                                    <option value="6_months">{t("6_months")}</option>
                                    <option value="year">{t("year")}</option>
                                </select>
                            </div>}

                            {new_product.product_price_type !== "free" && <div className={`${common_design}`}>
                                <label htmlFor="product_price" className="block text-xs font-medium text-gray-900">
                                    {t("price")}
                                </label>
                                <input
                                    type="number"
                                    name="product_price"
                                    required={new_product.product_price_type !== "free" ? true : false}
                                    onChange={handle_product_change}
                                    value={new_product.product_price}
                                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    placeholder={t("enter_the_price_associated_to_the_product_service",{type : product_service_label.toLowerCase()})}
                                />
                            </div>}

                            {new_product.product_price_type !== "free" && <div className={`${common_design}`}>
                                <label htmlFor="product_currency" className="block text-xs font-medium text-gray-900">
                                    {t("currency")}
                                </label>
                                <input
                                    type="text"
                                    name="product_currency"
                                    required={new_product.product_price_type !== "free" ? true : false}
                                    maxLength={5}
                                    onChange={handle_product_change}
                                    value={new_product.product_currency}
                                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    placeholder={t("enter_the_currency")}
                                />
                            </div>}

                            {new_product.product_price_type !== "free" && <div className={`${common_design}`}>
                                <label htmlFor="has_discounts" className="block text-xs font-medium text-gray-900">
                                    {t("discounts")}
                                </label>
                                <select
                                    name="has_discounts"
                                    required
                                    onChange={handle_product_change}
                                    value={new_product.has_discounts}
                                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                >
                                    <option value="yes">{t("we_have_discounts")}</option>
                                    <option value="no">{t("we_do_not_have_discounts")}</option>
                                </select>
                            </div>}

                            {saving_error && saving_error.message && <div>
                                <Alert onClose={handle_close_alert} type={saving_error.type} title={saving_error.title} message={saving_error.message} />
                            </div>}

                            <div>
                                <button type="submit" disabled={saving_product} className="px-4 py-2 bg-pubcm1 hover:bg-pubcm2 text-white rounded-md">
                                    {!saving_product ? t("save") : t("saving")}
                                </button>
                                <button type="button" onClick={handle_cancel_new_product} disabled={saving_product} className="px-4 py-2 mx-2 bg-slate-600 hover:bg-slate-400 text-white rounded-md">
                                    {t("cancel")}
                                </button>
                            </div>

                        </div>}

                    </div>



                </form>
            </div>
            <div className="max-w-7xl bg-white col-span-8 pt-2 pb-8 px-6 lg:px-8">
                <div className="bg-white">
                    <div className="mx-auto max-w-2xl px-4 pt-2 pb-8  lg:max-w-7xl lg:px-8">
                        <div className="mb-14">
                            <div className="text-right lg:hidden">
                                <button onClick={() => set_show_add_product_form(!show_add_product_form)} className="border px-4 py-2 rounded-md">
                                    {!show_add_product_form && <span><PlusIcon className="h-5 inline" /> {t("add_product")}</span>}
                                    {show_add_product_form && <span><XMarkIcon className="h-5 inline" /> {t("close_add_product")}</span>}
                                </button>
                            </div>
                            <div className="text-3xl font-bold my-1 mb-4 text-slate-600">
                                {t("your_products_services")}
                            </div>

                            {/* <div>
                                {product_usage.used_product === product_usage.max_products && <div className="pointer-events-none inset-x-0 bottom-0 sm:px-6 sm:pb-5 lg:px-8">
                                    <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-orange-600 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
                                        <p className="text-sm leading-6 text-white">                                           
                                                <strong className="font-semibold">You can't add more </strong>
                                                products or services.
                                        </p>
                                        
                                    </div>
                                </div>}
                            </div> */}

                            {(product_usage && product_usage.max_products) ? <div className="bg-gray-100 my-2 p-2  rounded-md shadow">
                                <div className="text-leftp-2 md:text-left md:flex space-x-8">
                                <div className="flex-auto">
                                    <div className="relative">
                                        <ArchiveBoxIcon className="h-5 w-5 inline relative bottom-0 text-gray-500 mr-3" /> 
                                        {t("you_have_number_products", {number : product_usage.used_product, max : product_usage.max_products})}
                                    </div>
                                    <div className="block my-1">
                                        <div className=" bg-gray-200 rounded-full">
                                            <div className={`h-3 text-xs rounded-full leading-none py-1 text-center text-white ${usage_progress_color}`} style={{ width: (product_usage.used_product * 100 / product_usage.max_products) + "%" }}></div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            {product_usage.max_products === product_usage.used_product && <div className="text-sm py-2 text-red-800">{t("sorry_you_cant_add_more_products_services_upgrade")}</div>}
                            </div> : ''}



                            <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-2 lg:gap-x-8">
                                {products.map((product) => (
                                    <div
                                        key={product._id}
                                        className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
                                    >
                                        <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96">
                                            <img
                                                src={product.picture}
                                                alt={product.name}
                                                className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                                            />
                                        </div>
                                        <div className="flex flex-1 flex-col space-y-2 p-4">
                                            <h3 className="text-sm font-medium text-gray-900">
                                                <span aria-hidden="true" className="inset-0" />
                                                {product.name}

                                            </h3>
                                            <p className="text-sm text-gray-500">{product.description}</p>
                                            <p className="text-sm text-gray-500">{product.tags.split(",").map(tag => "#" + tag)}</p>
                                            <p className="text-sm text-gray-500"><a href={product.url} target="_blank" rel="noreferrer">{product.url}</a></p>
                                            <div className="flex flex-1 flex-col justify-end">
                                                <p className="text-sm italic text-gray-500">{product.type} {product.availability}</p>
                                                <p className="text-base font-medium text-gray-900">{product.price}</p>
                                            </div>

                                            <div>
                                                <button onClick={() => handle_edit_product(product)} className="bg-orange-600 text-white hover:bg-orange-400 mx-2 text-sm px-3 py-1 rounded-md">{t("edit")}</button>
                                                <button disabled={deleting_product} onClick={() => handle_delete_product(product)} className="bg-red-600 text-white hover:bg-red-400 text-sm px-3 py-1 rounded-md">{!deleting_product ? t("delete") : t("deleting")}</button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex my-14">
                                <div className="text-left mr-2 text-gray-600 text-md pt-4 flex-grow">{t("page_current_of_total", { current_page: products_meta_obj.page, total_pages: products_meta_obj.pages })}</div>
                                <div className="text-left text-gray-600 text-md pt-4 flex">
                                    <div className="flex-auto relative bottom-2">
                                        <button onClick={handle_previous_page} className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                            <ChevronLeftIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                                            {t("previous")}
                                        </button>
                                    </div>
                                    <div className="flex-auto relative bottom-2">
                                        <button onClick={handle_next_page} className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                            {t("next")}
                                            <ChevronRightIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>}
    </DashboardLayout>

}
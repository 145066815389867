/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PencilIcon, PlusIcon } from '@heroicons/react/20/solid'
import { MediaFiles } from '../pages/media/MediaFiles'
import { get_file_upload_url, get_organization_files, save_organization, save_organization_file } from '../utils/api'
import axios from 'axios'
import TripRe from "../res/img/trip-re.svg";
import Alert from './Alert'

export default function SlideOverPickFile(props) {
  // const [open, setOpen] = useState(true);
  const open = props.show;
  const setOpen = props.fn_show;
  const [loading_files, set_loading_files] = useState(false);
  const [files, set_files] = useState([]);
  const [is_uploading, set_is_uploading] = useState(false);
  const [upload_progress, set_upload_progress] = useState(0);
  const [files_meta_obj, set_files_meta_obj] = useState({
    total: 0,
    page: 1,
    pages: 0
  });

  const [upload_error, set_upload_error] = useState({
    show: false,
    type: "warning",
    title: "",
    message: ""
  });

  const org_id = props.org_id;

  const handleFileUploading = (e) => {
    console.log(e.target.files);
    const file = e.target.files[0];
    get_file_upload_url({
      file_name: file.name,
      file_type: file.type,
      org_id
    }).then(async (res) => {
      if (res.signedUrl) {
        console.log(res);
        const { signedUrl, fileName } = res;
        set_is_uploading(true);
        const options = {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: (progressEvent) => {
            console.log(progressEvent.loaded);
            console.log(progressEvent.total);
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(percentCompleted);
            set_upload_progress(percentCompleted);
          }
        }

        axios.put(signedUrl, file, options).then((res_upload) => {
          console.log(res_upload);
          set_is_uploading(false);
          save_organization_file(org_id, {
            file_name: file.name,
            file_type: file.type,
            file_size: file.size,
            file_url: res.fileUrl,
            file_unique_name: res.fileName,
          }).then(function (save_res) {
            console.log(save_res);
            //Prepend the new file to the files array
            set_files([save_res.file, ...files]);
          });
        }).catch((err) => {
          set_is_uploading(false);
          set_upload_error({
            show: true,
            type: "error",
            title: "Upload failed",
            message: "Please try again later."
          });
          console.log(err);
        });
      } else if (res.error){
        set_upload_error({
              show: true,
              title: res.error.title,
              message: res.error.message,
              type : res.error.type
          });
      }
    }).catch((err) => {
      console.log(err);
    });

  }

  const handle_get_files = async (page = 1) => {
    try {
      set_loading_files(true);
      const files = await get_organization_files(org_id, page);
      set_loading_files(false);
      console.log(files);
      set_files(files.media_files);
      set_files_meta_obj({
        total: files.media_files_count,
        page: files.page,
        pages: files.pages
      });

    } catch (err) {
      console.log(err);
      set_loading_files(false);
    }
  }

  const handleSelectFile = (file) => {
    props.handle_select(props.post, file, props.post_index);
  }

  const handlePreviousPage = () => {
    if (files_meta_obj.page > 1) {
      handle_get_files(files_meta_obj.page - 1);
    }
  }

  const handleNextPage = () => {
    if (files_meta_obj.page < files_meta_obj.pages) {
      handle_get_files(files_meta_obj.page + 1);
    }
  }

  const handle_close_alert = () => {
    set_upload_error({
      show: false,
      type: "warning",
      title: "",
      message: ""
    });
  }

  useEffect(() => {
    handle_get_files(files_meta_obj.page);
  }, [org_id]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll px-4 py-2">
                    <div className=' h-full left-0 top-0'>
                      <div className='text-xl my-4 flex'>
                        <div>Select a file</div>
                        <div className='flex-auto text-right'>
                          <label htmlFor="file-upload-add" className="inline-flex items-center px-4 py-2 border bg-pubcm2 text-white border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-pubcm1">
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                            <span className='ml-2'>Upload</span>
                            <input type="file" onChange={handleFileUploading} id="file-upload-add" accept=".png, .jpg, .jpeg, .gif" className='hidden' />
                          </label>
                        </div>
                      </div>
                      {/* Upload progressbar  */}
                      {is_uploading && <div className='w-full'>
                        <div className="relative pt-1">
                          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-100">
                            <div style={{ width: `${upload_progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-pubcm2 rounded-md"></div>
                          </div>
                        </div>
                      </div>}

                      <div className="block text-left my-2">
                        {/* <div><Alert type="warning" title="Stockage plein" message="Une erreur s'est produite"/></div> */}
                        {upload_error && upload_error.show && <div><Alert onClose={handle_close_alert} type={upload_error.type} title={upload_error.title} message={upload_error.message} /></div>}
                      </div>

                      {files.length ? <div>
                        <div>
                          <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-2 xl:gap-x-8">
                            {files.map((file) => (
                              <li onClick={() => handleSelectFile(file)} key={file.source} className="relative">
                                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                                  <img src={file.url} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                                  <button type="button" className="absolute inset-0 focus:outline-none">
                                    <span className="sr-only">View details for {file.title}</span>
                                  </button>
                                </div>
                                <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.original_name}</p>
                                <p className="pointer-events-none block text-sm font-medium text-gray-500">{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className=' w-full bg-white py-2 px-2'>
                          <div className='flex'>
                            <div className='flex-auto'>
                              <button onClick={handlePreviousPage} className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                Previous
                              </button>
                            </div>
                            <div className='flex-auto text-center relative top-2'>{files_meta_obj.page} of {files_meta_obj.pages}</div>
                            <div className='flex-auto text-right'>
                              <button onClick={handleNextPage} className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> : <div>
                        <div className="text-center mt-4 text-gray-600 text-md mt-16">
                          <div><img src={TripRe} className="w-60 inline" /></div>
                          <div className="pt-6 text-xl">No files uploaded yet</div>
                          <div className="pt-2">Your files will appear here after upload.</div>
                        </div>
                      </div>}

                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}


import { useState } from "react";

import DashboardLayout from "../../components/DashboardLayout";
import { save_organization } from "../../utils/api";

import { useNavigate } from "react-router-dom";
import { countries } from "../../utils/countries";
import { Dot } from "react-animated-dots";

import i18next from "../../i18next";
const { t } = i18next;

export function NewOrganization() {
    return (
        <DashboardLayout>
            <div className="col-span-12 text-center px-4 md:px-0">
                <h1 className="text-xl">{t("new_organization")}</h1>
                <p className="text-sm">{t("add_your_organization_details")}</p>
                <div>
                    <Form />
                </div>
            </div>
        </DashboardLayout>
    );
}


function Form() {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [website, setWebsite] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [tags, setTags] = useState("");
    const [country, setCountry] = useState("United States");
    const [show_saving, set_show_saving] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            set_show_saving(true);
            const save_ = await save_organization({ name, description, website, email, phone, country, tags });
            set_show_saving(false);
            console.log(save_);
            if (save_ && save_.admin) {
                localStorage.setItem("selected_org_id", save_.organization._id);
                navigate(`/schedule/setup/${save_.organization._id}/${save_.organization.name}`);
            }
        } catch (ex) {
            set_show_saving(false);
            console.log(ex);
        }

        //console.log(name, description, website, email, phone, country);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "name":
                setName(value);
                break;
            case "description":
                setDescription(value);
                break;
            case "website":
                setWebsite(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "phone":
                setPhone(value);
                break;
            case "country":
                setCountry(value);
                break;
            case "tags":
                setTags(value);
                break;
            default:
                break;
        }
    }


    return (
        <>
            <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                    <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                               {t("organization_name")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    name="name"
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {t("description")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <textarea
                                    type="text"
                                    name="description"
                                    onChange={handleChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                ></textarea>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {t("tags")}<div><small>({t("comma_separated")})</small></div>
                            </label>  
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    name="tags"
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <div className="text-left text-xs text-slate-600 mt-1 sm:text-center">{t("eg_peace_tech_my_company_usa")}</div>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {t("website")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    name="website"
                                    onChange={handleChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {t("email")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    name="email"
                                    onChange={handleChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {t("phone_number")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    name="phone"
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>



                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="region" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {t("country")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <select
                                    id="region"
                                    name="country"
                                    onChange={handleChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                >
                                    {countries.map(country => <option key={country} value={country}>{country}</option>)}
                                </select>
                            </div>
                        </div>


                        <div>
                            <label htmlFor="region" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">

                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0 text-center">
                                <button disabled={show_saving} type="submit" className=" px-4 py-2 bg-blue-600 text-white rounded-md">
                                    {show_saving ? <span>{t("saving_organization")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span> : t("save")}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import i18next from '../../../../i18next';
const { t } = i18next;


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function RadioPostsNumber(props) {
  const def_index = props.state.period === "day" ? 0 : 1;
  console.log(props.state);
  const [mem, setMem] = useState(props.state.posts_number[def_index]);

  const handleChange = (evt) => {
    const arr = Array.from(Array(evt.number).keys()).map((i) => {
      return {'name' : `time${i}`,'time' : '', 'label' : t("time_number", {number : i})};
    });
    props.update_fn({
        ...props.state,
        time_to_post: arr,
        number_of_posts : evt.number
    });
  }

  console.log(props.state);

  return (
    <div>
      <div className="">
        <h2 className="text-sm font-medium text-gray-900 text-center">{t("number_of_posts_per_period",{period: props.state.period})}</h2>
      </div>

      <RadioGroup value={mem} onChange={setMem} className="mt-2">
        <div className="flex">
          {props.state.posts_number.map((option) => (
            <RadioGroup.Option
              key={option.name}
              value={option}
              onClick={() => handleChange(option)}
              className={({ active, checked }) => {
                if(props.state.number_of_posts){
                  active = t("number_posts",{number: props.state.number_of_posts}) === option.name;
                  checked = t("number_posts",{number: props.state.number_of_posts}) === option.name;
                }

                return classNames(
                    option.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
                    active ? 'ring-2 ring-offset-2 ring-pubcm2' : '',
                    checked ? 'bg-pubcm2 border-transparent text-white hover:bg-pubcm2' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50 ',
                    'mx-1 border rounded-md py-3 px-3 flex-1 items-center justify-center text-sm font-medium uppercase sm:flex-1'
                  );
                }
              }
              disabled={props.max_posts > 0 && props.max_posts < 13 && "6 posts" === option.name}
            >
              <RadioGroup.Label as="span">
                {option.name}
                {props.max_posts > 0 && props.max_posts < 13 && "6 posts" === option.name && <div className="text-xs text-red-400 lowercase"> <small> ({t("per_your_membership_this_option_is_disabled")})</small></div>}
              </RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  )
}

import { useEffect } from "react";
import { Dot } from "react-animated-dots";
import DashboardLayout from "../../components/DashboardLayout";
import { authentify_with_linkedin, get_linkedin_token } from "../../utils/api";
import i18next from "../../i18next";
const { t } = i18next;

let is_loading_token = false;
export default function LinkedinLogin() {
    //get code and state from url
    const url = window.location.href;
    const code = url.match(/code=([^&]+)/)[1];
    const state = url.match(/state=([^&]+)/)[1];


    const getLinkedinToken = async () => {
        try {
            if (is_loading_token) return;
            //Convert state from base64
            //convert state from URL encoded and then from base64
            const state_json = JSON.parse(atob(decodeURIComponent(state)));
            console.log(state_json);
            is_loading_token = true;
            let payload = { code };
            if(localStorage.getItem("referral_code")){
                payload = {...payload, referral_code: localStorage.getItem("referral_code")};
            }
            const resp = await authentify_with_linkedin(payload);
            is_loading_token = false;
            console.log(resp);
            if (resp && resp.token) {
                localStorage.removeItem("referral_code");
                localStorage.setItem('token', resp.token);
                localStorage.setItem('user', JSON.stringify(resp.user));
                window.location.href = '/';
            } else {
                alert(t("failed_to_login"));
                window.location.href = '/';
            }
        } catch (e) {
            alert(t("something_went_wrong"));
            is_loading_token = false;
            window.location.href = `/`;
        }

    }

    useEffect(() => {
        getLinkedinToken();
    }, [])


    return (

        <div className="col-span-12 bg-white text-center py-20">
            <h1 className="text-xl">{t("finalizing_linkedin_log_in")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></h1>
        </div>
    );
}
import human from "human-time";
import { useEffect, useState } from "react";
import { Dot } from "react-animated-dots";
import DashboardLayout from "../../components/DashboardLayout";
import { send_message } from "../../utils/api";

export default function News() {
    const [form, set_form] = useState({
        recipient: "",
        subject: "",
        message: ""
    });

    const [sending_message, set_sending_message] = useState(false);

    const handleChange = (e) => {
        set_form({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(form);
        if(!form.subject){
            alert("Please enter the message subject and message");
            return;
        }

        if(!form.message){
            alert("Please enter the message subject and message");
            return;
        }

        //Send the message
        try{
            set_sending_message(true);
            await send_message(form);
            set_sending_message(false);
        }catch(ex){
            console.log(ex);
            set_sending_message(false)
        }

    }

    return (
        <DashboardLayout>
            <div className="col-span-12 bg-white py-2">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Send messages</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Send messages to your all your users
                            </p>
                        </div>
                    </div>
                    <div className="mt-2 flex flex-col">
                        <div className="mt-4">
                            <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                Recipient
                            </label>
                            <div className="">
                                <input
                                    id="recipient"
                                    name="recipient"
                                    type="text"
                                    onChange={handleChange}
                                    className="my-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                    placeholder="Enter recepients"
                                    value={form.recipient}
                                />
                            </div>
                            <div className="text-slate-700 text-xs">
                                <small>Email addresses comma separated or "all" or "*"</small>
                            </div>
                        </div>

                        <div className="mt-4">
                            <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                Subject
                            </label>
                            <div className="">
                                <input
                                    id="subject"
                                    name="subject"
                                    type="text"
                                    onChange={handleChange}
                                    className="my-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                    placeholder="Message subject"
                                    value={form.subject}
                                />
                            </div>
                        </div>

                        <div className="mt-4">
                            <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                Tell me what you want to achieve with your posts in a few words.
                            </label>
                            <div className="mt-2">
                                <textarea
                                    id="message"
                                    name="message"
                                    rows={5}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Enter the message here"
                                    value={form.message}
                                />
                            </div>
                        </div>

                        <div className="mt-4">
                            <button onClick={handleSubmit} className="bg-sky-500 text-white px-4 py-2 rounded-md">
                                {!sending_message ? `Send message`: <span>Sending message <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

import DashboardLayout from "../../components/DashboardLayout";

import { CheckIcon, ClockIcon } from '@heroicons/react/24/outline'
import { Link, useParams } from "react-router-dom";
import { get_organization_details, get_stripe_session_url } from "../../utils/api";
import { useEffect, useState } from "react";
import human from "human-time";
import { setDocumentHeaders } from "../../utils/document";
import i18next from "../../i18next";
const { t } = i18next;


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Pay(props) {
    
    const { org_id, org_name } = useParams();
    setDocumentHeaders(t("pay_membership_name", {membership_name : org_name }));
    const [global_state, set_global_state] = useState({
        org: {},
        schedule: {},
        pricing: {}
    });
    const [loading_pyt_url, set_loading_pyt_url] = useState(-1);
    const [loading_plans, set_loading_plans] = useState(false);

    const { schedule } = global_state;

    const handle_payment = async (tier, index) => {
        try {
            set_loading_pyt_url(index);
            const res = await get_stripe_session_url({
                org_id,
                schedule_id:schedule._id,
                price: tier.price,
                items: tier.items,
                redirection_url: `${window.location.origin}/str_pyt/${org_id}/${org_name}`,
                title: t("subscription_for_org_name_to_pubcm",{org_name }),
                description: t("payement_for_generation_of_posts_per_month",{tier_posts_per_month : tier.posts_per_month, tier_title : tier.title}),
            });
            set_loading_pyt_url(-1);
            console.log(res);
            window.location.href = res.url;
        } catch (err) {
            console.log(err);
            set_loading_pyt_url(-1);
            alert(t("error_payement_failed"));

        }
    }


    const get_org_details = () => {
        console.log("org_id: ", org_id);
        console.log("org_name: ", org_name);
        set_loading_plans(true);
        get_organization_details(org_id).then((res) => {
            set_loading_plans(false);
            console.log("res: ", res);
            const { org, schedule } = res;

            const posts_per_day = schedule.period === 'day' ? parseInt(schedule.number_of_posts) : parseInt(schedule.number_of_posts) / 7;
            const posts_per_week = schedule.period === 'week' ? parseInt(schedule.number_of_posts) : parseInt(schedule.number_of_posts) * 7;
            const posts_per_month = posts_per_day * 30;

            const monthly_price = parseFloat(parseFloat(posts_per_month) * 0.42).toFixed(2);
            const price_tri = parseFloat(parseFloat(monthly_price) * 1.1).toFixed(2);
            const price_six = parseFloat(parseFloat(monthly_price) * 0.93).toFixed(2);
            const price_twelve = parseFloat(parseFloat(monthly_price) * 0.88).toFixed(2);

            let next_tri_payement = new Date().setMonth(new Date().getMonth() + 3);
            next_tri_payement = new Date(next_tri_payement).toISOString().split('T')[0];
            let next_six_payement = new Date().setMonth(new Date().getMonth() + 6);
            next_six_payement = new Date(next_six_payement).toISOString().split('T')[0];
            let next_twelve_payement = new Date().setMonth(new Date().getMonth() + 12);
            next_twelve_payement = new Date(next_twelve_payement).toISOString().split('T')[0];

            let pricing = {
                tiers: [
                    {
                        title: t("3_months"),
                        price: price_tri,
                        items: 3,
                        frequency: '/month',
                        description: 'Take the first step towards efficient social media management with a 3-month subscription to PubCM.',
                        features: [
                            `${posts_per_day} Posts per day`,
                            'Facebook',
                            'LinkedIn',
                            '24-hour support response time',
                            'Marketing automations',
                        ],
                        cta: `Pay ${(price_tri * 3).toFixed(2)}$`,
                        posts_per_month: posts_per_month,
                        mostPopular: false,
                        next_payement: next_tri_payement
                    },
                    {
                        title: t("12_months"),
                        price: price_twelve,
                        frequency: '/month',
                        items: 12,
                        description: 'Enjoy a year of hassle-free scheduling, analytics, and personalized support.',
                        features: [
                            `${posts_per_day} Posts per day`,
                            'Facebook',
                            'LinkedIn',
                            '24-hour support response time',
                            'Marketing automations',
                            'Marketing objectives',
                            'Posts analytics'
                        ],
                        cta: `Pay ${(price_twelve * 12).toFixed(2)}$`,
                        posts_per_month: posts_per_month,
                        mostPopular: true,
                        next_payement: next_twelve_payement
                    },
                    {
                        title: t("6_months"),
                        price: price_six,
                        frequency: '/month',
                        items: 6,
                        description: 'Streamline your social media management and schedule your posts with ease.',
                        features: [
                            `${posts_per_day} Posts per day`,
                            'Facebook',
                            'LinkedIn',
                            '24-hour support response time',
                            'Marketing automations',
                        ],
                        cta: `Pay ${(price_six * 6).toFixed(2)}$`,
                        posts_per_month: posts_per_month,
                        mostPopular: false,
                        next_payement: next_six_payement
                    },

                ],
            }

            set_global_state({
                org,
                schedule,
                pricing
            });
        }).catch((error) => {
            console.log(error);
            alert("Something went wrong, please try to refresh the page.");
        });

    }

    const posts_per_day = schedule.period === 'day' ? parseInt(schedule.number_of_posts) : (parseInt(schedule.number_of_posts) / 7).toFixed(2);
    const posts_per_week = schedule.period === 'week' ? parseInt(schedule.number_of_posts) : (parseInt(schedule.number_of_posts) * 7).toFixed(2);
    const posts_per_month = posts_per_day * 30;



    useEffect(() => {
        get_org_details();
    }, [global_state.org.length]);

    return (
        <DashboardLayout>
            <div className="mx-auto max-w-7xl bg-white col-span-12 pt-8 pb-24 px-6 lg:px-8">
                {!loading_plans ? <div>
                    <h3 className="text-xl font-bold text-center tracking-tight text-gray-900 sm:text-2xl sm:leading-none lg:text-3xl">
                        Choose membership for {org_name} on PubCM
                    </h3>


                    {/* Tiers */}
                    <div className="lg:mx-20 mt-10 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
                        {global_state.pricing.tiers && global_state.pricing.tiers.map((tier, index) => (
                            <div
                                key={tier.title}
                                className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm"
                            >
                                <div className="flex-1">
                                    <h3 className="text-xl font-semibold text-gray-900">{tier.title}</h3>
                                    {tier.mostPopular ? (
                                        <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-pubcm2 py-1.5 px-4 text-sm font-semibold text-white">
                                            Most popular
                                        </p>
                                    ) : null}
                                    <p className="mt-4 flex items-baseline text-gray-900">
                                        <span className="text-5xl font-bold tracking-tight">${tier.price}</span>
                                        <span className="ml-1 text-xl font-semibold">{tier.frequency}</span>
                                    </p>
                                    <p className="mt-6 text-gray-500">{tier.description}</p>
                                    <div className="mt-1 text-gray-500"> {posts_per_month} posts per month</div>

                                    {/* Feature list */}
                                    <ul role="list" className="mt-6 space-y-6 hidden">
                                        {tier.features.map((feature) => (
                                            <li key={feature} className="flex">
                                                <CheckIcon className="h-6 w-6 flex-shrink-0 text-pubcm2" aria-hidden="true" />
                                                <span className="ml-3 text-gray-500">{feature}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <a
                                    onClick={() => {
                                        handle_payment(tier, index);//.title, tier.price, tier.frequency);
                                    }}
                                    className={classNames(
                                        'cursor-pointer',
                                        tier.mostPopular
                                            ? 'bg-pubcm2 text-white hover:bg-pubcm1'
                                            : 'bg-indigo-50 text-pubcm2 hover:bg-sky-100',
                                        'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
                                    )}
                                >
                                    {loading_pyt_url === index ? 'Loading ...' : tier.cta}


                                </a>
                                <div className="text-center mt-2 text-slate-600"><small>Next payement :</small> {tier.next_payement}</div>
                            </div>
                        ))}
                    </div>

                    <div className="mt-6 mx-auto w-1/2 text-gray-500 text-center">



                        <div className="text-center text-32 my-4">
                            <div className="text-sm">Your schedule is :</div>
                            <ul>
                                <li>Posts per day : {posts_per_day}</li>
                                <li>Posts per week : {posts_per_week}</li>
                                <li>Posts per month : {posts_per_month}</li>
                                <li>Words per post : {schedule.words_number}</li>
                            </ul>

                            <div className=" "><Link className="text-pubcm2 px-2 text-xs" to={`/schedule/setup/${org_id}/${org_name}`}> <ClockIcon className="h-4 w-4 inline" /> Update schedule</Link></div>
                        </div>

                        <div className=" text-xl">
                            Thank you for choosing PubCM! We are glad you have decided to automate your social media management with us.
                        </div>

                    </div>
                </div> : <div className="text-center text-32 my-4">Loading plans ...</div>
                }
            </div>
        </DashboardLayout>
    )
}

import { ExclamationTriangleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon } from '@heroicons/react/24/outline';

export default function Alert(props) {
    const colors = {
        'error' : {
            'bg' : 'bg-red-50',
            'text' : 'text-red-800',
            'message' : 'text-red-700',
            'icon' : 'text-red-400',
            'close_btn_bg' : 'bg-red-400',
            'close_btn_bg_hover' : 'bg-red-600'
        },
        'success' : {
            'bg' : 'bg-green-50',
            'text' : 'text-green-800',
            'message' : 'text-green-700',
            'icon' : 'text-green-400',
            'close_btn_bg' : 'bg-green-400',
            'close_btn_bg_hover' : 'bg-green-600'
        },
        'warning' : {
            'bg' : 'bg-yellow-50',
            'text' : 'text-yellow-800',
            'message' : 'text-yellow-700',
            'icon' : 'text-yellow-400',
            'close_btn_bg' : 'bg-yellow-400',
            'close_btn_bg_hover' : 'bg-yellow-600'
        },
    };

    const selected_color = colors[props.type] || colors['error'];

    const icons = {
        'error' : <XCircleIcon className={`h-5 w-5 ${selected_color.icon}`} aria-hidden="true" />,
        'success' : <CheckCircleIcon className={`h-5 w-5 ${selected_color.icon}`} aria-hidden="true" />,
        'warning' : <ExclamationTriangleIcon className={`h-5 w-5 ${selected_color.icon}`} aria-hidden="true" />,
    };
    
  return (
    <div className={`rounded-md ${selected_color.bg} p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
            {icons[props.type]}
        </div>
        <div className="ml-3 flex-auto">
          <h3 className={`text-sm font-medium ${selected_color.text}`}>{props.title}</h3>
          <div className={`mt-2 text-sm  ${selected_color.message}`}>
            <p>
              {props.message}
            </p>
          </div>
        </div>
        <div className='flex-2'>
          <button className={`ml-auto px-2 py-2 rounded-md text-white ${selected_color.close_btn_bg} hover:${selected_color.close_btn_bg_hover}`} onClick={props.onClose}>
            <XMarkIcon className='h-4'/>
          </button>
        </div>
      </div>
    </div>
  )
}

import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { Bars3BottomLeftIcon, DocumentIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import Alert from './Alert'
import { Link } from 'react-router-dom'
import { experts, humors, well_known_people } from '../utils/personality'
import { get_personalitty, save_personalitty, test_personality } from '../utils/api'
import Loading from './Loading'

import i18next from '../i18next';
const { t } = i18next;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

let time_out_change_range = null;

export default function SlideOverPersonality(props) {
  const setOpen = () => {
    props.fn_state({ open: false });
  }

  const who_am_i = [
    { name: t("celebrity"), description: t("i_am_a_celebrity"), value: 'celebrity' },
    { name: t("an_expert"), description: t("i_am_an_expert"), value: 'expert' },
    { name: t("i_will_define_my_self"), description: t("i_will_define_my_self"), value: 'define' }
  ];

  const [saving_personalitty, set_saving_personalitty] = useState(false);
  const [testing_personalitty, set_testing_personalitty] = useState(false);
  const [has_testing_access, set_has_testing_access] = useState(false);

  const [global_state, set_global_state] = useState({
    who_am_i: 'celebrity',
    what_do_i_do: experts[0],
    celebrity: well_known_people[0],
    define_me: '',
    test_message: '',
    humor: 3,
    humor_obj: humors[3],
    name: humors[3].type + " " + well_known_people[0] + " " + t("for") + " " + props.org.name,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let personalitty_name = "";

    if (name === "celebrity" || name === "what_do_i_do" || name === "define_me") {
      personalitty_name = value;
    }

    set_global_state({
      ...global_state,
      [name]: value,
      name: global_state.humor_obj.type + " " + personalitty_name + " " + t("for") + " " + props.org.name
    });
  }

  const handleRangeChange = (e) => {
    const { name, value } = e.target;

    let personalitty_name = "";

    switch (global_state.who_am_i) {
      case "celebrity": personalitty_name = global_state.celebrity; break;
      case "expert": personalitty_name = global_state.what_do_i_do; break;
      case "define": personalitty_name = global_state.define_me; break;
    }

    const humor_index = Math.floor(value / 20) - 1;
    const selected_humor = humors[Math.floor(value / 20) - 1];
    set_global_state({
      ...global_state,
      "humor": humor_index,
      "humor_obj": selected_humor,
      "name": selected_humor.type + " " + personalitty_name + " " + t("for") + " " + props.org.name
    });
  }

  const handleSavePersonallity = async () => {
    try {
      set_saving_personalitty(true);
      const resp = await save_personalitty(props.org._id, global_state);
      set_saving_personalitty(false);
    } catch (ex) {
      console.log(ex);
      set_saving_personalitty(false);
      alert(t("failed_to_save_personality_try_again"));
    }
  }

  const getLatestPersonality = async () => {
    
    try {
      const resp = await get_personalitty(props.org._id, global_state);
      if (resp && resp.personalitty) {
        if (resp.personalitty.raw_obj) {

          set_global_state(resp.personalitty.raw_obj);
        }

      }
    } catch (ex) {
      console.log(ex);
      //alert("Failed to save personality, please try again later.");
    }
    console.log(props.user_org.payement);
    set_has_testing_access(props.user_org && props.user_org.payement && props.user_org.payement.features && props.user_org.payement.features ? props.user_org.payement.features.test_customization : false);
    //console.log(has_testing_access);
    //console.log(props.user_org.payement.features.features);
  }

  const handleTestPersonallity = async () => {
    try {
      set_testing_personalitty(true);
      const resp = await test_personality(props.org._id, global_state);
      set_testing_personalitty(false);
      if (resp.message) {
        set_global_state({
          ...global_state,
          test_message: resp.message
        });
      }
      console.log(resp);
    } catch (ex) {
      console.log(ex);
      set_testing_personalitty(false);
      alert(t("failed_to_save_personality_try_again"));
    }
  }

  useEffect(() => {
    if (props.state.open) {

      getLatestPersonality();
    } else {
      set_global_state({
        who_am_i: 'celebrity',
        what_do_i_do: experts[0],
        celebrity: well_known_people[0],
        define_me: '',
        test_message: '',
        humor: 3,
        humor_obj: humors[3],
        name: humors[3].type + " " + well_known_people[0] + " " + t("for") + " " + props.org.name,
      });
    }
  }, [props.state.open]);

  return (
    <Transition.Root show={props.state.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6 pb-2">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">{t("customize_your_AI")}</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-pubcm1"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">{t("close_panel")}</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className='text-center px-2 pb-2'>
                      <div className='mt-4 border p-2 bg-gray-50'>
                        <div>{t("who_am_i")}</div>
                        <div>
                          <select
                            id="who_am_i"
                            name="who_am_i"
                            value={global_state.who_am_i}
                            onChange={handleChange}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          >
                            {who_am_i.map((item, index) => {
                              return <option key={index} value={item.value}>{item.name}</option>
                            })}
                          </select>
                        </div>
                      </div>

                      {global_state.who_am_i && global_state.who_am_i === "expert" && <div className='mt-4 border p-2 bg-gray-50'>
                        <div>{t("what_do_i_do")}</div>
                        <div>
                          <select
                            id="what_do_i_do"
                            name="what_do_i_do"
                            value={global_state.what_do_i_do}
                            onChange={handleChange}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          >
                            {experts && experts.sort().map((item, index) => {
                              return <option key={item} value={item}>{item}</option>
                            })}
                          </select>
                        </div>
                      </div>}

                      {global_state.who_am_i && global_state.who_am_i === "celebrity" && <div className='mt-4 border p-2 bg-gray-50'>
                        <div>{t("choose_a_celebrity")}</div>
                        <div>
                          <select
                            id="celebrity"
                            name="celebrity"
                            value={global_state.celebrity}
                            onChange={handleChange}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          >
                            {well_known_people.sort().map((item, index) => {
                              return <option key={item} value={item}>{item}</option>
                            })}
                          </select>
                        </div>
                      </div>}

                      {global_state.who_am_i && global_state.who_am_i === "define" && <div className='mt-4 border p-2 bg-gray-50'>
                        <div>{t("define_who_i_am")}</div>
                        <div>
                          <input
                            type="text"
                            name="define_me"
                            placeholder={t("eg_i_am_a_physician_who_loves_to_help_people")}
                            id="define_me"
                            onChange={handleChange}
                            value={global_state.define_me}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>}

                      {humors && <div className='border p-2 mt-4 bg-gray-50'>
                        <div>{t("humor")}</div>
                        <div className='text-4xl my-2'>{humors[global_state.humor].emoji}</div>
                        <div className='my-4'>

                          <input
                            type="range"
                            step={20}
                            min={20}
                            max={120}
                            value={((global_state.humor + 1) * 20)}
                            className="slider w-full"
                            onChange={handleRangeChange}
                            id="myRange"
                          />
                        </div>
                        <div>{humors[global_state.humor].type}</div>
                        <div className='text-xs'><small>{humors[global_state.humor].description}</small></div>
                      </div>}

                      <div className='border p-2 mt-4 bg-gray-50'>
                        <div>{t("sample_post")}</div>
                        <div className='text-md my-2'>
                          {!testing_personalitty && <div>{global_state.test_message}</div>}
                          {!testing_personalitty && global_state.test_message === "" && <div className="text-slate-400 text-sm px-10"> <div><Bars3BottomLeftIcon className='h-10 w-10 inline' />{t("your_sample_post_will_show_up_here")}</div> </div>}
                          {testing_personalitty && <div className=''>
                            <Loading style={{ display: "inline-block" }} />
                            <div>{t("generating_sample_post")}</div>
                          </div>}
                        </div>
                      </div>

                      <div className='border p-2 mt-4 bg-gray-50'>
                        <div>{t("name_this_personality")}</div>
                        <div className='text-2xl my-2'>
                          <input
                            type="text"
                            name="name"
                            placeholder={t("eg_my_personality")}
                            id="name"
                            onChange={handleChange}
                            value={global_state.name}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      {!has_testing_access && <div className='my-1 mt-5 text-xs text-left text-gray-500'>
                          {/* display translated words to_test_your_custom_ai_you_need_a_pro_or_business_membership knowing it contains <u> HTML tags */}
                          <div dangerouslySetInnerHTML={{ __html: t("to_test_your_custom_ai_you_need_a_pro_or_business_membership") }} />
                      </div>}
                      <div className='pt-2 flex gap-x-2'>
                        <button 
                          onClick={handleTestPersonallity} 
                          disabled={saving_personalitty || testing_personalitty || !has_testing_access} 
                          className={has_testing_access ? `w-full rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm border border-slate-400` : `w-full rounded-md border border-transparent px-4 py-2 text-sm text-orange-400 font-medium shadow-sm border border-orange-400`}
                        >
                          {testing_personalitty ? t("testing") : t("test") } 
                          {!has_testing_access && <div className='text-xs'><small>{t("unaivalable_in_your_plan")}</small></div>}
                        </button>
                        <button onClick={handleSavePersonallity} disabled={saving_personalitty || testing_personalitty} className="w-full rounded-md border border-transparent bg-pubcm2 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-pubcm1">
                          {saving_personalitty ? t("saving") : t("save")}
                        </button>
                      </div>
                      
                      
                      <div className='my-2 text-gray-800'>{t("your_upcoming_posts_will_be_generated_by", {name : global_state.name})}</div>

                      <div className='pb-10'></div>

                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import { Switch } from "@headlessui/react";
import { useState } from "react";
import i18next from "../../../../i18next";
const { t } = i18next;

export default function AddImages(props) {

    const handleChange = () => {
        const checked = props.state.include_images;
        props.update_fn({ ...props.state, include_images: !checked });
    };

    return (
        <div className="flex justify-between">
            <div className="text-lg text-left">
                {t("include_images_in_posts")}
                <div className="text-left text-xs w-3/4 my-2">
                    {t("images_are_great_to_illustrate_posts_to_increase_engagement")}<br/>
                    {t("this_option_will_increase_by_15_pctg_the_price")}
                </div>
            </div>
            <div className="flex items-center">
                <div className="mr-2">{t("no")}</div>
                <Switch
                    checked={props.state.include_images}
                    onChange={handleChange}
                    className={`${props.state.include_images ? 'bg-pubcm2' : 'bg-gray-200'}
                                    relative inline-flex items-center h-6 rounded-full w-11`}
                >
                    <span className="sr-only">{t("include_images_in_posts")}</span>
                    <span

                        className={`${props.state.include_images ? 'translate-x-6' : 'translate-x-1'}
                                        inline-block w-4 h-4 transform bg-white rounded-full`}
                    />
                </Switch>
                <div className="ml-2">{t("yes")}</div>
            </div>
        </div>
    )
}
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, PencilIcon } from '@heroicons/react/24/outline'
import human from 'human-time';
import { update_post } from '../../../../utils/api';

export default function ModalEditPost(props) {  
    const [save_button_txt, set_save_button_txt] = useState("Save");
    const cancelButtonRef = useRef(null);
    
    const {post, open, post_index} = props.state;
    const setState = props.setState;
    let new_body = post.body;

    const handleChangeDiv = async(e) => {
        e.preventDefault();
        new_body = e.currentTarget.textContent;
    }

    const handleSavePost = async() => {
        try{
            set_save_button_txt("Saving ...");
            const res = await update_post(post._id, {body: new_body});
            set_save_button_txt("Save");
            setState({...props.state, post : {...props.state.post, body : new_body}, open :false});
            console.log(props.global_state);

            const newDocs = [...props.global_state.posts.docs];
            newDocs[post_index].body = new_body;
            newDocs[post_index].edited = new_body;
            props.set_global_state({
                ...props.global_state,
                posts: {
                    ...props.global_state.posts,
                    docs: newDocs
                }
            });

        }catch(ex){
            console.log(ex);
            set_save_button_txt("Save");
            alert("Something went wrong, please try again later.");
            
        }
        
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setState}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <PencilIcon className="h-6 w-6 text-sky-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title as="h3" className="text-md font-medium leading-6 text-gray-900">
                                            Edit post
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <div className='text-xs text-gray-500'>Old post</div>
                                            <div className="text-sm text-gray-500 text-justify bg-slate-100 px-2 py-2 my-2 rounded">
                                                {post.body}
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className=''>
                                                <div className='text-xs text-gray-500'>New post {post.publishAt && <span> to be published this {post.publishAt.substring(0,10)} at {post.publishAt.substring(11,16)}</span>}</div>
                                                
                                                <div onInput={handleChangeDiv} suppressContentEditableWarning={true} contentEditable="true" className="mt-1 text-slate-700 focus:ring-sky-500 text-justify block w-full shadow-sm sm:text-sm border-2 border-sky-400 px-2 py-2 rounded-md">{post.body}</div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-pubcm2 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-pubcm1 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={handleSavePost}
                                    >
                                        {save_button_txt}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={() => setState({post : {}, open :false})}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
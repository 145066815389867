import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { get_org_admins, org_add_admin, org_remove_admin } from '../utils/api'
import Alert from './Alert'

import i18next from '../i18next';

const t = i18next.t;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SlideOverAdmin(props) {
  //const [open, setOpen] = useState(true);
  // console.log(props.org);
  const my_email = JSON.parse(localStorage.getItem("user")).email;
  let my_role = "admin";
  console.log(props);
  if(props.user_org && props.user_org.admins){
    const my_admin = props.user_org.admins.find(admin => admin.email === my_email);
    if(my_admin) my_role = my_admin.role;
  }

  const setOpen = () => {
    props.fn_state({ open: false });
  }

  //alert(JSON.stringify(props.org));
  const [show_loading_admins, set_show_loading_admins] = useState(false);
  const [show_invite_administrators, set_show_invite_administrators] = useState(false);
  const [adding_admin, set_adding_admin] = useState(false);
  const [adding_admin_form, set_adding_admin_form] = useState({
    email: ""
  });

  const [admins, set_admins] = useState([]);

  const [resp, set_resp] = useState({
    type: "",
    title: "",
    msg: "",
    show: false
  });

  const handleChangeNewAdmin = (e) => {
    set_adding_admin_form({ ...adding_admin_form, [e.target.name]: e.target.value });
  }

  const handleSubmitNewAdm = async (e) => {
    e.preventDefault();
    try {
      set_adding_admin(true);
      set_resp({ type: "", title: "", msg: "", show: false });
      const res_add_adm = await org_add_admin(props.org._id, {
        email: adding_admin_form["email"]
      });
      set_adding_admin(false);

      if (res_add_adm.type === "success") {
        set_resp({
          type: "success",
          title: t("administrator_added"),
          msg: t("administrator_added_successfully"),
          show: true
        });
        //alert(JSON.stringify(res_add_adm.user));
        if (res_add_adm.admin) set_admins([...admins, res_add_adm.admin]);
        set_adding_admin_form({ email: "" });

        const can_add_admin = max_admins > [...admins, res_add_adm.admin].length;
        let reason = max_admins > admins.length ? 'max' : '';
        reason = props.user_org && props.user_org.payement && props.user_org.payement.status === "paid" ? (reason ? reason : '') : 'pay';

        set_can_add_admin({
          can: can_add_admin,
          reason: reason
        });

      } else {
        set_resp({
          type: "error",
          title: t("error_adding_admin"),
          msg: res_add_adm.error,
          show: true
        });
      }
      console.log(res_add_adm);
    } catch (ex) {
      set_adding_admin(false);
      set_resp({
        type: "error",
        title: t("error_adding_admin"),
        msg: t("error_adding_admin_please_try_again"),
        show: true
      });
      console.log(ex);
    }

    setTimeout(() => {
      set_resp({ type: "", title: "", msg: "", show: false });
    }, 5000);

  }

  const handleDeleteAdmin = async (user_id, index) => {
    if (user_id) {
      if (window.confirm(t("are_you_sure_you_want_to_delete_admin"))) {
        const res = await org_remove_admin(props.org._id, user_id);
        if (res.type === "success") {
          const new_admins = [...admins];
          new_admins.splice(index, 1);
          set_admins(new_admins);

          const can_add_admin = max_admins > new_admins.length;
          let reason = max_admins > admins.length ? 'max' : '';
          reason = props.user_org && props.user_org.payement && props.user_org.payement.status === "paid" ? (reason ? reason : '') : 'pay';

          set_can_add_admin({
            can: can_add_admin,
            reason: reason
          });
        }
      }
    }
  }

  const [can_add_admin, set_can_add_admin] = useState({
    can: false,
    reason: ""
  });

  const max_admins = props.user_org && props.user_org.payement && props.user_org.payement.features ? props.user_org.payement.features.max_admin : 1;

  const handle_get_admins = async () => {
    try {
      set_show_loading_admins(true);
      const res = await get_org_admins(props.org._id);

      const can_add_admin = max_admins > res.admins.length;
      let reason = max_admins > admins.length ? 'max' : '';
      reason = props.user_org && props.user_org.payement && props.user_org.payement.status === "paid" ? (reason ? reason : '') : 'pay';

      set_can_add_admin({
        can: can_add_admin,
        reason: reason
      });

      set_show_loading_admins(false);
      if (res.admins) set_admins(res.admins);
    } catch (ex) {
      set_show_loading_admins(false);
      console.log(ex);
    }
  }


  useEffect(() => {
    if (props.state.open) {
      handle_get_admins();
    } else {
      set_admins([]);
    }
  }, [props.state.open]);



  return (
    <Transition.Root show={props.state.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >

                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">{t("administrators")} ({admins.length}/{max_admins}) </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">

                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-pubcm1"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">{t("close_panel")}</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                      <form onSubmit={handleSubmitNewAdm}>
                        <div className='flex py-2 '>
                          <div className="flex-auto">
                            <input disabled={!can_add_admin.can} onChange={handleChangeNewAdmin} value={adding_admin_form.email} type="email" name="email" className='w-full rounded-l-md border border-slate-300 focus:ring-pubcm1' placeholder={t("enter_admin_email")} />
                          </div>
                          <div className='flex-auto'>
                            <button disabled={adding_admin || !can_add_admin.can} type='submit' className='bg-pubcm2 text-white p-2 shadow rounded-md rounded-l-none w-full rounded-left-0'>{t("add")}</button>
                          </div>
                        </div>
                        {adding_admin && <div className='text-xs text-slate-500 py-2'>{t("adding_admin")} ...</div>}
                        {!adding_admin && resp.show && <div>
                          <Alert type={resp.type} title={resp.title} message={resp.msg} />
                        </div>}
                      </form>
                    </div>

                    {!can_add_admin.can && (can_add_admin.reason && can_add_admin.reason === "max") && <div className='px-4 my-2'>
                      <Alert type="warning" title={t("cant_add_more_admins")} message={t("your_membership_does_not_allow_to_add_more_admin")} />
                    </div>}

                    {!can_add_admin.can && (can_add_admin.reason && can_add_admin.reason === "pay") && <div className='px-4 my-2'>
                      <Alert type="warning" title={t("cant_add_more_admins")} message={t("you_need_an_active_membership_to_add_more_admins")} />
                    </div>}



                    <ul role="list" className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                      {admins && admins.map(({ user }, index) => (
                        <li key={user._id}>
                          <div className="group relative flex items-center py-6 px-5">
                            <a href="#" className="-m-1 block flex-1 p-1">
                              <div className="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true" />
                              <div className="relative flex min-w-0 flex-1 items-center">
                                <span className="relative inline-block flex-shrink-0">
                                  <img className="h-10 w-10 rounded-full" src={user.picture} alt="" />
                                </span>
                                <div className="ml-4 truncate">
                                  <p className="truncate text-sm font-medium text-gray-900">{user.names}</p>
                                  <p className="truncate text-sm text-gray-500">{user.email}</p>
                                  <p className='text-xs text-gray-500'>{t("role")} : {admins[index].role}</p>                                  
                                </div>
                              </div>
                            </a>
                            {my_role === "owner" && admins[index].role === "admin" && <Menu as="div" className="relative ml-2 inline-block flex-shrink-0 text-left">
                              <Menu.Button className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <span className="sr-only">{t("open_options_menu")}</span>
                                <span className="flex h-full w-full items-center justify-center rounded-full">
                                  <EllipsisVerticalIcon
                                    className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Menu.Button>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute top-0 right-9 z-10 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          onClick={() => handleDeleteAdmin(user._id, index)}
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                          )}
                                        >
                                          {t("delete")}
                                        </a>
                                      )}
                                    </Menu.Item>

                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import { Fragment, useRef, useState } from 'react'
import {
    CalendarIcon,
    FaceFrownIcon,
    FaceSmileIcon,
    FireIcon,
    HandThumbUpIcon,
    HeartIcon,
    PaperAirplaneIcon,
    PaperClipIcon,
    SparklesIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid'
import { Listbox, Transition } from '@headlessui/react'
import { get_generate_text_post, save_new_post } from '../../../utils/api';
import SlideOverPickFile from '../../../components/SlideOverPickFile';
import './spinning-border.css';
import Alert from '../../../components/Alert';
import i18next from '../../../i18next';

const { t } = i18next;

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function InputTextNewPost(props) {
    // const { post_evt } = props;
    const user_info = JSON.parse(localStorage.user);
    const org_id = localStorage.selected_org_id;
    const [show_posting_post, set_show_posting_post] = useState(false);

    

    let linking_file_post_index = 0;

    const input_date_ref = useRef(null);

    const [post_data, set_post_data] = useState({
        post_text: '',
        post_pictures: [{ url: '' }],
        post_date_time: ''
    });

    const [posting_error, set_posting_error] = useState({
        show : false,
        type : 'error',
        title : t("error"),
        message : t("an_error_occured_posting_scheduling_to_your_channels")
    });

    const handle_close_posting_error = () => {
        set_posting_error({
            ...posting_error,
            show : false
        });
    }



    const [loading_ai_text, set_loading_ai_text] = useState(false);

    const handle_generate_text = async () => {
        try{
            set_loading_ai_text(true);
            const ai_result = await get_generate_text_post(org_id, {text : post_data.post_text});
            set_loading_ai_text(false);
            if(ai_result && ai_result.error){
                set_posting_error({
                    ...posting_error,
                    show : true,
                    type : ai_result.error.type,
                    title : ai_result.error.title,
                    message : ai_result.error.message
                });
                return;
            }
            console.log(ai_result);
            set_post_data((prevState) => ({
                ...prevState,
                post_text: ai_result.output_text
            }));
        }catch(ex){
            set_loading_ai_text(false);
            console.log(ex);
            set_posting_error({
                ...posting_error,
                show : true,
                type : 'error',
                title : t("error"),
                message : t("an_error_occured_generating_text")
            });
            // alert("An error occured generating text");
        }
    }

    const [origin_post, set_origin_post] = useState({});
    const [show_pick_file, set_show_pick_file] = useState(false);

    const handleUpdatePostFile = async (post, file, post_index) => {
        console.log(post, file, post_index);
        set_post_data((prevState) => ({
            ...prevState,
            post_pictures: [{ url: file.url }]
        }));
    }

    const handle_show_pick_file = (index, post) => {
        if (post) {
            linking_file_post_index = index;
            set_origin_post(post);
        }
        set_show_pick_file(!show_pick_file);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        set_post_data((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handlePostSubmit = async (e) => {
        e.preventDefault();

        const { post_text, post_pictures, post_date_time } = post_data;

        try {
            set_show_posting_post(true);
            const res = await save_new_post(org_id, {
                body: post_text,
                publish_at: post_date_time,
                images: post_pictures
            });
            set_show_posting_post(false);
            // create posts_event with value res.post and dispatch it
            const posts_event = new CustomEvent('added_post', { detail: { post: res.post } });

            window.dispatchEvent(posts_event);

            set_post_data({
                post_text: '',
                post_pictures: [{ url: '' }],
                post_date_time: ''
            });
        } catch (ex) {
            set_show_posting_post(false);
            alert(t("an_error_occured_posting_to_channels"));
        }
    }

    return (
        <div>
            <SlideOverPickFile post_index={linking_file_post_index} post={origin_post} handle_select={handleUpdatePostFile} fn_show={handle_show_pick_file} show={show_pick_file} org_id={org_id} />
            <div className="flex items-start lg:space-x-4">

                <div className="flex-shrink-0 hidden lg:block">
                    <img
                        className="inline-block h-10 w-10 rounded-full"
                        src={user_info.picture}
                        alt=""
                    />
                </div>
                <div className="min-w-0 flex-1">
                    <form action="#" className="relative" onSubmit={handlePostSubmit}>
                        <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-pubcm1">
                            <label htmlFor="comment" className="sr-only">
                                {t("write_your_post_here")}
                            </label>
                            <textarea
                                rows={3}
                                required
                                name='post_text'
                                onChange={handleChange}
                                value={post_data.post_text}
                                className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder={t("write_your_post_here")}
                            />

                            <div className="py-2" aria-hidden="true">
                                <div className="py-px">
                                    <div className="h-9" />
                                </div>
                            </div>


                        </div>

                        <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                            <div className="flex items-center space-x-5">
                                <div className="flex items-center">
                                    <button
                                        type="button"
                                        onClick={() => set_show_pick_file(true)}
                                        className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                                    >
                                        <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                                        <span className="sr-only">{t("attach_a_file")}</span>
                                    </button>
                                </div>
                                <div className="flex items-center">
                                    <button
                                        type="button"
                                        disabled={loading_ai_text}
                                        onClick={handle_generate_text}
                                        className={`
                                            -m-2.5 flex h-10 items-center 
                                            lg:px-4 
                                            justify-center rounded-full 
                                            text-white
                                            hover:bg-gradient-to-r hover:from-pubcm1 hover:to-green-500
                                             text-white bg-pubcm1
                                            transition ${loading_ai_text ? 'animate-bounce  bg-gradient-to-r from-pubcm1 to-emerald-500 '  : ' hover:animate-pulse '}`}
                                    >
                                        <SparklesIcon className="h-5 lg:mr-2" aria-hidden="true" />
                                        <span className="hidden lg:inline">{loading_ai_text ? t("loading") : t("flash_completion")}</span>
                                    </button>
                                </div>

                            </div>

                            <div className="flex-shrink-0">
                                <span className='text-xs text-slate-500'>{t("date")}</span>
                                <input
                                    name="post_date_time"
                                    id="post_date_time"
                                    onChange={handleChange}
                                    value={post_data.post_date_time}
                                    ref={input_date_ref}
                                    type="datetime-local"
                                    className="text-slate-500 w-12 lg:w-auto lg:inline lg:text-sm border-gray-200 rounded-md mx-2 focus:ring-pubcm1"
                                />
                                <button
                                    type="submit"
                                    className="inline-flex items-center rounded-md bg-pubcm1 pl-4 pr-2 lg:px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pubcm2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    {<span><PaperAirplaneIcon className='h-4 inline text-yellow-200 mr-2' /> <span className='hidden lg:inline'>{show_posting_post ? t("posting_or_planning") : t("post_or_plan")}</span></span>}
                                </button>
                            </div>

                        </div>


                    </form>

                    {posting_error && posting_error.show && <div className='py-2'>
                        <Alert onClose={handle_close_posting_error} type={posting_error.type} title={posting_error.title} message={posting_error.message} />
                    </div>}

                    <div>
                        {post_data.post_pictures.map((picture, i) => picture.url ? <div key={`new-post-pic-` + i} className='my-2 relative inline-block'>
                            <img
                                src={picture.url}
                                className="w-72 rounded-md shadow"
                                alt={t("post_illustration")}
                            />
                            <button onClick={() => {
                                set_post_data((prevState) => ({
                                    ...prevState,
                                    post_pictures: [{ url: '' }]
                                }));
                            }} className='bg-red-500 hover:bg-red-600 text-white py-2 px-2 rounded-full m-2 absolute top-2 right-2'><XMarkIcon className='w-5 h-5' /></button>
                        </div> : <span key={`empty-${i}`}></span>
                        )}
                    </div>
                </div>

            </div>

        </div>
    )
}

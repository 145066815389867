import human from "human-time";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../components/DashboardLayout";

import passing_with_dog from '../../res/img/passing_with_dog.svg';
import { get_all_users, get_system_configuration, get_user, set_system_configuration, update_user } from "../../utils/api";


import { Switch } from '@headlessui/react'
import { EnvelopeIcon, PresentationChartBarIcon, UserIcon } from "@heroicons/react/20/solid";
import AdminLayout from "./AdminLayout";

const people = [
    {
        name: 'Lindsay Walton',
        title: 'Front-end Developer',
        department: 'Optimization',
        email: 'lindsay.walton@example.com',
        role: 'Member',
        image:
            'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
]

export default function AdminHome() {
    const user = JSON.parse(localStorage.user);

    let config = {
        name : "webapp",
        value : {}
    };

    const [betaEnabled, setBetaEnabled] = useState(false)

    const get_system_config = async () => {
        if(user._id){
            const conf = await get_system_configuration(user._id);
            
            if(conf && conf.config){
                console.log(conf);
                config = conf.config;
                setBetaEnabled(conf.config.value.direct_sign_up);
            }
        }
    }

    const handleChangeBetaMode = async (checked) => {
        setBetaEnabled(checked);
        config.value = {
            ...config.value,
            direct_sign_up : checked,
            default_user_status : checked ? "active" : "wait"
        }
        
        if(user._id){
            const conf = await set_system_configuration(user._id, config);
            alert("Configuration updated successfully\n\n" + JSON.stringify(conf));
        }
        
    }

    useEffect(() => {
        get_system_config();
    }, []);

    return (
        <AdminLayout>
            <div className="col-span-12 bg-white py-2">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Admin</h1>

                        </div>

                    </div>
                    {/* A section showing cards with options to admin pages like Users, Notifications, Configurations */}
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                        <ul className="divide-y divide-slate-300">
                            <li className="text-slate-600 my-2 py-2"><Link to="/admin/users"><UserIcon className="h-5 inline mr-2"/> Manage users</Link></li>
                            <li className="text-slate-600 my-2 py-2"><Link to="/admin/news"><EnvelopeIcon className="h-5 inline mr-2"/> Send notifications</Link></li>
                        </ul>

                    </div>

                    <div className="py-6 border-t-2 border-slate-200">
                        {/* Create a switch allowing to enable beta mode or production mode for all the website */}
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    
                                </div>
                                <div className="ml-4">
                                    <div className="text-sm font-medium text-gray-900">
                                        Direct sign up
                                    </div>
                                    <div className="text-sm text-gray-500">
                                       Enable direct sign up
                                    </div>
                                </div>
                            </div>
                            <div className="ml-5 flex-auto flex-shrink-0">
                                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                    <EnableBetaToggle enabled={betaEnabled} handleChange={handleChangeBetaMode}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function EnableBetaToggle(props) {
  

  return (
    <Switch
      checked={props.enabled}
      onChange={props.handleChange}
      className={classNames(
        props.enabled ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          props.enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  )
}

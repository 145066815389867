import {
    CalendarIcon,
    ClockIcon,
    CurrencyDollarIcon
  } from '@heroicons/react/20/solid';

import { Cog6ToothIcon } from "@heroicons/react/24/outline";

import {Link} from "react-router-dom";

import i18next from '../../../i18next';
const { t } = i18next;


export default function Plans (props){
    const {schedule, payement} = props;
    console.log("PAYEMENT ", payement);

    if(schedule){
        const posts_per_day = schedule.period === 'day' ? parseInt(schedule.number_of_posts) : parseInt(schedule.number_of_posts) / 7 ;
        const posts_per_week = schedule.period === 'week' ? parseInt(schedule.number_of_posts) : parseInt(schedule.number_of_posts) * 7 ;
        const posts_per_month = posts_per_day * 30 ;

        const language =  schedule.prefered_language.charAt(0).toUpperCase() + schedule.prefered_language.slice(1)

        console.log(schedule);

        return (
            <div className="top-6 space-y-4 bg-white px-6 py-2 my-4 relative">
                <div className='text-md text-bold'>{t("my_schedule")}
                { payement &&  <Link className="absolute right-6 font-light text-slate-600 bg-slate-100 hover:bg-sky-200 px-2 py-2 rounded-md text-xs" to={`/schedule/setup/${props.org_id}/${props.org_name}`}> <Cog6ToothIcon className="h-5 inline  font-normal"/> <span className="">{t("manage")}</span></Link>}
                </div>

                
                <div className='mb-4'>
                    <div>
                        <table>
                            <tbody>
                                <tr><td className='text-xs'>{t("posts_per_day")}</td><td>: {parseFloat(posts_per_day).toFixed(1)}</td></tr>
                                <tr><td className='text-xs'>{t("posts_per_day")}</td><td>: {parseFloat(posts_per_week).toFixed(1)}</td></tr>
                                <tr><td className='text-xs'>{t("posts_per_month")}</td><td>: {parseFloat(posts_per_month).toFixed(1)}</td></tr>
                            </tbody>
                        </table>
                    </div>
                    
                <div className='text-sm text-slate-600'>{t("posts")} {schedule.period === 'day' ? t("every_day") : t("every_week")}</div>
                        <ul className='ml-2'>
                            {
                                schedule.period === 'day' ?
                                schedule.time_to_post.map((time_, index) => {
                                    return <li className='text-gray-700' key={index}><ClockIcon className='h-4 w-4 text-gray-300 inline mr-1'/> {time_.time}</li>
                                }) :
                                schedule.day_time_to_post.map((day_, index) => {
                                    return day_.checked && <li className='text-gray-700' key={index}><CalendarIcon className='h-4 w-4 text-gray-300 inline mr-1'/> {day_.label} :  {day_.time.map((time_, i) => i === 0 ? time_.time : `, ${time_.time}`)}</li>
                                }) 
                            }
                            
                        </ul>
                </div>

                <div className='text-sm text-slate-600'>
                    <div className='mb-2'><span className='text-xs'>{t("language")}</span> : {(language)}</div>
                </div>

                { schedule.time_zone && <div className='text-sm text-slate-600'>
                    <div className='mb-2'><span className='text-xs'>{t("timezone")}</span> : {(schedule.time_zone)}</div>
                </div> }

                <div className='text-sm text-slate-600'>
                    <div className='mb-2'><span className='text-xs'>{t("number_of_words")}</span> : {schedule.words_number}</div>
                </div>
                { !( payement && payement.features && payement.features.max_posts && payement.features.max_posts !== 0 ) ? <div>
                        <div>
                            <Link to={`/schedule/setup/${props.org_id}/${props.org_name}`}>
                                <button className="px-4 py-2 bg-sky-200  rounded-md ">
                                    <CalendarIcon className='h-5 w-5 inline mr-2 text-gray-600'/> 
                                    {t("schedule_setup")}
                                </button>
                            </Link>
                        </div>

                        <div className='pt-2'>
                            <Link to={`/pay/${props.org_id}/${props.org_name}`}>
                                <button className="px-4 py-2 bg-slate-600 text-white  rounded-md ">
                                    <CurrencyDollarIcon className='h-5 w-5 inline mr-2'/>
                                    {t("buy_plan")}
                                </button>
                            </Link>
                        </div>
                    </div> : <div className='text-xs'><div className='h-2 w-2 rounded-xl bg-green-400 inline-block mr-1'></div> {t("you_have_a_valid_subscription_up_to",{expiration_date : payement.expirationDate ? new Date(payement.expirationDate).toISOString().split('T')[0] : ''})}</div>
                }
                

            </div>

            

        );
    }else{
        return (
            <div className="sticky top-6 space-y-4 bg-white px-6 py-2 my-4">
                <div className='text-md text-bold'>{t("my_schedule")}</div>

                <div>
                    <Link to={`/schedule/setup/${props.org_id}/${props.org_name}`}>
                        <button className="px-4 py-2 bg-sky-200  rounded-md ">
                            <CalendarIcon className='h-5 w-5 inline mr-2 text-gray-600'/> 
                            {t("schedule_setup")}
                        </button>
                    </Link>
                </div>

            </div>
        );
    }
    
}
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { Dot } from "react-animated-dots";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../components/DashboardLayout";
import Loading from "../../components/Loading";
import { get_objectives, save_objective } from "../../utils/api";

import i18next from "../../i18next";
const { t } = i18next;

export function Objectives() {
    const { org_id, org_name } = useParams();
    const [form, set_form] = useState({
        org_id: org_id,
        description: "",
        run_till: "",
        run_from: "",
        title: "",
        tags: ""
    });
    const [objectives, set_objectives] = useState([]);
    const [saving_objective, set_saving_objective] = useState(false);
    const [loading_objectives, set_loading_objectives] = useState(false);

    const [show_advanced_options, set_show_advanced_options] = useState(false);

    const handleChange = (e) => {
        set_form({
            ...form,
            [e.target.name]: e.target.value
        });

        
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            set_saving_objective(true);
            const res = await save_objective(form);
            set_saving_objective(false);
            console.log(res);
            //Prepend the new objective to the list
            set_objectives([res.objective, ...objectives]);
        }catch(ex){
            console.log(ex);
            set_saving_objective(false);
        }
    }

    const handleShowAdvanced = () => {

    }

    const getObjectives = async () => {
        try{
            set_loading_objectives(true);
            const res = await get_objectives(org_id);
            set_loading_objectives(false);
            console.log(res);
            if(res && res.objectives) set_objectives(res.objectives);
        }catch(ex){
            console.log(ex);
            set_loading_objectives(false);
        }
    }

    useEffect(() => {
        getObjectives();
    }, []);

    return <DashboardLayout>
        {!loading_objectives ? <div className="max-w-7xl bg-white col-span-12 pt-8 pb-24 px-6 lg:px-8">
            <div className="lg:grid lg:grid-cols-5">
                <div className="col-span-3 md:px-4">
                    <h1 className="text-3xl font-bold">{t("set_your_posts_objectives")}</h1>

                    <div className="mt-4">
                        <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                            {t("tell_me_what_you_want_to_achieve_with_your_posts", {org_name})} 
                        </label>
                        <div className="mt-4">
                            <textarea
                                id="description"
                                name="description"
                                rows={3}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder={t("eg_create_posts_about_our_new_product")}
                                value={form.description}
                            />
                        </div>
                    </div>

                    <div className="mt-4 md:flex my-2 bg-slate-100 py-2 px-1">
                        <div>
                            {t("run_this_objective_till")} <input
                                type="date"
                                name="run_till"
                                value={form.run_till}
                                onChange={handleChange}
                                className="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm rounded-md"
                            />
                        </div>
                    </div>

                    <div className="py-2 cursor-pointer">
                        <span onClick={() => set_show_advanced_options(!show_advanced_options)}>{t("advanced_options")} {!show_advanced_options ? <ChevronRightIcon className="h-5 inline"/> : <ChevronDownIcon className="h-5 inline"/>}</span> 
                    </div>
                    {show_advanced_options && <div className="bg-slate-50 p-2 mb-2">
                        <div className="mt-4">
                            <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                {t("title")}
                            </label>
                            <div className="">
                                <input
                                    id="title"
                                    name="title"
                                    type="text"
                                    onChange={handleChange}
                                    className="my-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                    placeholder={t("objective_for_valentine_week")}
                                    value={form.title}
                                />
                            </div>

                        </div>
                        <div className="mt-4 mb-6">
                            <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                {t("tags")}
                            </label>
                            <div className="">
                                <input
                                    id="tags"
                                    name="tags"
                                    type="text"
                                    onChange={handleChange}
                                    className="my-2 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                    placeholder="increase sales, product name, USA"
                                    value={form.tags}
                                />
                            </div>

                        </div>

                        <div>
                            {t("run_this_objective_from")} <input
                                type="date"
                                name="run_from"
                                value={form.run_from}
                                onChange={handleChange}
                                className="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm rounded-md"
                            />
                        </div>
                    </div>}

                    <div>
                        <button onClick={handleSubmit} className="bg-sky-500 text-white px-4 py-2 rounded-md">
                            {!saving_objective ? t("save_your_objective"): <span>{t("saving_")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span>}
                        </button>
                    </div>

                </div>
                <div className="col-span-2 mt-10 md:mt-0">
                    <ol className="relative border-l border-gray-300">
                        {objectives && objectives.length && objectives.map(objective => <li className="mb-10 ml-4">
                            <div className="absolute w-3 h-3 bg-gray-400 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                            <time className="mb-1 text-xs font-normal leading-none text-gray-400 dark:text-gray-500">{t("from_date_to_date",{from_date: new Date(objective.run_from || objective.createdAt).toLocaleDateString(), to_date : new Date(objective.run_till).toLocaleDateString()})}</time>
                            <h3 className="text-md font-semibold text-gray-900 ">{objective.title}</h3>
                            <p className="text-base font-normal text-gray-500 dark:text-gray-400 text-violet-500 ">{objective.description}</p>
                            <div className="text-xs mb-4">{t("tags")} : {objective.tags}</div>
                            {/* <a href="#" className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700">Restore this objective <svg class="w-3 h-3 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></a> */}
                        </li>)
                        }

                    </ol>

                </div>
            </div>

        </div> : <div className="max-w-7xl bg-white col-span-12 pt-8 pb-24 px-6 lg:px-8">
        <div className="text-center text-32 my-4">
            <div className="text-center">
                <Loading style={{display:"inline-block"}}/>
            </div>
            {t("loading_other_objectives")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></div>
            </div>}
    </DashboardLayout>;
}
import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'

import i18next from '../../../../i18next';
const { t } = i18next;

const mailingLists = [
  { id: 1, period : 'day', title: t("daily"), description: t("post_messages_per_day"), users: '' },
  { id: 2, period : 'week', title: t("weekly"), description: t("post_messages_per_week"), users: '' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function RadioWeekDay(props) {
  const [selectedMailingLists, setSelectedMailingLists] = useState(props.state.period === "day" ? mailingLists[0] : mailingLists[1])

  const handleChange = (event) => {
    props.update_fn({
        ...props.state,
        period : event.period.toLowerCase(),
        number_of_posts : event.period.toLowerCase() === "day" ? 2 : 3,
        posts_number : event.period.toLowerCase() === "day" ? [
          { name: t("number_posts",{number:2}), number : 2, inStock: true },
          { name: t("number_posts",{number:3}), number : 3, inStock: true },
          //{ name: '4 posts', number : 4, inStock: true },
          //{ name: '5 posts', number : 5, inStock: true }
      ] : [
            //{ name: '1 posts', number : 1, inStock: true },
            //{ name: '2 posts', number : 2, inStock: true },
            { name: t("number_posts",{number:3}), number : 3, inStock: true },
            //{ name: '4 posts', number : 4, inStock: true },
            //{ name: '5 posts', number : 5, inStock: true },
            { name: t("number_posts",{number:6}), number : 6, inStock: true }
        ]
    });
  }

  return (
    <RadioGroup value={selectedMailingLists} onChange={setSelectedMailingLists}>
      <RadioGroup.Label className="text-base font-medium text-gray-900">{t("how_often_do_you_want_to_publish_posts")}</RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        {mailingLists.map((mailingList) => (
          <RadioGroup.Option
            key={mailingList.id}
            value={mailingList}
            onClick={() => handleChange(mailingList)}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-pubcm2 ring-2 ring-pubcm2' : '',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
           disabled={props.max_posts > 0 && props.max_posts < 25 && mailingList.period !== "week"}
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {mailingList.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 items-center text-sm text-gray-500">
                      <div>{mailingList.description}</div>
                      {props.max_posts > 0 && props.max_posts < 25 && mailingList.period !== "week" && <div className="text-xs text-red-400 lowercase"> <small> ({t("per_your_membership_this_option_is_disabled")})</small></div>}
                    </RadioGroup.Description>
                    <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                      
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-pubcm2')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-pubcm2' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

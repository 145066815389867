export default function Fb(){
    //get parameters
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");

    const getFacebookAccessToken = () => {
        //get access token from code facebook
        
    }

    return (
        <div>
            <h1>FB page</h1>
            <p>Code: {code}</p>
            <button>Get access token</button>
        </div>
    );
}
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HeartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PencilIcon, PlusIcon } from '@heroicons/react/20/solid'
import { update_file } from '../utils/api';

import i18next from '../i18next';
const { t } = i18next;

export default function SlideOverFileEdit(props) {
  const open = props.show;
  const setOpen = props.fn_show;
  const file = props.file;

  console.log(file);

  const [form, setForm] = useState({
    description: file ? file.description : "",
    tags: file ? file.tags : ""
  });
  const [saving_data, set_saving_data] = useState(false);

  const handle_form_change = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  const handleFormSubmit = async (e) => {
    try{
      e.preventDefault();
      console.log(form);
      set_saving_data(true);
      const res_update = await update_file(file._id, form);
      set_saving_data(false);
      props.fn_update_after(res_update.updated_file, props.file_index);
      console.log(res_update);
    }catch(ex){
      set_saving_data(false);
    }
  }

  const handleDeleteFile = async () => {
    if (window.confirm(t('are_you_sure_you_want_delete_this_file'))) {
      props.fn_delete(file._id, props.file_index);
      setOpen(false);
    }
  }

  useEffect(() => {
    setForm({
      description: file ? file.description : "",
      tags: file ? file.tags : ""
    });
  }, [file]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">{t("close_pannel")}</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-white p-8">
                    <div className="space-y-6 pb-16">
                      <form onSubmit={handleFormSubmit}>
                        <div>
                          <div className="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg">
                            <img
                              src={file.url}
                              alt=""
                              className="object-cover"
                            />
                          </div>
                          <div className="mt-4 flex items-start justify-between">
                            <div>
                              <h2 className="text-base font-semibold leading-6 text-gray-900">
                                <span className="sr-only">{t("details_for")} </span>{file.original_name}
                              </h2>
                              <p className="text-sm font-medium text-gray-500">{ }</p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3 className="font-medium text-gray-900">{t("description")}</h3>
                          <textarea onChange={handle_form_change} className='w-full py-2 border border-gray-300 rounded-md my-2' name="description" value={form.description}></textarea>
                        </div>

                        <div>
                          <h3 className="font-medium text-gray-900">{t("tags")}</h3>
                          <input onChange={handle_form_change} value={form.tags} name="tags" type="text" className='w-full py-2 border border-gray-300 rounded-md my-2' />

                        </div>

                        <div className="flex">
                          <button
                            type="submit"
                            disabled={saving_data}
                            className="flex-1 rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            {saving_data ? t("saving") : t("save")}
                          </button>
                          <button
                            type="button"
                            className="ml-3 flex-1 rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 hover:text-white "
                            onClick={handleDeleteFile}
                          >
                            {t("delete")}
                          </button>
                        </div>
                      </form>



                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import { useEffect, useState } from "react";

import DashboardLayout from "../../components/DashboardLayout";
import { save_organization, get_organization_details, updateOrgDetails, delete_organization, delete_channel } from "../../utils/api";

import { Link, useNavigate, useParams } from "react-router-dom";
import { countries } from "../../utils/countries";
import { setDocumentHeaders } from "../../utils/document";
import { Dot } from "react-animated-dots";
import human from "human-time";

import facebook_icon from '../../res/img/comm_channels/facebook.png';
import blog_icon from '../../res/img/comm_channels/blog.png';
import email_icon from '../../res/img/comm_channels/email.png';
import linkedin_icon from '../../res/img/comm_channels/linkedin.png';
import sms_icon from '../../res/img/comm_channels/sms.png';
import twitter_icon from '../../res/img/comm_channels/twitter.png';
import instagram_icon from '../../res/img/comm_channels/instagram.png';

import messenger_image from '../../res/img/messenger.svg';

import i18next from "../../i18next";
import { TypeAnimation } from "react-type-animation";
const { t } = i18next;

const channel_icons = {
    "facebook": facebook_icon,
    "twitter": twitter_icon,
    "linkedin": linkedin_icon,
    "email": email_icon,
    "sms": sms_icon,
    "blog": blog_icon,
    "instagram": instagram_icon
  };

export default function MarketingStudio() {
    setDocumentHeaders(t("edit_organization_pubcm"), t("edit_organization_pubcm"));
    const { org_id } = useParams();
    const [global_state, set_global_state] = useState({
        org: {},
        schedule: {},
        channels : []
    });

    useEffect(() => {

    }, []);

    return (
        global_state && global_state.org && <DashboardLayout>
            <div className="col-span-12 min-h-80 text-left bg-white p-2 shadow rounded-md">
                <div className="text-center my-8">
                    <div className="text-xl">{t("welcome_to_the")}</div>
                    <div className="text-4xl my-5 font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-sky-400 to-green-600">{t("marketing_studio")}</div>
                    <div className="py-5 px-4">
                    <TypeAnimation
                        sequence={[
                            // Same substring at the start will only be typed out once, initially
                            'Let\'s work on the SWOT of your business or product',
                            1000, // wait 1s before replacing "Mice" with "Hamsters"
                            'I help you have a communication plan',
                            1000,
                            'I help you decide the best channels to use online and offline',
                            1000,
                            'I am your marketing expert',
                            1000
                        ]}
                        wrapper="span"
                        speed={20}
                        style={{ fontSize: '4em', display: 'inline-block' }}
                        repeat={Infinity}
                        />
                    </div>
                </div>

                <div>

                </div>

                <div className="text-center mb-20">
                    <button className="bg-sky-500 text-white to-pubcm2 px-4 py-2 rounded">{t("comming_soon")}</button>
                </div>
            </div>
        </DashboardLayout>
    );
}


import i18next from "../i18next";

const get_language = () => {    ;
    return i18next.language;
}

export const get_back_end_url = (end_point) => {
    console.log(`${process.env.REACT_APP_BACK_END_API}`,`${end_point}`);
    return `${process.env.REACT_APP_BACK_END_API}/${end_point}`;
}

const xhr_request = async (end_point, type = "GET", auth = false, payload) => {
    let options = {
        method: type,
        headers: {}
    };

    options.headers['Content-Type'] = 'application/json';
    options.headers['Accept-Language'] = get_language();

    if(payload){
        options.body = JSON.stringify(payload);
    }

    if(auth === true){
        options.headers['Authorization'] = `Bearer ${localStorage.token}`;
    }

    const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/${end_point}`, options);
    const data = await res.json();
    return data; 
}

export const get_user_info = async(user_payload) => {    
    return await xhr_request('auth/google_login', 'POST', false, user_payload);
}

export const signin_with_email = async (user_payload) => {
    return await xhr_request('auth/signin', 'POST', false, user_payload);
}

export const signup_with_email = async (user_payload) => {
    return await xhr_request('auth/signup', 'POST', false, user_payload);
}

export const verify_email = async (user_payload) => {
    return await xhr_request('auth/verify_email', 'POST', false, user_payload);
}

export const resend_otp = async (user_payload) => {
    return await xhr_request('auth/resend_verification_code', 'POST', false, user_payload);
}

export const request_pwd_reset_otp = async (user_payload) => {
    return await xhr_request('auth/forgot_password', 'POST', false, user_payload);
}

export const reset_password = async (user_payload) => {
    return await xhr_request('auth/reset_password', 'PATCH', false, user_payload);
}

export const save_organization = async (organization_payload) => {
    return await xhr_request('new/organization', 'POST', true, organization_payload);
}

export const get_user_orgs = async () => {
    return await xhr_request('orgs', 'GET', true);
}

export const generate_posts = async (post_payload) => {
    return await xhr_request('posts/generate', 'POST', true, post_payload);
}

export const get_facebook_access_token = async (payload) => {
    return await xhr_request('auth/facebook_login', 'POST', true, payload);
}

export const get_facebook_pages = async (payload) => {
    return await xhr_request('facebook/pages', 'POST', true, payload); 
}

export const save_facebook_page = async (payload) => {
    return await xhr_request(`facebook/channel`, 'PUT', true, payload); 
}

export const get_organization_posts = async (org_id, page, limit) => {
    return await xhr_request(`list/posts/${org_id}?page=${page}&limit=${limit}`, 'GET', true ); 
}

export const get_organization_scheduled_posts = async (org_id, page, limit) => {
    return await xhr_request(`list/scheduled/posts/${org_id}?page=${page}&limit=${limit}`, 'GET', true ); 
}

//save schedule object to the server
export const save_schedule = async (payload) => {
    return await xhr_request(`setup/schedule`, 'POST', true , payload); 
}

//update a post 
export const update_post = async (post_id, payload) => {
    return await xhr_request(`post/update/${post_id}`, 'PUT', true , payload); 
}

export const delete_post = async (post_id) => {
    return await xhr_request(`post/delete/${post_id}`, 'DELETE', true ); 
}

export const get_organization_month_posts = async (org_id, page, limit) => {
    return await xhr_request(`list/month/posts/${org_id}?page=${page}&limit=${limit}`, 'GET', true ); 
    // const options = {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // }

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/list/month/posts/${org_id}?page=${page}&limit=${limit}`, options);
    // const data = await res.json();
    // return data;
}

export const get_upcoming_posts_per_day = async (org_id, date, page, limit) => {
    return await xhr_request(`posts/upcoming/at/date/${org_id}/${date}?page=${page}&limit=${limit}`, 'GET', true ); 
    // const options = {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // }

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/posts/upcoming/at/date/${org_id}/${date}?page=${page}&limit=${limit}`, options);
    // const data = await res.json();
    // return data;
}

export const get_user = async(user_id) => {
    return await xhr_request(`user/${user_id}`, 'GET', true ); 
    // const options = {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // }

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/user/${user_id}`, options);
    // const data = await res.json();
    // return data;
}

export const get_all_users = async (page, limit) => {
    return await xhr_request(`list/users?page=${page}&limit=${limit}`, 'GET', true ); 
    // const options = {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/list/users?page=${page}&limit=${limit}`, options);
    // const data = await res.json();
    // return data;
}


export const get_stripe_session_url = async (payload) => {
    return await xhr_request(`get/checkout/session`, 'GET', true ); 
    // const options = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // }

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/get/checkout/session`, options);
    // const data = await res.json();
    // return data;
}

export const publish_post_to_channels = async (post_id) => {
    return await xhr_request(`post/publish/${post_id}`, 'POST', true , {} ); 
    // const options = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify({})
    // }

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/post/publish/${post_id}`, options);
    // const data = await res.json();
    // return data;
}

export const get_organization_details = async (org_id) => {
    return await xhr_request(`org/info/${org_id}`, 'GET', true );
    // const options = {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/info/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const get_organization_details_info = async (org_id) => {
    return await xhr_request(`org/info/${org_id}`, 'GET', true );
    // const options = {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/info/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const update_user = async (user_id, payload) => {
    return await xhr_request(`admin/user/update/${user_id}`, 'PUT', true, payload );
    // const options = {
    //     method: 'PUT',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // }

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/admin/user/update/${user_id}`, options);
    // const data = await res.json();
    // return data;
}

export const check_channel_token_valid = async (channel_id, token) => {
    return await xhr_request(`token/valid/${channel_id}/facebook`, 'GET', true );
    // const options = {
    //     method : 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/token/valid/${channel_id}/facebook`, options);
    // const data = await res.json();
    // return data;
}

export const get_price_list = async(org_id) => {
    return await xhr_request(`ls/price/list/${org_id}`, 'GET', true );
    // const options = {
    //     method : 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/ls/price/list/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const get_ls_checkout_url = async (payload) => {
    return await xhr_request(`ls/checkout/session`, 'POST', true, payload );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/ls/checkout/session`, options);
    // const data = await res.json();
    // return data;
}

export const get_ub_checkout_url = async (payload) => {
    return await xhr_request(`ub/checkout/session`, 'POST', true, payload );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/ub/checkout/session`, options);
    // const data = await res.json();
    // return data;
}

export const pause_channel = async (channel_id) => {
    return await xhr_request(`channel/pause`, 'PUT', true, {channel_id} );
    // const options = {
    //     method : 'PUT',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify({channel_id})
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/channel/pause`, options);
    // const data = await res.json();
    // return data;
}

export const get_twitter_login_url = async (org_id) => {
    return await xhr_request(`twitter/login_url`, 'GET', true );
    // const options = {
    //     method : 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/twitter/login_url`, options);
    // const data = await res.json();
    // return data;
}

export const get_twitter_access_token = async (org_id, oauth_token, oauth_verifier) => {
    return await xhr_request(`twitter/access_token`, 'POST', true, {org_id, oauth_token, oauth_verifier} );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify({org_id, oauth_token, oauth_verifier})
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/twitter/access_token`, options);
    // const data = await res.json();
    // return data;
}

export const getPayementStatus = async (pyt_uuid) => {
    return await xhr_request(`pyt/status/${pyt_uuid}`, 'GET', true );
    // const options = {
    //     method : 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/pyt/status/${pyt_uuid}`, options);
    // const data = await res.json();
    // return data;
}

export const updateOrgDetails = async (org_id, payload) => {
    return await xhr_request(`org/update/${org_id}`, 'PUT', true, payload );
    // const options = {
    //     method : 'PUT',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/update/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const delete_organization = async (org_id) => {
    return await xhr_request(`org/delete/${org_id}`, 'DELETE', true );
    // const options = {
    //     method : 'DELETE',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/delete/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const delete_channel = async (channel_id) => {
    return await xhr_request(`channel/delete/${channel_id}`, 'DELETE', true );
    // const optinos = {
    //     method : 'DELETE',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/channel/delete/${channel_id}`, optinos);
    // const data = await res.json();
    // return data;
}

export const save_objective = async (payload) => {
    return await xhr_request(`objective/save`, 'POST', true, payload );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/objective/save`, options);
    // const data = await res.json();
    // return data;
}

export const get_objectives = async (org_id) => {
    return await xhr_request(`objectives/${org_id}`, 'GET', true );
    // const options = {
    //     method : 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/objectives/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const send_message = async (payload) => {
    return await xhr_request(`message/send`, 'POST', true, payload );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // }

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/message/send`, options);
    // const data = await res.json();
    // return data;
}

export const update_post_image_index = async (post_id,payload) => {
    return await xhr_request(`post/image_index/update/${post_id}`, 'PUT', true, payload );
    // const options = {
    //     method : 'PUT',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // }

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/post/image_index/update/${post_id}`, options);
    // const data = await res.json();
    // return data;
}

export const set_system_configuration = async (userid,payload) => {
    return await xhr_request(`sys/cnf/${userid}`, 'POST', true, payload );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // }

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/sys/cnf/${userid}`, options);
    // const data = await res.json();
    // return data;
}

export const get_system_configuration = async (userid) => {
    return await xhr_request(`sys/cnf/${userid}`, 'GET', true );
    // const options = {
    //     method : 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // }

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/sys/cnf/${userid}`, options);
    // const data = await res.json();
    // return data;
}

export const get_linkedin_token = async (org_id, code, type) => {
    return await xhr_request(`linkedin/access_token/${org_id}`, 'POST', true, {code, type});
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify({code, type})
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/linkedin/access_token/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const authentify_with_linkedin = async ( payload) => {
    return await xhr_request('auth/linkedin', 'POST', true, payload);
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/auth/linkedin`, options);
    // const data = await res.json();
    // return data;
}

export const org_add_admin = async (org_id, payload) => {
    return await xhr_request(`org/admin/add/${org_id}`, 'POST', true, payload);
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/admin/add/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const org_remove_admin = async (org_id, user_admin_id) => {
    return await xhr_request(`org/admin/remove/${org_id}/${user_admin_id}`, 'DELETE', true );
    // const options = {
    //     method : 'DELETE',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/admin/remove/${org_id}/${user_admin_id}`, options);
    // const data = await res.json();
    // return data;

}

export const get_org_admins = async (org_id) => {
    return await xhr_request(`org/get/admins/${org_id}`, 'GET', true );
    // const options = {
    //     method : 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/get/admins/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const save_personalitty = async (org_id, payload) => { 
    return await xhr_request(`org/personalitty/save/${org_id}`, 'POST', true, payload );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/personalitty/save/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const get_personalitty = async (org_id) => {
    return await xhr_request(`org/personalitty/get/${org_id}`, 'GET', true );
}

export const test_personality = async (org_id, payload) => {
    return await xhr_request(`org/personalitty/test/${org_id}`, 'POST', true, payload );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/personalitty/test/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const save_linkedin_org_channel = async (org_id, payload) => {
    return await xhr_request(`org/linkedin/channel/save/${org_id}`, 'POST', true, payload );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/linkedin/channel/save/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const get_file_upload_url = async (payload) => {
    return await xhr_request(`file/upload/url`, 'POST', true, payload );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/file/upload/url`, options);
    // const data = await res.json();
    // return data;
}

export const save_organization_file = async (org_id, payload ) => {
    return await xhr_request(`org/file/save/${org_id}`, 'POST', true, payload );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/file/save/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const get_organization_files = async (org_id, page = 1) => {
    return await xhr_request(`org/list/media/${org_id}/${page}`, 'GET', true );
    // const options = {
    //     method : 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/list/media/${org_id}/${page}`, options);
    // const data = await res.json();
    // return data;
}

export const update_file = async (file_id, payload) => {
    return await xhr_request(`file/update/${file_id}`, 'PUT', true, payload );
    // const options = {
    //     method : 'PUT',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/file/update/${file_id}`, options);
    // const data = await res.json();
    // return data;
}

export const get_storage_usage = async (org_id) => {
    return await xhr_request(`org/storage/usage/${org_id}`, 'GET', true );
    // const options = {
    //     method : 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/storage/usage/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const delete_org_file = async (file_id) => {
    return await xhr_request(`org/file/delete/${file_id}`, 'DELETE', true );
    // const options = {
    //     method : 'DELETE',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/file/delete/${file_id}`, options);
    // const data = await res.json();
    // return data;
}

export const update_post_image = async (post_id, payload) => {
    return await xhr_request(`post/update_file/${post_id}`, 'PUT', true , payload );
    // const options = {
    //     method : 'PUT',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/post/update_file/${post_id}`, options);
    // const data = await res.json();
    // return data;
}

export const get_linked_accounts = async (org_id) => {
    return await xhr_request(`org/linkedin/accounts/${org_id}`, 'GET', true );
    // const options = {
    //     method : 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/linkedin/accounts/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const get_linkedin_organizations_accounts = async (org_id) => {
    return await xhr_request(`org/linkedin/orgs/${org_id}`, 'GET', true );
    // const options = {
    //     method : 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/linkedin/orgs/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const check_user_connection = async (payload) => {
    return await xhr_request(`check/user/connection`, 'POST', true, payload );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body: JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/check/user/connection`, options);
    // const data = await res.json();
    // return data;
}

export const get_ig_pages = async (code) => {
    return await xhr_request(`ig/pages`, 'POST', true, {
        code,
        callback_url : window.location.origin + "/instagram/pages"
    } );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body : JSON.stringify({
    //         code,
    //         callback_url : window.location.origin + "/instagram/pages"
    //     })
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/ig/pages`, options);
    // const data = await res.json();
    // return data;
}

export const save_ig_channel = async (org_id, payload) => {
    return await xhr_request(`org/ig/channel/save/${org_id}`, 'POST', true, payload);
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body : JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/ig/channel/save/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const get_website_info = async ( payload) => {
    return await xhr_request(`org/website/info`, 'POST', true, payload);
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body : JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/org/website/info`, options);
    // const data = await res.json();
    // return data;
}

export const save_new_post = async (org_id, payload) => {
    return await xhr_request(`post/save/${org_id}`, 'POST', true, payload);
}

export const save_new_product = async (org_id, payload ) => {
    return await xhr_request(`product/new/${org_id}`, 'POST', true, payload);
    // const options = {
    //     method : 'POST',
    //     headers : {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body : JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/product/new/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const list_products = async (org_id, page = 1, search = "" ) => {
    return await xhr_request(`product/list/${org_id}?page=${page}&search=${search}`, 'GET', true );
    // const options = {
    //     method : 'GET',
    //     headers : {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };
    
    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/product/list/${org_id}?page=${page}&search=${search}`, options);
    // const data = await res.json();
    // return data;
}

export const delete_product = async ( org_id, product_id) => {
    return await xhr_request(`product/delete/${org_id}/${product_id}`, 'DELETE', true );
    // const options = {
    //     method : 'DELETE',
    //     headers : {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // }

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/product/delete/${org_id}/${product_id}`, options);
    // const data = await res.json();
    // return data;
}

export const update_product = async (org_id, product_id, payload ) => {
    return await xhr_request(`product/update/${org_id}/${product_id}`, 'PUT', true, payload );
    // const options = {
    //     method : 'PUT',
    //     headers : {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body : JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/product/update/${org_id}/${product_id}`, options);
    // const data = await res.json();
    // return data;
}

export const get_product_info = async ( payload) => {
    return await xhr_request(`product/info/url/`, 'POST', true, payload );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body : JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/product/info/url/`, options);
    // const data = await res.json();
    // return data;
}


export const get_product_usage = async ( org_id) => {
    return await xhr_request(`product/usage/${org_id}`, 'POST', true );
    // const options = {
    //     method : 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     }
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/product/usage/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const get_generate_text_post = async ( org_id, payload ) => {
    return await xhr_request(`post/generate/text/${org_id}`, 'POST', true, payload );
    // const options = {
    //     method : 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body : JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/post/generate/text/${org_id}`, options);
    // const data = await res.json();
    // return data;
}

export const generate_promo_code = async ( payload) => {
    return await xhr_request(`user/generate/promocode`, 'POST', true, payload );
    // const options = {
    //     method : 'POST', 
    //     headers : {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body : JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/user/generate/promocode`, options);
    // const data = await res.json();
    // return data;
}

export const update_user_language = async ( payload) => {
    return await xhr_request(`user/language`, 'POST', true, payload );
    // const options = {
    //     method : 'POST', 
    //     headers : {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${localStorage.token}`
    //     },
    //     body : JSON.stringify(payload)
    // };

    // const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/user/language`, options);
    // const data = await res.json();
    // return data;
}

export const save_org_branding_info = async ( org_id, payload) => {
    return await xhr_request(`org/branding/save/${org_id}`, 'POST', true, payload );
}


export const update_branding_actions = async ( org_id, payload) => {
    return await xhr_request(`org/branding/actions/${org_id}`, 'PUT', true, payload );
}


export const get_a_user_orgs = async (user_id) => {
    return await xhr_request('user/orgs/' + user_id, 'GET', true);
}

export const activate_plan = async ( payload) => {
    return await xhr_request(`activate/plan`, 'POST', true, payload );
}

export const activate_tester_plan = async ( payload) => {
    return await xhr_request(`activate/tester/membership`, 'POST', true, payload );
}
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { get_ls_checkout_url } from '../../utils/api';
import { get_ub_checkout_url } from '../../utils/api';
import { Socket, io } from "socket.io-client";
import { connect_socket } from '../../utils/socket';
import Loading from '../../components/Loading';
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/20/solid';
import i18next from '../../i18next';
const { t } = i18next;

export default function PopupChoosePyt(props) {
  //   const [open, setOpen] = useState(props.open);
  const open = props.open;
  const setOpen = props.setOpen;
  const [loading_pyt_url, set_loading_pyt_url] = useState(0);
  const [phone_number, set_phone_number] = useState("");
  const [show_waiting_for_confirmation, set_show_waiting_for_confirmation] = useState(false);
  const [show_mobile_money_form, set_show_mobile_money_form] = useState(false);
  const [show_visa_momo_choice, set_show_visa_momo_choice] = useState(true);
  const [show_failed_momo_pyt, set_show_failed_momo_pyt] = useState(false);
  const [show_success_momo_pyt, set_show_success_momo_pyt] = useState(false);
  const [estiblishing_connection, set_estiblishing_connection] = useState(false);

  console.log(props.visa_payload);

  const freq = props.visa_payload && props.visa_payload.frequency && props.visa_payload.frequency.value ? props.visa_payload.frequency.value : null;
  // console.log(freq);
  // console.log(props.visa_payload.tier.totalPrice);
  const total_price = props.visa_payload && props.visa_payload.tier && props.visa_payload.tier.totalPrice ? props.visa_payload.tier.totalPrice[freq] : null;

  const handle_phone_number_change = (e) => {
    set_phone_number(e.target.value);
  }

  const handle_chooses = async (pyt_method) => {
    console.log(pyt_method);
    if (pyt_method === "visa") {
      try {
        set_loading_pyt_url(1);
        const res = await get_ls_checkout_url(props.visa_payload);
        setTimeout(() => {
          set_loading_pyt_url(-1);
        }, 2000)
        set_loading_pyt_url(0);
        console.log(res);
        window.location.href = res.url;
      } catch (err) {
        console.log(err);
        set_loading_pyt_url(0);
        alert("Error payement failed");
      }
    } else if (pyt_method === "momo") {
      // alert("About to pay with momo");
      set_show_visa_momo_choice(false);
      set_show_mobile_money_form(true);
    }
    // setOpen(false);
  }

  const handle_submit_momo_pyt = async (e) => {
    e.preventDefault();
    // const res = await get_ub_checkout_url({...props.visa_payload, phone : phone_number});
    // console.log(res);
    console.log(phone_number.length);
    if (phone_number.length <= 9) {
      console.log("Creating socket ...");
      set_estiblishing_connection(true);
      connect_socket((socket) => {

        socket.emit("message", {
          type: "checkout",
          order: { ...props.visa_payload, phone: phone_number },
          user: JSON.parse(localStorage.getItem("user"))
        });

        socket.on("message", (data) => {
          set_estiblishing_connection(false);
          console.log(data);
          if (data.type === "checkout") {
            if (data.message === "success") {
              set_show_waiting_for_confirmation(true);
            } else {

            }
          } else if (data.type === "checkout-webhook") {
            if (data.message === "success") {
              set_show_success_momo_pyt(true);
              set_show_failed_momo_pyt(false);
              set_show_waiting_for_confirmation(false);

            } else {
              set_show_failed_momo_pyt(true);
              set_show_success_momo_pyt(false);
              set_show_waiting_for_confirmation(false);
            }
          }
        });

        //on disconnect
        socket.on("disconnect", () => {
          console.log("Socket disconnected");
          //close socket 
          socket.close();
        });

        socket.on("error", (err) => {
          set_estiblishing_connection(false);
        });

      });

    } else {
      alert("Invalid phone number");
    }
  }

  let socket = null;

  const handleClickSocket = () => {
    if (socket) {
      socket.emit("message", "Hello World");
    } else {
      alert("Socket not connected");
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" data-modal-backdrop="static" className="relative z-10" onClose={() => {

      }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="text-right">
                    {show_mobile_money_form && <button onClick={() => {
                      if (window.confirm(t("are_you_sure_you_want_to_restart"))) {
                        set_show_visa_momo_choice(true);
                        set_show_mobile_money_form(false);
                        set_show_waiting_for_confirmation(false);
                        set_show_failed_momo_pyt(false);
                      }
                    }} type="button" className="py-1 mx-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-slate-700 hover:text-white bg-slate-300 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"> <ArrowPathIcon className='w-4 h-4 inline' /> </button>}
                    <button onClick={() => {
                      if (window.confirm(t("are_you_sure_you_want_to_cancel"))) {
                        set_show_visa_momo_choice(true);
                        set_show_mobile_money_form(false);
                        set_show_waiting_for_confirmation(false);
                        set_show_failed_momo_pyt(false);
                        setOpen(false);
                      }
                    }} type="button" className="py-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-slate-700 hover:text-white bg-slate-300 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"> <XMarkIcon className='w-4 h-4 inline' /> </button>
                  </div>

                  {show_visa_momo_choice && <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {t("pay_with")}
                    </Dialog.Title>
                  </div>}


                </div>
                {show_visa_momo_choice && <div className="mt-5 sm:mt-6">
                  {!loading_pyt_url ? <ul className="divide-y divide-gray-200">
                    <li onClick={() => handle_chooses("visa")} className="py-4 flex cursor-pointer hover:bg-slate-100">
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">{t("visa_mastercard")}</p>
                        <p className="text-sm text-gray-500">{t("pay_with_credit_card")}</p>
                      </div>
                    </li>
                    <li onClick={() => handle_chooses("momo")} className="py-4 flex cursor-pointer hover:bg-slate-100">
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">{t("mobile_money")}</p>
                        <p className="text-sm text-gray-500">{t("pay_with_airtel_money_orange_money_mpesa")}</p>
                      </div>
                    </li>
                  </ul> : <div className="flex justify-center items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                    </svg> &nbsp;
                    <p className="text-sm font-medium text-gray-900">{t("loading_pyt_page")}<br /><small>{t("please_wait")} ...</small></p>

                  </div>
                  }
                </div>}
                {show_mobile_money_form && !show_waiting_for_confirmation && !show_success_momo_pyt && !show_failed_momo_pyt && <div>
                  <form onSubmit={handle_submit_momo_pyt}>
                    <div className='my-2'>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("votre_numero_de_telephone")}
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          +243
                        </div>
                        <input
                          type="tel"
                          name="phone_number"
                          id="phone_number"
                          onChange={handle_phone_number_change}
                          className="block w-full rounded-md border-0 py-1.5 pl-14 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder={t("ex_998_121_900")}
                        />
                      </div>
                      <div>
                        <p className="mt-2 text-xs text-gray-500">{t("we_ll_send_you_a_notification_on_your_phone")}</p>
                      </div>

                      <div>
                        <button type="submit" className="mt-2 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500">
                          {estiblishing_connection ? <span>{t("please_wait")}</span> : <span>{t("pay_total_price_usd",{total_price})}<small>&nbsp;&nbsp; {t("frais_de_transaction")}</small></span>}
                        </button>
                      </div>
                      {/* <div>
                        <button type="button" onClick={handleClickSocket} className="mt-2 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500">
                          Send message
                        </button>
                      </div> */}

                    </div>
                  </form>
                </div>}

                {(show_waiting_for_confirmation || show_success_momo_pyt || show_failed_momo_pyt) && <div className='text-center'>

                  {show_waiting_for_confirmation && <div className='my-2'>
                    <div className='text-sm'>{t("waiting_for_payement_confirmation")}</div>
                    <div className='text-xl my-2'>
                      <small>{t("confirm_pyt_with_your_pin_on_your_phone")}</small>
                    </div>
                    <div>
                      <Loading style={{ display: "inline-block" }} />
                    </div>
                  </div>}

                  {show_success_momo_pyt && <div>
                    <div className='text-3xl'>✅</div>
                    <div>{t("payement_was_successful")}</div>
                    <div className='my-2'>
                      <div className='my-2'><small >{t("thank_you_for_your_purchase")}</small></div>
                      <div>
                        <button onClick={() => {
                          window.location.href = "/";
                        }} type="button" className="py-2 px-4 w-full border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"> {t("go_to_dashboard")} </button>
                      </div>
                    </div>
                  </div>}

                  {show_failed_momo_pyt && <div>
                    <div className='text-3xl'>⛔︎</div>
                    <div>{t("pyt_failed")}</div>
                    <div className='my-2'>
                      <div className='my-2'><small>{t("you_can_try_again")}</small></div>
                      <div>
                        <button onClick={() => {
                          set_show_visa_momo_choice(true);
                          set_show_mobile_money_form(false);
                          set_show_waiting_for_confirmation(false);
                          set_show_failed_momo_pyt(false);
                          socket.emit("cancel_payement");
                        }} type="button" className="py-2 px-4 w-full border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500">{t("try_again")}</button>
                      </div>
                    </div>
                  </div>}
                </div>}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import { Switch } from "@headlessui/react";
import { useState } from "react";
import i18next from "../../../../i18next";
const { t } = i18next;

export default function IncludeContact(props) {
    const {type, label} = props;

    const handleChange = () => {
        const checked = props.state[type];
        props.update_fn({ ...props.state, [type]: !checked });
    };

    console.log(label);

    return (
        <div className="flex justify-between">
            <div className="text-lg">{t("include_org_name_label_in_posts",{org_name: props.org.name, label : label.toString().toLowerCase()})}</div>
            <div className="flex items-center">
                <div className="mr-2">{t("no")}</div>
                <Switch
                    checked={props.state[type]}
                    onChange={handleChange}
                    className={`${props.state[type] ? 'bg-pubcm2' : 'bg-gray-200'}
                                    relative inline-flex items-center h-6 rounded-full w-11`}
                >
                    <span className="sr-only">{t("include_label_in_posts",{label : label.toString().toLowerCase() })}</span>
                    <span

                        className={`${props.state[type] ? 'translate-x-6' : 'translate-x-1'}
                                        inline-block w-4 h-4 transform bg-white rounded-full`}
                    />
                </Switch>
                <div className="ml-2">{t("yes")}</div>
            </div>
        </div>
    )
}
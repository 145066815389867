import { useEffect, useState } from "react";

import DashboardLayout from "../../components/DashboardLayout";
import { save_organization, get_organization_details, updateOrgDetails, delete_organization, delete_channel } from "../../utils/api";

import { Link, useNavigate, useParams } from "react-router-dom";
import { countries } from "../../utils/countries";
import { setDocumentHeaders } from "../../utils/document";
import { Dot } from "react-animated-dots";
import human from "human-time";

import facebook_icon from '../../res/img/comm_channels/facebook.png';
import blog_icon from '../../res/img/comm_channels/blog.png';
import email_icon from '../../res/img/comm_channels/email.png';
import linkedin_icon from '../../res/img/comm_channels/linkedin.png';
import sms_icon from '../../res/img/comm_channels/sms.png';
import twitter_icon from '../../res/img/comm_channels/x-icon.png';
import instagram_icon from '../../res/img/comm_channels/instagram.png';

import messenger_image from '../../res/img/messenger.svg';

import i18next from "../../i18next";
const { t } = i18next;

const channel_icons = {
    "facebook": facebook_icon,
    "twitter": twitter_icon,
    "linkedin": linkedin_icon,
    "email": email_icon,
    "sms": sms_icon,
    "blog": blog_icon,
    "instagram": instagram_icon
  };

export function EditOrganization() {
    setDocumentHeaders(t("edit_organization_pubcm"), t("edit_organization_pubcm"));
    const { org_id } = useParams();
    const [global_state, set_global_state] = useState({
        org: {},
        schedule: {},
        channels : []
    });

    const navigate = useNavigate();

    const [updating_org, set_updating_org] = useState(false);
    const [deleting_org, set_deleting_org] = useState(false);
    const [deleting_channel, set_deleting_channel] = useState(-1);

    const getOrganizationDetails = async () => {
        try {
            const org = await get_organization_details(org_id);
            console.log(org);
            set_global_state({
                ...global_state,
                org: org.org,
                channels : org.channels,
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    const handleUpdateOrganization = async (e) => {
        e.preventDefault();
        try {
            set_updating_org(true);
            await updateOrgDetails(global_state.org._id, { org: global_state.org });
            set_updating_org(false);
        } catch {
            set_updating_org(false);
        }

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const { org } = global_state;
        set_global_state({
            ...global_state,
            org: {
                ...org,
                [name]: value
            }
        });
    }

    const handleDeleteOrganization = async () => {
        if (!window.confirm(t("are_you_sure_you_want_to_delete_org"))) return;
        try {
            set_deleting_org(true);
            const delete_ = await delete_organization(org_id);
            localStorage.removeItem("selected_org_id");
            set_deleting_org(false);
            window.location.href = "/";
        } catch (ex) {
            console.log(ex);
            set_deleting_org(false);
            alert(t("error_deleting_organization"));
        }
    }

    const handleUpdateChannel = async (channel) => {
        
    }

    const handleDeleteChannel = async (channel, index) => {
        if(!window.confirm(t("are_you_sure_you_want_to_delete_this_channel"))) return;
        try {
            set_deleting_channel(index);
            const delete_ = await delete_channel(channel._id);
            set_deleting_channel(-1);
            set_global_state({
                ...global_state,
                channels : global_state.channels.filter((c) => c._id !== channel._id)
            });
        }catch(ex){
            console.log(ex);
            alert(t("error_deleting_channel"));
            set_deleting_channel(-1);
        }
    }

    useEffect(() => {
        getOrganizationDetails();
    }, []);

    return (
        global_state && global_state.org && <DashboardLayout>
            <div className="col-span-7 text-left bg-white p-2 shadow rounded-md">
                <h1 className="text-xl  px-4">{t("edit_organization_details")}</h1>
                <div className=" px-4">
                    <form onSubmit={handleUpdateOrganization} className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                            <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        {t("organization_name")}
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            onChange={handleChange}
                                            name="name"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            value={global_state.org.name}
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        {t("description")}
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <textarea
                                            type="text"
                                            name="description"
                                            onChange={handleChange}
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            value={global_state.org.description}
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        {t("tags")} <div><small>({t("comma_separated")})</small></div>
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            onChange={handleChange}
                                            name="tags"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            value={global_state.org.tags}
                                        />
                                        <div className="text-left text-xs text-slate-600 mt-1 sm:text-center">E.g Peace, Tech, My Company, USA</div>
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        {t("website")}
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="website"
                                            onChange={handleChange}
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            value={global_state.org.website}
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        {t("email")}
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="email"
                                            onChange={handleChange}
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            value={global_state.org.email}
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        {t("phone_number")}
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            onChange={handleChange}
                                            name="phone"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            value={global_state.org.phone}
                                        />
                                    </div>
                                </div>



                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="region" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        {t("country")}
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select
                                            id="region"
                                            name="country"
                                            onChange={handleChange}
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            value={global_state.org.country}
                                        >
                                            {countries.map(country => <option key={country} value={country}>{country}</option>)}
                                        </select>
                                    </div>
                                </div>


                                <div>
                                    <label htmlFor="region" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">

                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 text-right">
                                        <button disabled={updating_org} type="submit" className=" px-4 py-2 bg-blue-600 text-white rounded-md">
                                            {updating_org ? <span>{t("updating")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span> : t("update")}
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="text-left bg-slate-100 p-2 m-2 my-4 rounded-md">
                    <h1 className="text-xl text-red-500">{t("danger_zone")}</h1>
                    <div className="text-sm text-slate-600">
                        <div className="font-bold">{t("warning")}</div>
                        <div>{t("delete_an_organization_is_permanent_and_cannot_be_undone")}</div>
                    </div>
                    <div className="my-2">
                        <button disabled={deleting_org} type="button" onClick={handleDeleteOrganization} className="px-4 py-2 bg-red-400 hover:bg-red-600 text-white rounded-md">
                            {deleting_org ? <span>{t("deleting_organization")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span> : t("delete_organization")}
                        </button>
                    </div>
                </div>
            </div>

            <div id="social-channels" className="col-span-5 text-center bg-white p-2 rounded-md shadow">
                <div>
                    <h1 className="text-xl">{t("social_channels")}</h1>
                    {/** create cards showing social medias with an icon on left, the name in the middle, below the name, a description and below it two buttons delete and update */}
                    <div>
                        {global_state.channels && global_state.channels.map((channel, index) => <div className="bg-slate-100 p-2 m-2 my-4 rounded-md">
                            <div className="flex justify-between">
                                <div className="flex text-left">
                                    <div className="w-10 h-10 rounded-full flex ">
                                        <img src={channel_icons[channel.type]}/>
                                    </div>
                                    <div className="ml-2">
                                        <div className="text-sm font-bold">{channel.type && channel.type.toString() === "twitter" ? "X (Twitter)" : channel.type }</div>
                                        <div className="text-xs text-slate-600">{channel && channel.info ? ( channel.info.name || channel.info.screen_name ) : ""}</div>
                                        <div className="text-xs text-slate-600">Since {human(new Date(channel.createdAt))}</div>
                                    </div>
                                </div>
                                <div className="">
                                    {/* <button onClick={() => handleUpdateChannel(channel, index)} className="px-4 py-2 bg-blue-400 hover:bg-blue-600 text-white rounded-md">
                                        Update
                                    </button> */}
                                    <button onClick={() => handleDeleteChannel(channel, index)} className="px-4 py-2 bg-red-400 hover:bg-red-600 text-white rounded-md ml-1">
                                        {deleting_channel === index ? <span>{t("deleting")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span> : t("delete")}
                                    </button>
                                </div>
                            </div>
                        </div>)}

                        {global_state.channels && global_state.channels.length === 0 && <div className="text-center text-slate-600 mt-8">
                            <img src={messenger_image} className='h-40 inline'/> 
                            <div>{t("you_have_not_added_any_social_media_channels_yet")}</div>
                            <div className="my-3"><Link to="/" className="bg-sky-400 rounded-md text-white px-4 py-2 text-xs">{t("go_to_the_dashboard_to_add_channels")}</Link></div>
                            </div>}
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}


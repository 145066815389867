import i18next from 'i18next';
import en from './locales/en.json';
import fr from './locales/fr.json';
import sw from './locales/sw.json';
import pt from './locales/pt.json';
// import ar from './locales/ar.json';
import es from './locales/es.json';
import cn from './locales/cn.json';
import in_ from './locales/in.json';

import LanguageDetector from 'i18next-browser-languagedetector';

const language = window.localStorage.getItem('language');

i18next
  .use(LanguageDetector)
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    lng :  language || 'en',
    failingLng: 'en',
    // Using simple hardcoded resources for simple example
    resources: {
      en: { translation: en, },
      fr: { translation: fr },
      sw: { translation : sw},
      pt: { translation : pt},
        // ar: { translation : ar},
      es: { translation : es},
      cn: { translation : cn},
      in: { translation : in_}

    },
  })

export default i18next;
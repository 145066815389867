import { useState, useEffect } from "react";
import facebook_icon from '../../res/img/comm_channels/facebook.png';
import blog_icon from '../../res/img/comm_channels/blog.png';
import email_icon from '../../res/img/comm_channels/email.png';
import linkedin_icon from '../../res/img/comm_channels/linkedin.png';
import sms_icon from '../../res/img/comm_channels/sms.png';
import twitter_icon from '../../res/img/comm_channels/x-icon.png';
import instagram_icon from '../../res/img/comm_channels/instagram.png';
import all_icon from '../../res/img/comm_channels/all.png';

import DashboardLayout from "../..//components/DashboardLayout";
import { check_channel_token_valid, generate_posts, get_facebook_pages, get_organization_posts, get_twitter_login_url, get_user_orgs, update_user_language } from "../../utils/api";
import DontHavePlan from "./DontHavePlan";
import Loading from "../../components/Loading";
import Post from "./Post";
import UpcomingPosts from "./components/UpcomingPosts";
import { addFacebookChannel, addInstagramChannel, getLinkedinLoginURL, handleAjoutFacebookChannel } from "../../utils/channels";
import FacebookPageModal from "./FacebookPageModal";
import PostsZone from "./components/PostsZone";
import { ClockIcon, GlobeAltIcon, BuildingStorefrontIcon, BuildingOffice2Icon, PencilIcon, PencilSquareIcon, SparklesIcon, ChevronRightIcon, UsersIcon, SwatchIcon, RocketLaunchIcon, PuzzlePieceIcon, PhotoIcon, AdjustmentsVerticalIcon, MegaphoneIcon, BookOpenIcon, RectangleStackIcon } from "@heroicons/react/20/solid";
import Plans from "./components/Plans";
import ToggleChannel from "./components/ToggleChannel";
import { setDocumentHeaders } from "../../utils/document";
import { CalendarIcon, Cog6ToothIcon, EnvelopeIcon, GlobeEuropeAfricaIcon, PhoneIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SelectOrganization from "./components/SelectOrganization";
import OrganizationAdmins from "./components/OrganizationAdmins";
import FilesWidget from "./components/FilesWidget";
import LeftNavigation from "./components/LeftNavigation";
import SlideOverAdmin from "../../components/SlideOverAdmin";
import SlideOverFileUpload from "../../components/SlideOverFileUpload";
import SlideOverMenus from "../../components/SlideOverMenus";
import SlideOverPersonality from "../../components/SlideOverPersonality";
import SelectLinkedinType from "./components/SelectLindedinType";

import i18next from "../../i18next";
import feature_not_yet_implemented from "../../utils/organization";
import InputTextNewPost from "./components/InputTextNewPost";
import AffiliateProgramWidget from "./components/AffiliateProgramWidget";
import DesignPostWidget from "./components/DesignPostWidget";
import ActivateTesterMembership from "./components/ActivateTesterMembership";

const { t } = i18next;

const channel_icons = {
  "facebook": facebook_icon,
  "twitter": twitter_icon,
  "instagram": instagram_icon,
  "linkedin": linkedin_icon,
  "email": email_icon,
  "sms": sms_icon,
  "blog": blog_icon
};

const my_organisations = [
  {
    name: "Troto",
    description: "Troto is a market place allowing people to sell music in the DRC and benefit from a market made of people using mobile money",
    tags: [
      "music", "rdc", "app", "mobile money"
    ],
    info: [{
      "type": "Website",
      "value": "https://troto.co"
    },
    {
      "type": "Phone",
      "value": "+243 998 955 302"
    },
    {
      "type": "Email",
      "value": "info@troto.co"
    }
    ]
  },
  {
    name: "Urgence RDC"
  }
]

const communication_channels = [
  {
    name: "Facebook",
    display_name : "Facebook",
    img: facebook_icon,
    existing: false,
    type: "short"
  },
  {
    name: "Instagram",
    display_name : "Instagram",
    img: instagram_icon,
    existing: false,
    type: "short"
  },
  {
    name: "Twitter",
    display_name : "X (Twitter)",
    img: twitter_icon,
    existing: false,
    type: "short"
  },
  {
    name: "LinkedIn",
    display_name : "LinkedIn",
    img: linkedin_icon,
    existing: false,
    type: "short"
  },
  // {
  //   name: "Email",
  //   img: email_icon,
  //   existing: false,
  //   type: "long"
  // },
  // {
  //   name: "SMS",
  //   img: sms_icon,
  //   existing: false,
  //   type: "short"
  // },
  // {
  //   name: "Blog",
  //   img: blog_icon,
  //   existing: false,
  //   type: "long"
  // }
]

// const latest_posts = [
//   {
//     "type": "facebook",
//     "post_at_text": "Published on Facebook",
//     "content": "Let's go party todayorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet orci est. Pellentesque turpis nisi, consequat id finibus eu, elementum sed velit. Donec sit amet laoreet purus. Quisque id gravida nisl. Ut tempus, est vitae feugiat sodales, quam nulla convallis elit, in ornare diam ipsum eu diam.",
//     "timest": "02:31 12/03/2023",
//     "images": []
//   },
//   {
//     "type": "facebook",
//     "post_at_text": "Published on Facebook",
//     "content": "Let's go party todayorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet orci est. Pellentesque turpis nisi, consequat id finibus eu, elementum sed velit. Donec sit amet laoreet purus. Quisque id gravida nisl. Ut tempus, est vitae feugiat sodales, quam nulla convallis elit, in ornare diam ipsum eu diam.",
//     "timest": "02:31 12/03/2023",
//     "images": []
//   },
//   {
//     "type": "facebook",
//     "post_at_text": "Published on Facebook",
//     "content": "Let's go party todayorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet orci est. Pellentesque turpis nisi, consequat id finibus eu, elementum sed velit. Donec sit amet laoreet purus. Quisque id gravida nisl. Ut tempus, est vitae feugiat sodales, quam nulla convallis elit, in ornare diam ipsum eu diam.",
//     "timest": "02:31 12/03/2023",
//     "images": []
//   }
// ]

let has_fetched_user_orgs = false;

export default function Dashboard() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tester_param = query.get("test_ig");
  const navigate = useNavigate();
  //const [selectedOrganisation, selectAnOrganistion] = useState(0);
  const [show_sketch_section, set_show_sketch_section] = useState(false);
  //const [user_orgs, set_user_orgs] = useState([]);
  //const [generated_posts, set_generated_posts] = useState([]);
  const [loading_generated_posts, set_loading_generated_posts] = useState(false);
  const [show_facebook_page_modal, set_show_facebook_page_modal] = useState(false);
  const [current_channel_id, set_current_channel_id] = useState(0);
  //const [org_communication_channels, set_communication_channels] = useState([]);
  const [latest_posts, set_latest_posts] = useState([]);
  const [show_linkedin_connect_modal, set_show_linkedin_connect_modal] = useState(false);

  const [global_state, set_global_state] = useState({
    loading_orgs: true,
    selectedOrganisation: 0,
    user_orgs: [],
    org_communication_channels: [],
    generated_posts: []
  });

  const [fb_invalid_token, set_fb_invalid_token] = useState(false);

  const checkChannelTokenValid = async (channel_id) => {
    const valid = await check_channel_token_valid(channel_id);
    console.log("Testing token validity", valid);
    if (valid && valid.validity) {
      set_fb_invalid_token(false);
    } else {
      set_fb_invalid_token(true);
    }
  }

  console.log("THE SELECTED LANGUAGE IS ", i18next.language);

  let timeout_retry_loading = null;

  const changeOrganization = (index, close_) => {
    if (close_) {
      //close();
    }
    // document.getElementById("btnClosePopoverMenus").click();
    localStorage.selected_org_id = global_state.user_orgs[index].org._id;
    set_global_state({
      ...global_state,
      selectedOrganisation: index,
      org_communication_channels: global_state.user_orgs[index].channels
    });
    setDocumentHeaders(t("dashboard") + " - " + global_state.user_orgs[index].org.name, t("dashboard") + " - " + global_state.user_orgs[index].org.name);
    checkChannelTokenValid(global_state.user_orgs[index].org._id);
  }

  const click_sketch_ideas = () => {
    set_show_sketch_section(!show_sketch_section);
  }
  // http://localhost:3000/auth/fb?code=AQBSqtC4bN3wB3C9IsqZxj_wIUV1FoF-FV1-vqc-apMkJ5ZRmlbNJzbi9D_UKDjpi_Z_x04jadAe23XEQtlbg4xZzOwzwyiQ3AqPBu4QqT7TUuCW38XqSOIj1-cPiDrC-07--6e28ESIUn5FAOGwCiMzafA4pEn-E_O2NENWZL8xSTDl_xC9p1YK6WSUi1vDzS9mYPJjt_BdyKVteKlR39R2NZC7dsucRL4j3HZv7S_wSU-5mvPsxzBIQAM-wDGcCGwfO_p8EZJvfgKZ2YES3LFYD7LAG78qCntNZ8SJCxA2Gag0XDQ5Duu33JEtycwTmfy98IyHJ94GIOBN2Q1YxACR63d5-kxMY6zws5OxsbAdu4g01CksqUwBa1OdmCh0QbM#_=_
  if (!localStorage || !localStorage.getItem("token")) {
    window.location.href = "/auth/login";
  }

  const my_orgs = async () => {
    try {
      if (!has_fetched_user_orgs) {
        has_fetched_user_orgs = true;
        set_global_state({
          ...global_state,
          loading_orgs: true
        });

        let my_orgs = await get_user_orgs();

        const user_obj = my_orgs.user;
        my_orgs = my_orgs.orgs;
        if (user_obj && user_obj._id) {
          if (user_obj.status !== "active") {
            if(user_obj.status === "verify_email"){
              window.location.href = "/auth/verify/email";
              return;
            }
            window.location.href = "/sorry";
          }
        }

        const get_selected_org_index = (saved_org_id) => {
          let index = 0;
          for (let i = 0; i < my_orgs.length; i++) {
            if (my_orgs[i].org._id === saved_org_id) {
              index = i;
              break;
            }
          }
          return index;
        };
        let selected_org = 0;
        if (localStorage.selected_org_id) {
          selected_org = get_selected_org_index(localStorage.selected_org_id);
        } else {
          if (my_orgs && my_orgs.length) {
            localStorage.selected_org_id = my_orgs[0].org._id;

          }
        }

        if (my_orgs && my_orgs[selected_org] && my_orgs[selected_org].org) setDocumentHeaders(t("dashboard") + " - " + my_orgs[selected_org].org.name, t("dashboard") + " - " + my_orgs[selected_org].org.name);

        console.log(my_orgs);
        set_global_state({
          ...global_state,
          loading_orgs: false,
          user_orgs: my_orgs,
          selectedOrganisation: selected_org,
          org_communication_channels: my_orgs.length ? my_orgs[selected_org].channels : []
        });

        //const posts = await get_organization_posts(my_orgs[selected_org].org._id,1,10);
        //console.log(posts);
      }
    } catch (ex) {
      console.log(ex);
      if (timeout_retry_loading) clearTimeout(timeout_retry_loading);
      timeout_retry_loading = setTimeout(function () {
        console.log("Retrying the download");
        has_fetched_user_orgs = false;
        my_orgs();
      }, 2000);
    }

  }



  const get_gen_posts = async () => {
    try {
      set_loading_generated_posts(true);
      const generation_payload = {
        "org_id": global_state.user_orgs[global_state.selectedOrganisation].org._id
      };

      const posts = await generate_posts(generation_payload);
      console.log(posts);
      if (posts && posts.posts) {
        set_global_state({
          ...global_state,
          generated_posts: posts.posts
        })
      }
      set_loading_generated_posts(false);
    } catch (ex) {
      set_loading_generated_posts(false);
      alert(t("Failed to generate posts"));
    }

  }

  const [list_of_pages, set_list_of_pages] = useState([]);

  const selectChannel = async () => {
    //alert("Filter by channel");
  }

  const addChannel = async (name) => {
    if (name.toLowerCase() === "facebook") {
      addFacebookChannel({ org_id: global_state.user_orgs[global_state.selectedOrganisation].org._id, org_name: global_state.user_orgs[global_state.selectedOrganisation].org.name }, async function () { });
    } else if (name.toLowerCase() === "twitter") {
      // alert("Sorry, we have suspended access to Twitter. We are working on getting back.");
      // return;
      const resp = await get_twitter_login_url();
      // console.log(resp);
      // return;
      window.location.href = resp.url;
    }

    else if (name.toLowerCase() === "linkedin") {
      // set_show_linkedin_connect_modal(true);
      navigate(`/linkedin/pick/org/${global_state.user_orgs[global_state.selectedOrganisation].org._id}/${global_state.user_orgs[global_state.selectedOrganisation].org.name}`);
    }else if(name.toLowerCase() === "instagram"){
      let this_email = JSON.parse(localStorage.user).email;
      //If this is running on localhost, run the function if not alert that it is not ready yet
      window.location.hostname === "localhost" || (this_email === "elimek2@gmail.com" || this_email === "pubcmtester@gmail.com" || this_email === "pubcmtester2@gmail.com") ? addInstagramChannel({ org_id: global_state.user_orgs[global_state.selectedOrganisation].org._id, org_name: global_state.user_orgs[global_state.selectedOrganisation].org.name }) : alert("Sorry, this channel has not been added yet. We will inform you when it is ready");
    }else {
      alert(t("sorry_this_channel_has_not_been_added_yet"));
    }
  }

  //Handle ajout des channels 
  if (has_fetched_user_orgs) {
    global_state.user_orgs[global_state.selectedOrganisation] && global_state.user_orgs[global_state.selectedOrganisation].org._id && handleAjoutFacebookChannel(global_state.user_orgs[global_state.selectedOrganisation].org._id, async function () {
      let res = await get_facebook_pages({ org: global_state.user_orgs[global_state.selectedOrganisation].org._id });
      set_list_of_pages(res.pages);
      set_show_facebook_page_modal(true);
      set_current_channel_id(res.channel_id);
    });
  }

  useEffect(() => {
    if (!has_fetched_user_orgs) my_orgs();
  }, [global_state.user_orgs]);

  const current_org = global_state && global_state.user_orgs[global_state.selectedOrganisation] ? global_state.user_orgs[global_state.selectedOrganisation].org : null;
  const current_schedule = global_state && global_state.user_orgs[global_state.selectedOrganisation] ? global_state.user_orgs[global_state.selectedOrganisation].schedule : null;
  const current_payement = global_state && global_state.user_orgs[global_state.selectedOrganisation] ? global_state.user_orgs[global_state.selectedOrganisation].payement : null;

  const check_has_channel = (channel_name) => {
    if (!global_state.org_communication_channels) return { has: false, channel: {} };
    let has_channel = false;
    global_state.org_communication_channels.forEach(channel => {
      if (channel.info && channel.type.toLowerCase() === channel_name.toLowerCase()) has_channel = { has: true, channel: channel };
    });
    return has_channel;
  }

  const [admin_state, set_admin_state] = useState({
    open: false
  });

  const fn_open_admin_slide_over = () => {
    set_admin_state({
      open: true
    });
  }

  const [personnality_state, set_personnality_state] = useState({
    open: false
  });

  const handle_personality_slide_over = () => {
    set_personnality_state({
      open: true
    });
  }

  const __current_user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;

  const handleLinkedInConnectOrganization = () => {
    const current_user = JSON.parse(localStorage.getItem("user"));
    const user_email = current_user.email;
    // if (!(user_email === "elimek2@gmail.com" || user_email === "pubcmtester@gmail.com" || user_email === "pubcmtester2@gmail.com")) {
    //   alert("Sorry, this feature will be available soon.");
    //   return;
    // }
    set_show_linkedin_connect_modal(false);
    const org_name = global_state.user_orgs[global_state.selectedOrganisation].org.name;
    const org_id = global_state.user_orgs[global_state.selectedOrganisation].org._id;
    const url = getLinkedinLoginURL("organization", org_id, org_name);
    window.location.href = url;
  }

  const handlePersonalAccountLinkedInConnect = () => {
    set_show_linkedin_connect_modal(false);
    const org_name = global_state.user_orgs[global_state.selectedOrganisation].org.name;
    const org_id = global_state.user_orgs[global_state.selectedOrganisation].org._id;
    const url = getLinkedinLoginURL("personal", org_id, org_name);
    window.location.href = url;
  }


  // update_user_language({language : i18next.language});

  const number_of_channels = global_state.org_communication_channels ? global_state.org_communication_channels.length : 0;
  const user_email_ = JSON.parse(localStorage.getItem("user")).email; 
  const is_ig_authorized = user_email_ === "elimek2@gmail.com" || user_email_ === "pubcmtester2@gmail.com" || user_email_ === "pubcmtester@gmail.com";

  return (<>
    {
      global_state.loading_orgs ? <div style={{ display: "flex", height: (parseInt(window.innerHeight)) + "px", flexDirection: "column", textAlign: "center", alignItems: "center", justifyContent: "center" }}><Loading style={{ display: "inline-block" }} /></div> :

        <DashboardLayout user_orgs={global_state.user_orgs} current_org_id={current_org ? current_org._id : ''} change_org_fn={changeOrganization}>
          {show_facebook_page_modal && <FacebookPageModal channel_id={current_channel_id} list={list_of_pages} open={true} setOpen={set_show_facebook_page_modal} />}


          {global_state.user_orgs.length ? <>
            {/* <div className="hidden lg:col-span-2 lg:block xl:col-span-2">
              <nav aria-label="Sidebar" className="rounded-md shadow top-6 divide-y divide-gray-300 py-4 pt-1 ">
                <LeftNavigation state={global_state} current_org={current_org} fn_change_org={changeOrganization} user_org_obj={global_state.user_orgs[global_state.selectedOrganisation]}/>                
              </nav>

              
            </div> */}


            {/* <SlideOverFileUpload/> */}

            <SlideOverAdmin state={admin_state} fn_state={set_admin_state} org={current_org} user_org={global_state.user_orgs[global_state.selectedOrganisation]} />
            <SlideOverPersonality state={personnality_state} fn_state={set_personnality_state} org={current_org} user_org={global_state.user_orgs[global_state.selectedOrganisation]} />
            <SelectLinkedinType org={current_org} state={{ open: show_linkedin_connect_modal }} fn_state={set_show_linkedin_connect_modal} actions={{ personal: handlePersonalAccountLinkedInConnect, organization: handleLinkedInConnectOrganization }} />

            {/* <button onClick={handle_personality_slide_over} className="block font-bold lg:hidden p-0 w-14 h-14 shadow-lg fixed bottom-5 right-5 text-white z-10 bg-gradient-to-r from-teal-600  to-pubcm2 rounded-full hover:bg-pubcm1 active:shadow-lg ">
              {/* <PuzzlePieceIcon className="h-6 w-6 top-3 text-white mx-auto inline-block"/> * /}
              {t("CM")}
            </button> */}

            <div className="col-span-12 bg-white py-2 px-2 shadow ml-2 hidden lg:block">
              <div className=" md:flex gap-x-5">
                <div className="flex-2">
                  <div className="text-2xl font-bold text-slate-700">{current_org.name}</div>
                </div>

                {/* <div className="text-sm text-slate-600 py-2">{current_org.description}</div> */}
                <div className="flex-1">
                  <div className="text-xs py-2">{t("tags")} : {current_org.tags && current_org.tags.split(",").map((tag, i) => <span key={tag + "" + i} className="mx-0.5 bg-slate-100 px-2 py-1 rounded-md">{tag}</span>)}</div>
                  <div className="md:flex text-sm text-slate-600 gap-x-6">
                    {current_org.website && <div><GlobeEuropeAfricaIcon className="h-4 w-4 inline text-slate-600" /> {current_org.website} </div>}
                    {current_org.phone && <div><PhoneIcon className="h-4 w-4 inline text-slate-600" /> {current_org.phone} </div>}
                    {current_org.email && <div><EnvelopeIcon className="h-4 w-4 inline text-slate-600" /> {current_org.email} </div>}
                    
                  </div>
                </div>
                <div className="flex-2">
                  {current_org.logo && <img onClick={() => {navigate(`/manage/org/branding/${current_org._id}/${current_org.name}`)}} className="w-20 p-1 cursor-pointer hover:border hover:rounded-md" src={current_org.logo} alt="Company logo"/>}
                  {/* <div className="md:flex text-sm text-slate-600 gap-x-6 py-1 max-w-md">
                    {current_org.description.substr(0, 150)}
                  </div> */}
                </div>

              </div>

            </div>

            <main className="md:col-span-12 lg:col-span-9">
              {

                global_state.selectedOrganisation && !global_state.user_orgs[global_state.selectedOrganisation].channels.length ? <div>
                  <div className="bg-gradient-to-r from-orange-500 to-red-500 shadow text-sm text-white m-2 p-2 text-center rounded-md">
                    <span>{t("warning")}</span> : {t("make_sure_you_have_at_least_1_channel")}
                  </div>
                </div> : ''
              }

              {/* <div className="hidden lg:block bg-gray-100 hover:bg-white hover:cursor-pointer hover:font-bold px-4 py-4 rounded-md mx-2 my-2 shadow">
                <div className="border border-slate-300 md:inline py-2 px-4 text-slate-400 rounded-md my-4">Écrire pour publier ou planifier une publication</div>
                <div className="border border-slate-300 md:inline py-2 pl-2 pr-4 text-slate-400 rounded-md my-4 mx-2"> <CalendarIcon className="h-5 inline relative"/> &nbsp; <span className="top-1">{new Date().toLocaleString()}</span></div>
                <div className="block md:inline"><div className="px-4 py-2 rounded bg-pubcm1 text-white text-sm inline">Planifier</div></div>
              </div>

              <div className="block lg:hidden bg-white hover:cursor-pointer hover:font-bold px-4 py-4 rounded-md mx-2 my-2 shadow">
                <div className="border border-slate-300 md:inline py-2 px-4 text-slate-400 rounded-md my-4">Écrire pour publier ou planifier une publication</div>
              </div> */}

              <div className="bg-white px-4 py-2 rounded-md mx-2 my-2 shadow">
                <div>
                  <InputTextNewPost user_info={{}}/>
                </div>
              </div>

              <div className="bg-white px-4 py-2 rounded-md mx-2 my-2 shadow">
                <UpcomingPosts
                  org_id={current_org._id} 
                  channels={communication_channels} 
                />
                
              </div>

              <div className="bg-white px-4 py-2 rounded-md mx-2 my-2 shadow">
                <PostsZone org_id={current_org._id} />
              </div>
            </main>

            <div id="actions_menus" className="h-10 lg:hidden">
              
            </div>
            <div className="my-2 text-3xl font-bold lg:hidden ml-2">Menus</div>

            {current_org && (
              <aside className="rounded-md shadow lg:col-span-3 xl:col-span-3 xl:block mb-16">
                {/*<aside className="hidden rounded-md shadow xl:col-span-3 xl:block"> */}
                
                {__current_user && __current_user.email === "pubcmtester2@gmail.com" ? <ActivateTesterMembership /> : <></>}

                <div className="space-y-4 bg-white px-6 py-2 relative">
                  <div className='text-sm '>{t("social_channels")} <Link className="absolute right-6 text-slate-600 bg-slate-100 hover:bg-sky-200 px-2 py-2 rounded-md text-xs" to={`/manage/org/${current_org._id}#social-channels`}> <Cog6ToothIcon className="h-5 inline" /> <span className="">{t("manage")}</span></Link></div>
                  {/* <fieldset className="border rounded">
                    <legend className="text-xs bg-white px-4 text-gray-500">{t("short_text_channels")}</legend>
                    
                  </fieldset> */}

                  <div>
                  <ul>
                      {
                        communication_channels.map((channel, i) => {
                          if (channel.type === "long") return "";
                          if (channel.display_name == "Instagram"){
                            if(is_ig_authorized);
                            else return "";
                          } 
                          const has_channel = check_has_channel(channel.name);
                          return <li key={channel.name} onClick={has_channel.has ? () => null : () => addChannel(channel.name)} className='py-2 px-2 hover:bg-blue-100 cursor-pointer my-2 mx-1 border-bottom'>
                            {/* <img className="w-4 inline mr-4" style={{filter: !channel.info ? 'grayscale(100%)' : ''}} src={channel_icons[channel.type]} alt={channel.name} /> {channel.name ? channel.name : channel.type} {channel.info && <span className='text-xs text-slate-600'> ({channel.info.name})</span>} */}
                            <img className="w-4 inline mr-4" style={{ filter: !has_channel.has ? 'grayscale(100%)' : '' }} src={channel.img} alt={channel.name} /> <span onClick={selectChannel} >{channel.name ? channel.display_name : channel.type}</span> {channel.info && <div className='text-xs text-slate-600'> ({channel.info.name})</div>}
                            {!has_channel.has ? <span className={`text-xs border ${number_of_channels === 0 ? `border-2 border-orange-500` : ''} relative text-blue-600 hover:bg-white border-blue-500 px-2 py-1 ml-2 bg-blue-100 rounded-md`}> {number_of_channels === 0 && <Blinker/>} {t("add")} </span> : <ToggleChannel enabled={has_channel.channel.status === "active"} channel={has_channel} />}
                            {has_channel.has && fb_invalid_token && channel && channel.name.toLowerCase() === 'facebook' && <div className='text-xs bg-yellow-300 text-red-600 text-white inline-block py-1'> {t("please_refresh_the_token_click_add")} </div>}
                            <div>{fb_invalid_token}</div>
                          </li>
                        })
                      }
                    </ul>
                  </div>

                  {/* <fieldset className="border rounded">
                    <legend className="text-xs bg-white px-4 text-gray-500">{t("long_text_channels")}</legend>
                    <ul>
                      {
                        communication_channels.map((channel, i) => {
                          if (channel.type === "short") return "";
                          const has_channel = check_has_channel(channel.name);
                          return <li key={channel.name} onClick={has_channel.has ? selectChannel : () => addChannel(channel.name)} className='py-2 px-2 hover:bg-blue-100 cursor-pointer my-2 mx-1 border-bottom'>
                            {/* <img className="w-4 inline mr-4" style={{filter: !channel.info ? 'grayscale(100%)' : ''}} src={channel_icons[channel.type]} alt={channel.name} /> {channel.name ? channel.name : channel.type} {channel.info && <span className='text-xs text-slate-600'> ({channel.info.name})</span>} * /}
                            <img className="w-4 inline mr-4" style={{ filter: !has_channel.has ? 'grayscale(100%)' : '' }} src={channel.img} alt={channel.name} /> {channel.name ? channel.name : channel.type} {channel.info && <span className='text-xs text-slate-600'> ({channel.info.name})</span>}
                            {!has_channel.has ? <span className='text-xs text-blue-600 hover:bg-white border-blue-500 px-2 py-1 ml-2 bg-blue-100 rounded-md'> {t("add")} </span> : ''}

                          </li>
                        })
                      }
                    </ul>
                  </fieldset> */}



                </div>

                <div className="bg-white py-2 my-2 px-2 mb-4 rounded-md mx-1 shadow">
                  <DesignPostWidget org_id={current_org._id} org_name={current_org.name} has_logo={current_org.logo} use_ai_img={current_org.use_ai_img} add_text_img={current_org.add_text_img}/>
                </div>

                <div className="bg-white py-2 my-2 px-2 mb-4 rounded-md mx-1 shadow">
                  <ul className="divide-y divide-gray-200 text-gray-700">
                    <li className="py-2 relative hover:bg-slate-100 cursor-pointer rounded-md " onClick={handle_personality_slide_over}><SwatchIcon className="h-4 w-4 absolute ml-2 top-3 text-rose-500" /> <span className="ml-8">{t('customize_your_AI')}</span> <ChevronRightIcon className="h-5 w-5 text-slate-400 absolute right-1 top-3" /></li>
                    <li className="py-2 relative hover:bg-slate-100 cursor-pointer rounded-md"> <Link to={`/manage/org/${current_org._id}`}><BuildingOffice2Icon className="h-4 w-4 absolute ml-2 top-3 text-green-500" /> <span className="ml-8">{t('edit_organization')}</span> <ChevronRightIcon className="h-5 w-5 text-slate-400 absolute right-1 top-3" /> </Link></li>
                    <li className="py-2 relative hover:bg-slate-100 cursor-pointer rounded-md"> <Link to={`/manage/org/branding/${current_org._id}/${current_org.name}`}><PencilIcon className="h-4 w-4 absolute ml-2 top-3 text-red-500" /> <span className="ml-8">{t('logo_branding')}</span> <ChevronRightIcon className="h-5 w-5 text-slate-400 absolute right-1 top-3" /> </Link></li>
                    
                    <li className="py-2 relative hover:bg-slate-100 cursor-pointer rounded-md" onClick={fn_open_admin_slide_over}> <UsersIcon className="h-4 w-4 absolute ml-2 top-3 text-violet-500" /> <span className="ml-8">{t('manage_your_team')}</span> <ChevronRightIcon className="h-5 w-5 text-slate-400 absolute right-1 top-3" /> </li>
                    <li className="py-2 relative hover:bg-slate-100 cursor-pointer rounded-md"> <Link to={`/manage/org/media/${current_org._id}/${current_org.name}`}><PhotoIcon className="h-4 w-4 absolute ml-2 top-3 text-sky-500" /> <span className="ml-8">{t('manage_files')}</span> <ChevronRightIcon className="h-5 w-5 text-slate-400 absolute right-1 top-3" /> </Link></li>
                    <li className="py-2 relative hover:bg-slate-100 cursor-pointer rounded-md"> <Link to={`/manage/org/products/${current_org._id}/${current_org.name}`} ><RectangleStackIcon className="h-4 w-4 absolute ml-2 top-3 text-yellow-500" /> <span className="ml-8">{t('my_products_and_services')}</span> <ChevronRightIcon className="h-5 w-5 text-slate-400 absolute right-1 top-3" /> </Link></li>
                    {/* <li className="py-2 relative hover:bg-slate-100 cursor-pointer rounded-md"> <Link to={`/manage/org/marketing_studio/${current_org._id}/${current_org.name}`}><MegaphoneIcon className="h-4 w-4 absolute ml-2 top-3 text-orange-500" /> <span className="ml-8">{t('marketing_studio')}</span> <ChevronRightIcon className="h-5 w-5 text-slate-400 absolute right-1 top-3" /> </Link></li> */}
                    {/* <li className="py-2 relative hover:bg-slate-100 cursor-pointer rounded-md"> <Link to={`#`} onClick={feature_not_yet_implemented}><BookOpenIcon className="h-4 w-4 absolute ml-2 top-3 text-blue-500" /> <span className="ml-8">{t('knowledge_base')}</span> <ChevronRightIcon className="h-5 w-5 text-slate-400 absolute right-1 top-3" /> </Link></li> */}
                    
                  </ul>
                </div>
                {/* <div className="bg-white py-2 my-3 mx-3 md:mx-0 md:my-0 px-2 shadow border-4 border-gradient-glow"> */}
                <div className="bg-white py-2 my-3 mx-3 md:mx-0 md:my-0 px-2 shadow">
                  <div className="text-xl font-bold">{t("your_posts_objective")}</div>
                  <div className="my-2 border p-2 text-md bg-slate-100">
                    {global_state.user_orgs[global_state.selectedOrganisation] && global_state.user_orgs[global_state.selectedOrganisation].objective ? global_state.user_orgs[global_state.selectedOrganisation].objective.description : t("write_post_about_my_organization")}
                  </div>
                  <div className="py-2">
                    <div>
                      <Link to={`/manage/org/objective/${current_org._id}/${current_org.name}`} className="bg-sky-500 text-white to-pubcm2 px-4 py-2 rounded"> <SparklesIcon className="h-5 inline" /> {t("tell_me_what_to_do")}</Link>
                    </div>
                    <div className="text-xs pt-4 text-slate-700">
                      {t("provide_me_with_directions_on_what_to_do_next")}
                    </div>
                  </div>
                </div>

                <Plans payement={current_payement} schedule={current_schedule} org_id={current_org._id} org_name={current_org.name} />

                <div className="bg-white py-2 my-2 px-2">
                  {t("need_a_special_feature")}
                  <div className="py-2">
                    <a href="mailto:info@pubcm.com" className="bg-gradient-to-r from-green-600 text-white to-pubcm2 px-2 py-1 rounded">{t("contact_us")}</a>
                  </div>
                </div>

                <div className="bg-white py-2 my-3 mx-3 md:mx-0 md:my-0 px-2 shadow">
                  <AffiliateProgramWidget promo_code={global_state.user_orgs[global_state.selectedOrganisation].promo_code} />                  
                </div>

                

                {/* <div className="my-2 px-6 py-3 block lg:hidden">
                  <div>
                    <div className='text-sm'>{t("organizations")}</div>
                    <LeftNavigation state={global_state} current_org={current_org} fn_change_org={changeOrganization} selected_org={global_state.selectedOrganisation} user_org_obj={global_state.user_orgs[global_state.selectedOrganisation]} />
                  </div>
                </div> */}


              </aside>
            )}


          </> :
            <DontHavePlan />

          }

        </DashboardLayout>
    }
  </>
  );
}

function Blinker(){
  return <span className="absolute inline left-9 top-0 flex h-3 w-3">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-600 opacity-75"></span>
        <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
      </span>;
}
import { useEffect } from "react";
import { get_twitter_access_token } from "../../../utils/api";

export const Twitter = (props) => {
    //get oauth_token and oauth_verifier from query string
    const oauth_token = window.location.search.match(/oauth_token=([^&]+)/)[1];
    const oauth_verifier = window.location.search.match(/oauth_verifier=([^&]+)/)[1];

    const org_id = localStorage.getItem("selected_org_id");

    const handleFinalize = async () => {
        const access = await get_twitter_access_token(org_id, oauth_token, oauth_verifier);
        console.log(access);
        if(access && access.accessToken && access.accessTokenSecret && access.status === "active"){
            window.location.href = "/";
        }else{
            alert("Something went wrong. Please try again.");
            window.location.href = "/";
        }
    }

    useEffect(() => {
        setTimeout(() => {
            handleFinalize();
        }, 1000);
    }, []);

    return (<div>
        <h1>Finalizing Twitter connection ... </h1>

        {/* <div className="bg-white py-2 px-2 rounded-md shadow">
            <p>oauth_token: {oauth_token}</p>
            <p>oauth_verifier: {oauth_verifier}</p>
            <p>
                <button className="px-4 py-2 border shadow my-4" onClick={handleFinalize}>Connect</button>
            </p>
        </div> */}
    </div>);
}
const prices = {
    "starter" : {
        "price" : 12.91,
        "posts" : 24
    },
    "essentials":{
        "price" : 17.33,
        "posts" : 24
    },
    "pro":{
        "price" : 34.58,
        "posts" : 90
    },
    "business":{
        "price" : 86.99,
        "posts" : 90
    }
}

export const get_local_price_list = () => {
    return prices;
}
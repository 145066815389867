import { Fragment, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Dialog, Transition } from '@headlessui/react'

import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  PencilSquareIcon,
  PaperAirplaneIcon,
  TrashIcon,
  ArrowPathIcon,
  BoltIcon,
  CameraIcon,
  PhotoIcon,
  ShareIcon,
} from '@heroicons/react/20/solid';

import { Menu } from '@headlessui/react';
import { createCalendarDates, getNameOfMonth } from '../../../utils/calendar';
import { get_back_end_url, get_organization_month_posts, get_organization_scheduled_posts, get_upcoming_posts_per_day, publish_post_to_channels, update_post_image, update_post_image_index } from '../../../utils/api';

import imengeBot from '../../../res/img/imenge_bot.png';
import byMyCar from '../../../res/img/by_my_car.svg';
import ModalEditPost from './schedule-components/ModalEditPost';
import ModalDeletePost from './schedule-components/ModalDeletePost';
import SlideOverPickFile from '../../../components/SlideOverPickFile';
import i18next from '../../../i18next';
import { share_post_on_media } from '../../../utils/share';
const { t } = i18next;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

var default_posts = {};
let timeout_update_img = null;
let linking_file_post_index = 0;

export default function UpcomingPosts(props) {
  const [modal_state, set_modal_state] = useState({
    open: false,
    post: {},
    post_index: 0
  });


  const [modal_del_state, set_modal_del_state] = useState({
    open: false,
    post: {},
    post_index: 0
  });

  const [show_pick_file, set_show_pick_file] = useState(false);
  const [origin_post, set_origin_post] = useState({});
  const handle_show_pick_file = (index, post) => {
    if (post) {
      linking_file_post_index = index;
      set_origin_post(post);
    }
    set_show_pick_file(!show_pick_file);
  }

  window.addEventListener('added_post', (e, data) => {
    const added_post = e.detail.post;
    let posts = global_state.posts;
    posts.docs.unshift(added_post);
    set_global_state({
      ...global_state,
      posts: posts
    });
  });

  const cancelButtonRef = useRef(null)

  let days = [];
  let month = new Date().getMonth();
  let year = new Date().getFullYear();
  let name_of_month = getNameOfMonth(month);

  //add event listener 

  console.log("TTHE ELFSDFJSKFJKSA", props.post_evt);


  days = createCalendarDates(month, year);

  //console.log(`The days are `, days);

  const [global_state, set_global_state] = useState({
    posts: {
      docs: []
    },
    calendar: days,
    calendar_posts: [],
    upcoming_posts_text: t("upcoming_posts")
  });

  const handleDayClick = async (day) => {
    const posts = await get_upcoming_posts_per_day(props.org_id, day, 1, 4);
    default_posts = posts;
    set_global_state({
      ...global_state,
      posts: posts,
      upcoming_posts_text: t("posts_for", { day })
    });
  }

  const handleClickButton = async (post, index, type) => {
    if (type === "edit") {
      //alert("This feature is not yet implemented");
      set_modal_state({
        open: true,
        post: post,
        post_index: index
      });
    } else if (type === "cancel") {
      set_modal_del_state({
        open: true,
        post: post,
        post_index: index
      });
    } else if (type === "publish") {
      try {
        const res = await publish_post_to_channels(post._id);

        alert(t("post_published_successfully_you_will_be_able_to_see"));
        let posts = global_state.posts;
        posts.docs.splice(index, 1);
        set_global_state({
          ...global_state,
          posts: posts
        });
      } catch (ex) {
        alert(t("an_error_occured_while_publishing_the_post"));
      }

    }
  }

  const get_up_coming_posts = async (date) => {
    let posts = await get_organization_scheduled_posts(props.org_id, 1, 4);
    let mon_posts = await get_organization_month_posts(props.org_id, 1, 10);

    default_posts = posts;

    set_global_state({
      ...global_state,
      posts: posts,
      calendar_posts: mon_posts,
    });
  }

  const get_preceding_empty_boxes = (day) => {
    let boxes = [];
    for (let i = 0; i < day; i++) {
      boxes.push(<button
        type="button"
        className="py-1.5 hover:bg-gray-100 focus:z-10"
      >
        <span className="sr-only"></span>
        <span className="text-gray-400"></span>
      </button>);
    }
    return boxes;
  }

  const handleUpdateSelectedImg = async (post_index, direction) => {
    let posts = global_state.posts;
    let selected_image = posts.docs[post_index].selected_image;
    let max_images = posts.docs[post_index].images_suggestions.photos.length;
    if (direction === "left") {
      selected_image = selected_image - 1;
      if (selected_image < 0) {
        selected_image = max_images - 1;
      }
    } else if (direction === "right") {
      selected_image = selected_image + 1;
      if (selected_image >= max_images) {
        selected_image = 0;
      }
    }
    if (timeout_update_img) clearTimeout(timeout_update_img);
    timeout_update_img = setTimeout(() => {
      update_post_image_index(posts.docs[post_index]._id, { selected_image: selected_image });
    }, 2000);


    posts.docs[post_index].selected_image = selected_image;

    set_global_state({
      ...global_state,
      posts: posts
    });

  }

  const handleUpdateSelectedImg1 = async (post_index) => {
    let posts = global_state.posts;
    //Select a random image from post.images_suggestions.photos array
    let random_index = Math.floor(Math.random() * posts.docs[post_index].images_suggestions.photos.length);
    update_post_image_index(posts.docs[post_index]._id, { selected_image: random_index });
    posts.docs[post_index].selected_image = random_index;
    //alert(JSON.stringify(posts.docs[post_index].selected_image));
    set_global_state({
      ...global_state,
      posts: posts
    });
  }

  const handleResetSelectedImg = async (post_index) => {
    //alert(JSON.stringify(default_posts.docs[post_index].selected_image));
    let posts = global_state.posts;
    const selected_image = default_posts.docs[post_index].selected_image;
    update_post_image_index(posts.docs[post_index]._id, { selected_image: selected_image });
    posts.docs[post_index].selected_image = selected_image;
    set_global_state({
      ...global_state,
      posts: posts
    });
  }

  const handleShowAllText = (post_index) => {
    let posts = global_state.posts;
    console.log(posts);
    posts.docs[post_index].show_all_text = true;
    set_global_state({
      ...global_state,
      posts: posts
    });
  }

  const handleUpdatePostFile = async (post, file, post_index) => {
    console.log(post, file);
    const res = await update_post_image(post._id, {
      url: file.url,
    });

    const updated_post = res.post;
    //Update post in global state
    let posts = global_state.posts;
    posts.docs[post_index] = updated_post;
    set_global_state({
      ...global_state,
      posts: posts
    });

    console.log(res);
  }

  


  useEffect(() => {
    get_up_coming_posts();
  }, [props.org_id]);

  const [show_calendar, set_show_calendar] = useState(false);

  //CONSOLE LOG posts
  console.log("The posts are ", global_state.posts);

  return (
    <div className='relative'>
      <SlideOverPickFile post_index={linking_file_post_index} post={origin_post} handle_select={handleUpdatePostFile} fn_show={handle_show_pick_file} show={show_pick_file} org_id={props.org_id} />
      <ModalEditPost global_state={global_state} set_global_state={set_global_state} state={modal_state} open={modal_state.open} setState={set_modal_state} />
      <ModalDeletePost global_state={global_state} set_global_state={set_global_state} state={modal_del_state} setState={set_modal_del_state} />
      <h2 className="text-lg font-semibold text-gray-900 mt-4 h-12">{global_state.upcoming_posts_text}</h2>
      <button className='px-4 py-2 border rounded-md absolute right-0 top-0' onClick={() => set_show_calendar(!show_calendar)}><CalendarIcon className='h-4 inline' /> <span className='text-sm'>{show_calendar ? t("hide_calendar") : t("show_calendar")}</span></button>

      {show_calendar && <div className="mt-10 mb-4 text-center lg:col-start-7 lg:col-end-12 lg:row-start-1 lg:mt-9 xl:col-start-7">
        <div className="flex items-center text-gray-900">
          <button
            type="button"
            className="-m-1.5 hidden flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">{t("Previous_month")}</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <div className="flex-auto font-semibold">
            {name_of_month + ' ' + year}
          </div>
          <button
            type="button"
            className="-m-1.5 hidden flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">{t("next_month")}</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
          <div>S</div>
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
        </div>

        <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
          {global_state.calendar[0].day_of_week > 0 && get_preceding_empty_boxes(global_state.calendar[0].day_of_week)}

          {global_state.calendar.map((day, dayIdx) => (day.isCurrentMonth ?
            <button
              key={day.date}
              type="button"
              onClick={() => handleDayClick(day.date)}
              className={classNames(
                'py-1.5 hover:bg-gray-100 focus:z-10',
                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                (day.isSelected || day.isToday) && 'font-semibold',
                day.isSelected && 'text-white',
                !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                day.isToday && !day.isSelected && 'text-indigo-600',
                dayIdx === 0 && 'rounded-tl-lg',
                dayIdx === 6 && 'rounded-tr-lg',
                dayIdx === days.length - 7 && 'rounded-bl-lg',
                dayIdx === days.length - 1 && 'rounded-br-lg'
              )}
            >
              <time
                dateTime={day.date}
                className={classNames(
                  'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                  day.isSelected && day.isToday && 'bg-indigo-600',
                  day.isSelected && !day.isToday && 'bg-gray-900'
                )}
              >
                {day.date.split('-').pop().replace(/^0/, '')}

              </time>
              <div>
                {
                  global_state.calendar_posts.map((post, postIdx) => {
                    //let post_date = new Date(post.createdAt);
                    const day_number = day.date.split('-').pop().replace(/^0/, '');
                    const month_day_post = parseInt(post._id);
                    const check_ = parseInt(day_number) === parseInt(post._id);
                    //console.log("Day number ", day_number,month_day_post, check_, day);
                    if (parseInt(day_number) === parseInt(post._id) && day.isCurrentMonth) {
                      return Array.from(Array(post.count > 5 ? 5 : post.count).keys()).map((c) => {
                        console.log("Called");
                        return <div key={`${day_number}-${c}-${postIdx}`} className='h-1 w-1 bg-gray-400 rounded-3xl m-px inline-block'></div>
                      });
                    }
                  }
                  )}
              </div>
            </button> : ''
          ))}
        </div>
      </div>}


      <div className="md:grid md:grid-cols-12 pb-4 pr-2">
        {global_state.posts.docs.length === 0 ? <div className='text-center mt-4 md:col-span-12 lg:col-span-12 xl:col-span-12'><div className='text-center'><img className='w-40 inline mb-4' src={byMyCar} alt={t("by_my_car")} /></div>{t("no_upcoming_posts_yet")} <div><small>{t("once_you_have_some_posts_they_will_be_displayed_here")}</small></div></div> : global_state.posts.docs.map((post, index_posts) => {
          let post_image = null;
          let img_id = `img-id-${post._id}`;
          if(post.images_suggestions && post.images_suggestions.photos && post.images_suggestions.photos.length > 0){
            post_image = post.images_suggestions.photos[post.selected_image].src.large;
          }
          
          return (
          <div key={post._id} className="md:col-span-6 xl:col-span-4 md:mx-2 md:my-2">
            {/* <div>{post._id}</div> */}
            <div className="max-w-full md:max-w-xs lg:max-w-full rounded overflow-hidden shadow-lg">
              {post.images_suggestions && post.images_suggestions.photos && post.images_suggestions.photos.length > 0 && <div className="h-30 relative">
                <img id={img_id} className="w-full object-cover h-72" src={post.images_suggestions.photos[post.selected_image].src.large} alt="img" />
                <button onClick={() => handleUpdateSelectedImg(index_posts, "left")} className="bg-slate-400/50 hover:bg-sky-400/70 shadow text-white absolute top-4 right-4 px-3 py-2 rounded-3xl"><ChevronLeftIcon className='inline h-6' /></button>
                <button onClick={() => handleUpdateSelectedImg(index_posts, "right")} className="bg-slate-400/50 hover:bg-sky-400/70 shadow text-white absolute top-16 right-4 px-3 py-2 rounded-3xl"><ChevronRightIcon className='inline h-6' /></button>
                <button onClick={() => handle_show_pick_file(index_posts, post)} className="bg-slate-400/50 hover:bg-sky-400/70 shadow text-white absolute top-28 right-4 px-3 py-2 rounded-3xl"><PhotoIcon className='inline h-6' /></button>
                {/* <button onClick={() => handle_show_pick_file(index_posts, post)} className="bg-slate-400/50 hover:bg-sky-400/70 shadow text-white absolute top-40 right-4 px-3 py-2 rounded-3xl"><ShareIcon className='inline h-6' /></button> */}
              </div>
              }
              <div>
                <div className='text-left pl-6 pt-3'>
                  {post.status === 'published' ? <div style={{ fontSize: "8px" }} className='bg-slate-200 text-gray-400 inline px-2 py-1 rounded-md'>{t("published")}</div> : <div style={{ fontSize: "8px" }} className='bg-orange-500 text-white inline px-2 py-1 rounded-md'>{t("draft_scheduled")}</div>}
                  {post.edited ? <div style={{ fontSize: "8px" }} className='bg-slate-200 bg-green-100 text-green-600 inline ml-1 px-2 py-1 rounded-md'>{t("edited")}</div> : ''}
                  <div>
                    {post.publishAt &&
                      <span className='ml-0 my-1 relative top'>
                        <CalendarIcon className="h-5 w-5 text-gray-400 inline" aria-hidden="true" />
                        <time className='ml-1 text-xs text-slate-700' dateTime={post.publishAt}>
                          {post.publishAt.substring(0, 10)} at {post.publishAt.substring(11, 16)}
                        </time>
                      </span>}
                  </div>
                </div>
              </div>
              <div className="px-6 py-2">
                <p className="text-gray-700 text-base">
                  {post.body.length < 200 || post.show_all_text ? post.body : post.body.substring(0, 200) + '...'}
                  {post.body.length > 200 && !post.show_all_text && <span className='text-pubcm2 underline underline-offset-1 cursor-pointer' onClick={() => handleShowAllText(index_posts)}> {t("read_more")}</span>}
                </p>

                {/* <div className="text-gray-700 text-base text-sm my-2">
                  <small>{post.twitter_body}</small>
                </div> */}

                
              </div>
              <div className="px-6 py-4 space-y-3">
                <button onClick={() => handleClickButton(post, index_posts, 'edit')} className='border text-xs px-2 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded-md'>
                  <PencilSquareIcon className='inline h-3' />  {t("edit")}
                </button>
                <button onClick={() => handleClickButton(post, index_posts, 'publish')} className='border text-xs px-2 py-1 bg-green-500 hover:bg-green-600 text-white rounded-md'>
                  <PaperAirplaneIcon className='inline h-3' />  {t("publish_now")}
                </button>
                <button onClick={() => handleClickButton(post, index_posts, 'cancel')} className='border text-xs px-2 py-1 bg-red-500 hover:bg-red-600 text-white rounded-md'>
                  <TrashIcon className='inline h-3' />  {t("delete")}
                </button>
                {navigator && navigator.share && <button className='border text-xs px-2 py-1 bg-slate-500 hover:bg-slate-600 text-white rounded-md' onClick={() => share_post_on_media( post.body, post_image)}>
                  <ShareIcon className='inline h-3' /> {t("share")}
                </button>}
              </div>
            </div>
          </div>
        )
        })}

      </div>
    </div>
  )
}

import { useEffect, useState } from "react";
import { Dot } from "react-animated-dots";
import DashboardLayout from "../../components/DashboardLayout";
import { get_linked_accounts, get_linkedin_organizations_accounts, get_linkedin_token, save_linkedin_org_channel } from "../../utils/api";
import LinkedinImgIcon from "../../res/img/comm_channels/linkedin.png";
import { BuildingLibraryIcon, UserCircleIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";
import { getLinkedinLoginURL } from "../../utils/channels";
import Loading from "../../components/Loading";

import i18next from "../../i18next";
const { t } = i18next;

let is_loading_token = false;
let meta_data = null;
export default function PickLinkedinOrg() {
    const [accounts, set_accounts] = useState([]);
    const [loading, set_loading] = useState(false);
    const { org_id, org_name } = useParams();
    const handle_get_accounts = async () => {
        try {
            set_loading(true);
            const res = await get_linkedin_organizations_accounts(org_id);
            set_loading(false);
            // const res__ = {
            //     "organizations": {
            //         "elements": [
            //             {
            //                 "organizationalTarget~": {
            //                     "vanityName": "pubcm",
            //                     "localizedName": "PubCM",
            //                     "name": {
            //                         "localized": {
            //                             "en_US": "PubCM"
            //                         },
            //                         "preferredLocale": {
            //                             "country": "US",
            //                             "language": "en"
            //                         }
            //                     },
            //                     "primaryOrganizationType": "NONE",
            //                     "locations": [],
            //                     "id": 89048239,
            //                     "localizedWebsite": "https://www.pubcm.com",
            //                     "logoV2": {
            //                         "cropped": "urn:li:digitalmediaAsset:C4D0BAQF_NFcFRmn54w",
            //                         "cropInfo": {
            //                             "width": 0,
            //                             "x": 0,
            //                             "y": 0,
            //                             "height": 0
            //                         },
            //                         "original": "urn:li:digitalmediaAsset:C4D0BAQF_NFcFRmn54w"
            //                     }
            //                 },
            //                 "organizationalTarget": "urn:li:organization:89048239"
            //             },
            //             {
            //                 "organizationalTarget~": {
            //                     "vanityName": "trotoapp",
            //                     "localizedName": "Troto",
            //                     "name": {
            //                         "localized": {
            //                             "en_US": "Troto"
            //                         },
            //                         "preferredLocale": {
            //                             "country": "US",
            //                             "language": "en"
            //                         }
            //                     },
            //                     "primaryOrganizationType": "NONE",
            //                     "locations": [],
            //                     "id": 67752123,
            //                     "localizedWebsite": "https://troto.co"
            //                 },
            //                 "organizationalTarget": "urn:li:organization:67752123"
            //             },
            //             {
            //                 "organizationalTarget~": {
            //                     "vanityName": "nfinic",
            //                     "localizedName": "nfinic",
            //                     "name": {
            //                         "localized": {
            //                             "en_US": "nfinic"
            //                         },
            //                         "preferredLocale": {
            //                             "country": "US",
            //                             "language": "en"
            //                         }
            //                     },
            //                     "primaryOrganizationType": "NONE",
            //                     "locations": [
            //                         {
            //                             "description": {
            //                                 "localized": {
            //                                     "en_US": "Headquarters"
            //                                 },
            //                                 "preferredLocale": {
            //                                     "country": "US",
            //                                     "language": "en"
            //                                 }
            //                             },
            //                             "locationType": "HEADQUARTERS",
            //                             "address": {
            //                                 "city": "Goma",
            //                                 "country": "CD",
            //                                 "geographicArea": "Nord-Kivu"
            //                             },
            //                             "localizedDescription": "Headquarters",
            //                             "streetAddressFieldState": "OPT_OUT",
            //                             "geoLocation": "urn:li:geo:106719817"
            //                         }
            //                     ],
            //                     "id": 43338422,
            //                     "localizedWebsite": "https://www.nfinic.com",
            //                     "logoV2": {
            //                         "cropped": "urn:li:digitalmediaAsset:C4D0BAQHygXWN9qatJQ",
            //                         "cropInfo": {
            //                             "width": 0,
            //                             "x": 0,
            //                             "y": 0,
            //                             "height": 0
            //                         },
            //                         "original": "urn:li:digitalmediaAsset:C4D0BAQHygXWN9qatJQ"
            //                     }
            //                 },
            //                 "organizationalTarget": "urn:li:organization:43338422"
            //             }
            //         ]
            //     },
            //     "meta": {
            //         "access_token": "AQUzr2JKiRIqzfubdhsEH1fsy6lHWaaueD-E69ElLTnqMaODKMm_tFLm1dlCjXHSOGY9_IVeobIanZKnvYq4i1DD1pTATsCz_QWgX5I40tUJFikRkBE9_0sMvRquwXiWRRuIEl8QWSh1qP4iPPBI8UQfm_tBcX6GI9CPZPnO8mUEvr3XZbJJUGMG9yVXIRBCqerJXYNOqCnIyWBBfEISuPIv3x4vuqyuqlYh0DZurl3pcuBSFHxpk23-BYV69PG7uK56GqNNvnOFG-L7dSCaNQ9_JyGc1it5O6veHJZe9MTHWYZDugx-Q_y0AsKe9oe3t2iB5braWvLwht5g2jtcYucPjcVPgg",
            //         "expires_in": 5183999,
            //         "refresh_token": "AQU92Psy9_OCpSGQ9GrbJTF4d7TWTwXGfZO8ddld8UW8G8czdBTlTc8K3i4W0b9-YvmDA3hEDKoNJ8i4cJ1OlvjO0D9_VeeH-Fy5YcBBmJ8ak8S1rvnFHG6_Igephd4qcLkcDKHK-qTp6D2ICe8TZHvnCKEDsMAePmpNtrBHWbSIsHOqXPPp3eMn4qvh6tTzhczB0R9YVglax0nGk_7VC7VQtyr3qetYjMC-klCX8yZuTXhQJ0p1LStN-HQj0kiluOHIF3K6-xuJQW03ejjfl2fZt_rYFCvtBvIefYKInViRZlHRpqbgtpcvLE9tTK4uoIOepUXCx1Fl26oP1Gcs2iGGVYtWyw",
            //         "refresh_token_expires_in": 31536059,
            //         "scope": "r_basicprofile,r_organization_social,rw_organization_admin,w_organization_social"
            //     }
            // };

            meta_data = res.meta;

            if (res.organizations && res.organizations.elements)
                set_accounts(res.organizations.elements);
        }
        catch (e) {
            set_loading(false);
            console.log(e);
        }

    }

    const handle_connect_linkedin = async (type) => {
        const url = getLinkedinLoginURL(type, org_id, org_name);
        window.location.href = url;

    }

    const handleSelectOrg = async (org_channel) => {
        const res = await save_linkedin_org_channel(org_id, {
            meta: meta_data,
            channel: org_channel
        });

        console.log(res);
        window.location.href = "/";
    }

    useEffect(() => {
        // getLinkedinToken();
        handle_get_accounts();
    }, [])


    return (
        <DashboardLayout>
            <div className="mt-6 text-center bg-white px-2 py-4 rounded-md col-span-12">
                {!loading ? <div>
                    <div className="text-xl my-2">{t("connect")} <span className="font-bold">{org_name}</span> {t("to_linked_in")}</div>

                    {accounts && accounts.length > 0 && <div className="pt-4 border-b  mt-4">
                        <div><img src={LinkedinImgIcon} className="h-10 inline" /></div>
                        {t("connect_to_my_org_with_previous_account")}
                        <div className="text-xs text-gray-500">{t("uses_the_connection_you_previously")}</div>
                        <div>
                            <ul className="w-80 inline-block mt-3 divide-y divide-gray-200">
                                {accounts && accounts.map((account, index) => <li className="flex hover:bg-slate-100">
                                    <div className="flex-1 px-4 py-4">{account['organizationalTarget~']['localizedName']}</div>
                                    <div className="flex-1 px-4 py-2">
                                        <button onClick={() => handleSelectOrg(account, index)} className="bg-sky-400 hover:bg-sky-700 text-white px-4 py-2 rounded-md text-xs">{t("connect")}</button>
                                    </div>
                                </li>)}
                            </ul>
                        </div>
                    </div>}
                    <div className="flex">
                        <div className="flex-1 px-4 py-4">
                            <div><BuildingLibraryIcon className="text-gray-500 h-12 inline" /></div>
                            {t("connect_an_organization_linkedin")}
                            <div className="text-xs text-gray-500">{t("a_new_connection_will_be_initiated_linkedin")}</div>
                            <div>
                                <button onClick={() => handle_connect_linkedin("organization")} className="bg-slate-500 text-white px-4 py-2 rounded-md mt-4 text-xs">{t("connect_organization")}</button>
                            </div>
                        </div>
                        <div className="flex-1 border-l px-4 py-4">
                            <div><UserCircleIcon className="text-gray-500 h-12 inline" /></div>
                            {t("connect_to_a_personnal_linkedin_page")}
                            <div className="text-xs text-gray-500">{t("a_new_connection_will_be_initiated_linkedin")}</div>
                            <div>
                                <button onClick={() => handle_connect_linkedin("personal")} className="bg-slate-500 text-white px-4 py-2 rounded-md mt-4 text-xs">{t("connect_personal")}</button>
                            </div>
                        </div>
                    </div>
                </div> : <div className="py-16">
                    <Loading style={{display: "inline-block"}}/>
                    <div>{t("please_wait")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></div>
                </div>
                }
            </div>
        </DashboardLayout>
    );
}
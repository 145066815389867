import icon from '../../res/img/icon-transparent.png';

import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin, } from '@react-oauth/google';
import { FacebookProvider, LoginButton, useLogin } from 'react-facebook';
import { get_user_info, sign_with_email, signup_with_email } from '../../utils/api';
import { useEffect, useState } from 'react';
//const { OAuth2Client } = require('google-auth-library');
import { LinkedIn, useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import linkedinIcon from '../../res/img/linkedin-icon.png';
import { openLinkedinLogin } from '../../utils/channels';
import i18next from '../../i18next';
import { NavLink, useParams } from 'react-router-dom';
import { Dot } from 'react-animated-dots';
import AuthLayout from './AuthLayout';

const { t } = i18next;

export default function Signup() {

  const [display_loading, set_display_loading] = useState("none");
  const [waiting_for_google, set_waiting_for_google] = useState(true);

  const { referral_code } = useParams();

  if (referral_code) {
    localStorage.setItem("referral_code", referral_code);
  }


  if (localStorage && localStorage.getItem("token")) {
    window.location.href = "/";
  }

  const [sign_up_info, set_sign_up_info] = useState({
    names: "",
    email: "",
    phone_number: "",
    password: ""
  });
  const [signing_up, set_signing_up] = useState(false);

  const handle_input_change = (e) => {
    const { name, value } = e.target;
    set_sign_up_info({ ...sign_up_info, [name]: value });
  }

  const handle_email_login = async (e) => {
    e.preventDefault();
    try {
      set_signing_up(true);
      const res = await signup_with_email(sign_up_info);
      set_signing_up(false);
      if (res && res.token) {
        localStorage.setItem('token', res.token);
        localStorage.setItem('user', JSON.stringify(res.user));
        if (res && res.user && res.user.language) i18next.changeLanguage(res.user.language);
        console.log(res);
        return;
        window.location.href = '/';
      } else {
        if (res && res.error) {
          alert(res.error);
          return;
        }
      }

    } catch (e) {
      console.log(e);
      alert("Failed to login");
      set_signing_up(false);
    }
  }

  function getCodeFromQueryString() {
    const match = window.location.search.match(/code=([^&]+)/);
    return match ? match[1] : null;
  }

  const getFacebookLoginURL = () => {
    const FB_GRAPH_VERSION = "v15.0";
    const base_url = `https://www.facebook.com/${FB_GRAPH_VERSION}/dialog/oauth`;
    const client_id = "837281664196995";
    //http 
    const redirect_uri = "http://localhost:3000/auth/fb";
    const scope = "pages_manage_posts";

    const url = `${base_url}?client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&response_type=code`;
    return url;
  }


  const handleFacebookLogin = async () => {
    console.log(getFacebookLoginURL());
    const url = getFacebookLoginURL();
    window.location.href = url;
  }

  function LinkedInPage() {
    const { linkedInLogin } = useLinkedIn({
      clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
      redirectUri: `${window.location.origin}/linkedin/connect`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
      onSuccess: async (credentialResponse) => {
        set_display_loading("block");
        let pay_load_sign_up = credentialResponse;
        if (localStorage.getItem("referral_code")) {
          pay_load_sign_up = { ...pay_load_sign_up, referral_code: localStorage.getItem("referral_code") };
        }
        const res = await get_user_info(pay_load_sign_up);
        set_display_loading("none");
        if (res && res.token) {
          localStorage.removeItem("referral_code");
          localStorage.setItem('token', res.token);
          localStorage.setItem('user', JSON.stringify(res.user));
          if (res && res.user && res.user.language) i18next.changeLanguage(res.user.language);

          window.location.href = '/';
        } else {
          alert("Failed to login");
        }
        console.log(res);
      },
      onError: (error) => {
        console.log(error);
      },
    });

    return (
      <img
        onClick={linkedInLogin}
        src={linkedin}
        alt={t("sign_in_with_linked_in")}
        style={{ maxWidth: '180px', cursor: 'pointer' }}
      />
    );
  }

  const GoogleLoginButton = () => {
    const handleGoogleLogin = useGoogleLogin({
      // autoSelect: true,
      clientId: "691955201473-9k0kgv03ns75j0aqe5itblijmnffh1ib.apps.googleusercontent.com", // process.env.REACT_APP_GOOGLE_CLIENT_ID,
      onSuccess: async credentialResponse => {
        console.log(credentialResponse);
        set_display_loading("block");
        return;
        let pay_load_sign_up = credentialResponse;
        if (localStorage.getItem("referral_code")) {
          pay_load_sign_up = { ...pay_load_sign_up, referral_code: localStorage.getItem("referral_code") };
        }
        const res = await get_user_info(pay_load_sign_up);
        set_display_loading("none");
        if (res && res.token) {
          localStorage.removeItem("referral_code");
          localStorage.setItem('token', res.token);
          localStorage.setItem('user', JSON.stringify(res.user));
          if (res && res.user && res.user.language) i18next.changeLanguage(res.user.language);

          window.location.href = '/';
        } else {
          alert("Failed to login");
        }
        console.log(res);
      },
      // flow: 'auth-code'
    });
    return (
      // <button
      //   onClick={handleGoogleLogin}
      //   className="flex w-full border  border-gray-300 items-center justify-center gap-3 rounded-md bg-white px-3 py-1.5 text-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
      // >
      //   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4" /><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853" /><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05" /><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335" /><path d="M1 1h22v22H1z" fill="none" /></svg>
      //   <span className="text-sm font-semibold leading-6">Google</span>
      // </button>
      <GoogleOAuthProvider clientId="691955201473-9k0kgv03ns75j0aqe5itblijmnffh1ib.apps.googleusercontent.com">
        <GoogleLogin
          auto_select={true}
          onSuccess={async credentialResponse => {
            console.log(credentialResponse);
            set_display_loading("block");
            let pay_load_sign_up = credentialResponse;
            if (localStorage.getItem("referral_code")) {
              pay_load_sign_up = { ...pay_load_sign_up, referral_code: localStorage.getItem("referral_code") };
            }
            const res = await get_user_info(pay_load_sign_up);
            // return;
            set_display_loading("none");
            if (res && res.token) {
              localStorage.removeItem("referral_code");
              localStorage.setItem('token', res.token);
              localStorage.setItem('user', JSON.stringify(res.user));
              if (res && res.user && res.user.language) i18next.changeLanguage(res.user.language);

              window.location.href = '/';
            } else {
              alert("Failed to login");
            }
            console.log(res);
          }}
          onError={() => {
            console.log('Login Failed');
          }}
          useOneTap
        />
      </GoogleOAuthProvider>
    );
  }

  useEffect(() => {
    setTimeout(() => {
      set_waiting_for_google(false);
    }, 4000);
  }, []);

  return (
    <>
      <AuthLayout title={t("welcome_to_pubcm_let_ai_make_you_more_productive")}>


        <div className="mt-8">


          <div className="mt-6">
            <form action="#" method="POST" onSubmit={handle_email_login} className="space-y-6">
              <div>
                <label htmlFor="full_name" className="block text-sm font-medium text-gray-700">
                  {t("full_name")}
                </label>
                <div className="mt-1">
                  <input
                    id="full_name"
                    name="names"
                    type="text"
                    onChange={handle_input_change}
                    autoComplete="full_name"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-pubcm2 focus:outline-none focus:ring-pubcm2 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {t("email_address")}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={handle_input_change}
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-pubcm2 focus:outline-none focus:ring-pubcm2 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {t("phone_number")}
                </label>
                <div className="mt-1">
                  <input
                    id="phone_number"
                    name="phone_number"
                    type="text"
                    onChange={handle_input_change}
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-pubcm2 focus:outline-none focus:ring-pubcm2 sm:text-sm"
                  />
                </div>
              </div>

              <div className="space-y-1">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {t("password")}
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    onChange={handle_input_change}
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-pubcm2 focus:outline-none focus:ring-pubcm2 sm:text-sm"
                  />
                </div>
                {/* Add show/hide password toggle */}


              </div>

              <div>
                <button
                  type="submit"
                  disabled={signing_up}
                  className="flex w-full justify-center rounded-md border border-transparent bg-pubcm2 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-pubcm1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  {signing_up ? <span>{t("signing_up")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span> : t("sign_up")}
                </button>
              </div>
              <div className="text-sm text-right">
                <NavLink to="/auth/login" className="font-medium text-pubcm2 hover:text-pubcm1">
                  {t("login_with_email_instead")}
                </NavLink>
              </div>
            </form>
          </div>

          <div>
            <div>
              {/* Sign in with Google */}

            </div>

            <div>

            </div>

            <div className="relative mt-6">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-white px-2 text-gray-500">{t("or_continue_with")}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-4 text-sm text-slate-600'>
          {t("by_signing_in_you_agree_with_our_terms")} <a href="https://www.pubcm.com/privacy" className='text-pubcm2'>{t("here")}</a>.
        </div>

        <div className='grid grid-cols-2 my-4 space-x-2 flex'>
          <div className='flex-auto overflow-hidden'>
            <GoogleOAuthProvider clientId="691955201473-9k0kgv03ns75j0aqe5itblijmnffh1ib.apps.googleusercontent.com">
              <GoogleLoginButton clientId="691955201473-9k0kgv03ns75j0aqe5itblijmnffh1ib.apps.googleusercontent.com" />
            </GoogleOAuthProvider>

            {/* <GoogleOAuthProvider clientId="691955201473-9k0kgv03ns75j0aqe5itblijmnffh1ib.apps.googleusercontent.com">
                  <GoogleLogin
                    auto_select={true}
                    onSuccess={async credentialResponse => {
                      console.log(credentialResponse);
                      set_display_loading("block");
                      let pay_load_sign_up = credentialResponse;
                      if (localStorage.getItem("referral_code")) {
                        pay_load_sign_up = { ...pay_load_sign_up, referral_code: localStorage.getItem("referral_code") };
                      }
                      const res = await get_user_info(pay_load_sign_up);
                      // return;
                      set_display_loading("none");
                      if (res && res.token) {
                        localStorage.removeItem("referral_code");
                        localStorage.setItem('token', res.token);
                        localStorage.setItem('user', JSON.stringify(res.user));
                        if (res && res.user && res.user.language) i18next.changeLanguage(res.user.language);

                        window.location.href = '/';
                      } else {
                        alert("Failed to login");
                      }
                      console.log(res);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                    useOneTap
                  />
                </GoogleOAuthProvider> */}
          </div>

          {!waiting_for_google && <div className='flex-auto'>
            <button onClick={openLinkedinLogin} className='px-4 w-full py-2 pt-3 bg-linkedin text-white text-sm rounded-md relative'><img src={linkedinIcon} alt="Linkedin icon" className="h-6 inline absolute left-3 bottom-2" /> {t("LinkedIn")}</button>
          </div>}
        </div>
      </AuthLayout>
    </>
  )
}

import { useEffect, useState } from "react";

import DashboardLayout from "../../components/DashboardLayout";
import { delete_org_file, get_file_upload_url, get_organization_files, get_storage_usage, save_organization, save_organization_file } from "../../utils/api";

import { useNavigate, useParams } from "react-router-dom";
import { countries } from "../../utils/countries";
import { Dot } from "react-animated-dots";
import MediaFilesList from "./components/MediaFilesList";
import { ArrowUpTrayIcon, CheckCircleIcon, CheckIcon, ChevronLeftIcon, ChevronRightIcon, CloudIcon, DocumentIcon, PhotoIcon, QueueListIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import SlideOverFileEdit from "../../components/SlideOverFileEdit";
import TripRe from "../../res/img/trip-re.svg";
import Loading from "../../components/Loading";

import i18next from "../../i18next";
import Alert from "../../components/Alert";
const { t } = i18next;

let ongoing_uploads_local = [];
let files_to_upload_number = 0;
let files_uploaded_number = 0;

let _used_storage = 0;
let _used_files = 0;
let _max_storage = 0;
let _max_files = 0;

const check_svg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="w-5 text-green-500 h-5 inline"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd"></path></svg>';
export function MediaFiles(props) {
    //get org id from url params
    const { org_id, org_name } = useParams();

    const [show_file_uploader, set_show_uploader] = useState("hidden");
    const [there_is_an_upload, set_there_is_an_upload] = useState(false);
    const [change_reloader, set_change_reloader] = useState(0);
    const [usage_progress_color, set_usage_progress_color] = useState({ storage: "bg-pubcm2", files: "bg-pubcm2" });
    const [loading_files, set_loading_files] = useState(false);
    const [files_meta_obj, set_files_meta_obj] = useState({
        total: 0,
        page: 1,
        pages: 0
    });

    const [upload_error, set_upload_error] = useState({
        show: false,
        type: "warning",
        title: "",
        message: ""
    });

    const handle_close_alert = () => {
        set_upload_error({
            show: false,
            type: "warning",
            title: "",
            message: ""
        });
    }

    const [ongoing_uploads, set_ongoing_uploads] = useState([]);

    const get_upload_progress = (id, file_name, file_size) => {
        return `<div style="">
                    <div id='${id}' style="display:inline-block;width:70%;">
                            <div style="border:1px solid #e8e8e8;height:16px;">
                                <div id="${id}-progress" style="height:100%;" class="bg-pubcm2"></div>
                            </div>
                            <div style="font-size:12px; color: gray; text-align:left; padding-left: 1px; width: 100%; border: 1px solid #e8e8e8;">${file_name} | ${(file_size / 1024 / 1024).toFixed(2)} ${t("MB")}</div>
                        </div>
                        <div style="display:inline-block;width:25%;text-align:left;font-size:13px;color:#c7c7c7;">
                            <div id="${id}-text" style="text-align:left;">
                            </div>
                            <div id="${id}-check-success"  style="display:inline;width:10%;text-align:right;">
                            ...
                            </div>
                        </div>
                        
                        </div>`;


    }

    const handle_show_uploader = () => {
        if (show_file_uploader === "block") {
            set_show_uploader("hidden");
            return;
        } else {
            set_show_uploader("block");
            return;
        }
    };

    const handle_file_upload = async (e) => {
        const files_arr = Array.from(e.target.files);
        upload_files(files_arr);
    };

    const upload_files = async (files) => {
        set_there_is_an_upload(true);
        files_to_upload_number += files.length;
        // _used_files = _used_files + files.length;
        // _used_storage = _used_storage + files.reduce((a, b) => a + b.size, 0);
        if (_used_files + files.length > _max_files) {
            set_upload_error({
                show: true,
                title: "More files than allowed",
                message: "You are trying to upload more files than allowed. Please delete some files or contact us to upgrade your storage.",
                type: "error"
            });
            return;
        }

        for (let k = 0; k < files.length; k++) {
            console.log("Let's go!");
            console.log(files[k]);
            const file = files[k];

            get_file_upload_url({
                file_name: file.name,
                file_type: file.type,
                file_size: file.size,
                org_id
            }).then(async (res) => {
                if (res.signedUrl) {
                    const signedUrl = res.signedUrl;

                    let new_ongoing_upload = {
                        file_name: file.name,
                        file_type: file.type,
                        file_size: file.size,
                        file_progress: 0,
                        file_unique_name: res.fileName,
                        file_status: "uploading",
                    };

                    const ongoing_up = document.getElementById("ongoing-upload-zone");
                    ongoing_up.innerHTML += get_upload_progress(res.fileName, file.name, file.size);
                    //set progress 0 
                    let upload_progress = document.getElementById(res.fileName + "-progress");
                    upload_progress.style.width = "0%";
                    //set file name
                    let upload_file_name = document.getElementById(res.fileName + "-text");
                    upload_file_name.innerHTML = "0%";


                    const ongoing_upload_index = ongoing_uploads_local.length;
                    ongoing_uploads_local.push(new_ongoing_upload);

                    // set_ongoing_uploads([...ongoing_uploads, new_ongoing_upload]);

                    await set_ongoing_uploads(ongoing_uploads => {
                        return [...ongoing_uploads, new_ongoing_upload]
                    });

                    let timeout_uploading = null;


                    const options = {
                        headers: {
                            "Content-Type": file.type,
                        },
                        onUploadProgress: function (progressEvent) {
                            if (timeout_uploading) clearTimeout(timeout_uploading);
                            const { loaded, total } = progressEvent;
                            let percent = Math.floor((loaded * 100) / total);
                            //set progress
                            upload_progress.style.width = `${percent}%`;
                            //set file name
                            upload_file_name.innerHTML = `${percent}%`;
                        }
                    };

                    // const upload_res = await 
                    axios.put(signedUrl, file, options).then(async (upload_res) => {
                        if (upload_res.status && upload_res.status >= 200 && upload_res.status < 300) {
                            save_organization_file(org_id, {
                                file_name: file.name,
                                file_type: file.type,
                                file_size: file.size,
                                file_url: res.fileUrl,
                                file_unique_name: res.fileName
                            }).then(function (save_res) {
                                console.log("Saved file ", ongoing_upload_index, save_res);

                                document.getElementById(res.fileName + "-check-success").innerHTML = check_svg;
                                document.getElementById(res.fileName + "-progress").style.width = "100%";
                                document.getElementById(res.fileName + "-progress").style.background = "#21c55d";
                                document.getElementById(res.fileName + "-text").innerHTML = "100%";

                                ongoing_uploads_local[ongoing_upload_index].file_status = "saved";
                                ongoing_uploads_local[ongoing_upload_index].file_progress = 100;
                                set_ongoing_uploads(ongoing_uploads_local);

                                files_uploaded_number += 1;
                                set_files([save_res.file, ...file_list]);
                                set_storage_usage({
                                    used: storage_usage.used + file.size,
                                    total: storage_usage.total,
                                    files: storage_usage.files + 1,
                                    total_files: storage_usage.total_files,
                                    used_percentage: (((storage_usage.used + file.size) / storage_usage.total) * 100).toFixed(2),
                                    files_percentage: (((storage_usage.files + 1) / storage_usage.total_files) * 100).toFixed(2),

                                })

                                if (files_uploaded_number === files_to_upload_number) {
                                    set_there_is_an_upload(false);
                                }

                                console.log(ongoing_uploads_local);
                            }).catch((err) => {
                                console.log(err);
                            });
                        }
                    }).catch((err) => {
                        console.log(err);
                    });

                } else if (res.error) {
                    set_upload_error({
                        show: true,
                        title: res.error.title,
                        message: res.error.message,
                        type: res.error.type
                    });
                }
            }).catch((err) => {
                console.log(err);
                set_upload_error({
                    show: true,
                    title: "An error occured",
                    message: "An error occured while uploading your file. Please try again later. If the problem persists, please refresh the page or contact us.",
                    type: "error"
                });
            });
        }
    }

    const handle_drop_files = async (e) => {
        e.preventDefault();
        console.log(e.dataTransfer);
        const files_arr = Array.from(e.dataTransfer.files);
        console.log(files_arr);
        upload_files(files_arr);

        set_show_dragging_over("");
    };

    const [show_dragging_over, set_show_dragging_over] = useState("");

    const handle_drag_over = (e) => {
        e.preventDefault();
        console.log("Drag over");
        set_show_dragging_over("bg-slate-50");
    }

    const handle_drag_end = (e) => {
        e.preventDefault();
        console.log("Drag end");
        set_show_dragging_over("");
    }

    const [file_list, set_files] = useState([]);

    const handle_get_files = async (page = 1) => {
        try {
            set_loading_files(true);
            const files = await get_organization_files(org_id, page);
            set_loading_files(false);
            console.log(files);
            set_files(files.media_files);
            set_files_meta_obj({
                total: files.media_files_count,
                page: files.page,
                pages: files.pages
            });

        } catch (err) {
            console.log(err);
            set_loading_files(false);
        }
    }

    const [show_file_slider_over, set_show_file_slider_over] = useState(false);
    const [showing_file_details, set_showing_file_details] = useState(null);
    const [showing_file_index, set_showing_file_index] = useState(0);

    const handle_file_edit_slider_ver = (file, index) => {
        set_show_file_slider_over(!show_file_slider_over);
        set_showing_file_details(file);
        set_showing_file_index(index);
    }

    const [storage_usage, set_storage_usage] = useState({});

    const handle_get_storage_usage = async () => {
        const storage_usage = await get_storage_usage(org_id);
        console.log(storage_usage);

        const used_storage_percentage = ((storage_usage.used / storage_usage.total) * 100).toFixed(2);
        const used_files_percentage = ((storage_usage.files / storage_usage.total_files) * 100).toFixed(2);

        if (storage_usage) {
            set_storage_usage({
                used: storage_usage.used,
                files: storage_usage.files,
                total: storage_usage.total,
                total_files: storage_usage.total_files,
                used_percentage: used_storage_percentage > 100 ? 100 : used_storage_percentage,
                files_percentage: used_files_percentage > 100 ? 100 : used_files_percentage,
            });

            _used_files = storage_usage.files;
            _used_storage = storage_usage.used;
            _max_files = storage_usage.total_files;
            _max_storage = storage_usage.total;

            if (used_storage_percentage > 75 && used_storage_percentage < 95) {
                set_usage_progress_color({ storage: "bg-pubcm2", files: "bg-orange-500" });
            } else if (used_storage_percentage > 95) {
                set_usage_progress_color({ storage: "bg-pubcm2", files: "bg-red-500" });
            }

            if (used_files_percentage > 75 && used_files_percentage < 95) {
                set_usage_progress_color({ storage: "bg-orange-500", files: "bg-pubcm2" });
            } else if (used_files_percentage > 95) {
                set_usage_progress_color({ storage: "bg-red-500", files: "bg-pubcm2" });
            }

        }
    }

    const fn_update_after = (file, index) => {
        console.log("Update after", file, index);
        let new_file_list = [...file_list];
        new_file_list[index] = file;
        console.log(new_file_list);
        set_files(new_file_list);
    }

    const handle_previous_page = () => {
        const previous_page = files_meta_obj.page - 1 < 1 ? 1 : files_meta_obj.page - 1;
        handle_get_files(previous_page);
    }

    const handle_next_page = () => {
        console.log(files_meta_obj.page + 1)
        const next_page = files_meta_obj.page + 1 > files_meta_obj.pages ? files_meta_obj.pages : files_meta_obj.page + 1;
        handle_get_files(next_page);
    }

    const handle_delete_file = async (file_id, index) => {
        try {
            const delete_res = await delete_org_file(file_id);
            console.log(delete_res);
            if (delete_res.success) {
                let deleted_file = delete_res.deleted_file;

                // delete file from list using its _id and update storage usage

                let new_file_list = [...file_list];
                // find index of file to delete by its _id
                const file_index = new_file_list.findIndex((file) => file._id === deleted_file._id);
                // delete file from list
                new_file_list.splice(file_index, 1);
                // update file list
                set_files(new_file_list);

                // update storage usage

                set_storage_usage({
                    used: storage_usage.used - deleted_file.size,
                    total: storage_usage.total,
                    files: storage_usage.files - 1,
                    total_files: storage_usage.total_files,
                    used_percentage: (((storage_usage.used - deleted_file.file_size) / storage_usage.total) * 100).toFixed(2),
                    files_percentage: (((storage_usage.files - 1) / storage_usage.total_files) * 100).toFixed(2),
                });

                _used_files = _used_files - 1;
                _used_storage = _used_storage - deleted_file.file_size;

            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        handle_get_files(files_meta_obj.page);
        handle_get_storage_usage();
    }, []);


    return (
        <DashboardLayout >
            {showing_file_details && <SlideOverFileEdit fn_update_after={fn_update_after} fn_delete={handle_delete_file} file_index={showing_file_index} file={showing_file_details} show={show_file_slider_over} fn_show={set_show_file_slider_over} />}
            <div className="col-span-12 bg-white text-center px-4 md:px-8 py-2 pt-7 text-left">
                <div className="w-full md:h-10 ">

                    {(storage_usage && storage_usage.used) ? <div className="text-left md:text-left md:flex space-x-8">
                        <div className="flex-auto">
                            <div className="relative">
                                <CloudIcon className="h-5 w-5 inline relative bottom-0 text-gray-500" />
                                {t("storage")} : {(storage_usage.used / 1024 / 1024).toFixed(2)} {t("MB")} / {(storage_usage.total / 1024 / 1024).toFixed(2)} {t("MB")}
                            </div>
                            <div className="block my-1">
                                <div className=" bg-gray-200 rounded-full">
                                    <div className={`h-3 text-xs rounded-full leading-none py-1 text-center text-white ${usage_progress_color.files}`} style={{ width: storage_usage.used_percentage + "%" }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-auto">
                            <div className="relative">
                                <PhotoIcon className="h-5 w-5 inline relative bottom-0 text-gray-500" />
                                {storage_usage.files} {t("files_")} / {storage_usage.total_files} {t("files_")}
                            </div>
                            <div className="block my-1">
                                <div className=" bg-gray-200 rounded-full">
                                    <div>
                                        <div className={`h-3 text-xs rounded-full leading-none py-1 text-center text-white ${usage_progress_color.storage}`} style={{ width: storage_usage.files_percentage + "%" }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
                </div>
                {!loading_files && <div className={there_is_an_upload ? `lg:flex` : ''}>
                    <div onDrop={handle_drop_files} onDragOver={handle_drag_over} onDragLeave={handle_drag_end} className={`flex-auto ${show_dragging_over}`}>
                        <div className="col-span-full">
                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 mt-4">
                                <div className="text-center">
                                    <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                        <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                        >
                                            <span>{t("upload_a_file")}</span>
                                            {/* Accept only images file types */}
                                            <input id="file-upload" multiple={true} onChange={handle_file_upload} name="file-upload" type="file" className="sr-only" accept=".png, .jpg, .jpeg, .gif, .mp4, .mov, .webm" />
                                        </label>
                                        <p className="pl-1">{t("or_drag_and_drop")}</p>
                                    </div>
                                    <p className="text-xs leading-5 text-gray-600">{t("png_jpg_gif_up_to_100mb")}</p>
                                </div>
                            </div>
                        </div>
                    </div>



                    {there_is_an_upload && <div className="flex-auto ml-2">
                        <div className="text-center mt-4 text-gray-600 text-md">{t("ongoing_uploads")}</div>

                        <div id="ongoing-upload-zone" style={{ height: '158px', 'overflowY': 'scroll' }}>

                        </div>
                    </div>}


                </div>}

                <div className="block text-left my-2">
                    {/* <div><Alert type="warning" title="Stockage plein" message="Une erreur s'est produite"/></div> */}
                    {upload_error && upload_error.show && <div><Alert onClose={handle_close_alert} type={upload_error.type} title={upload_error.title} message={upload_error.message} /></div>}
                </div>

                {loading_files && <div className="text-center mt-4 text-gray-600 text-md py-8">
                    <div>
                        <Loading style={{ display: "inline-block" }} />
                    </div>
                    <div className="pt-6 text-xl">{t("loading_your_files")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></div>
                </div>}

                {!loading_files && file_list.length === 0 && <div className="text-center mt-4 text-gray-600 text-md">
                    <div><img src={TripRe} className="w-60 inline" /></div>
                    <div className="pt-6 text-xl">{t("no_files_uploaded_yet")}</div>
                    <div className="pt-2">{t("your_files_will_appear_here_after_upload")}</div>
                </div>}

                {!loading_files && file_list.length > 0 && <div>
                    <div className="text-3xl text-left font-bold pt-8">{t("your_files")}</div>

                    {/* Add a pagination section having current page, next page, previous page */}
                    <div className="flex">
                        <div className="text-right mr-2 text-gray-600 text-md pt-4 flex-grow">{t("page_current_of_total", { current_page: files_meta_obj.page, total_pages: files_meta_obj.pages })}</div>
                        <div className="text-right text-gray-600 text-md pt-4 flex">
                            <div className="flex-auto relative bottom-2">
                                <button onClick={handle_previous_page} className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                    <ChevronLeftIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                                    {t("previous")}
                                </button>
                            </div>
                            <div className="flex-auto relative bottom-2">
                                <button onClick={handle_next_page} className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                    {t("next")}
                                    <ChevronRightIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>





                    <div className="py-4">
                        {/* <MediaFilesList change={change_reloader} org_id={org_id} /> */}
                        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                            {file_list.map((file, index) => {
                                const is_video = file.type.includes("video");

                                return (
                                    <li key={file.source} onClick={() => handle_file_edit_slider_ver(file, index)} className="relative">
                                        <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden relative rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">

                                            {is_video ? <video controls src={file.url}></video> : <img src={file.url} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />}
                                            <button type="button" className="absolute inset-0 focus:outline-none">
                                                <span className="sr-only">{t("view_details_for_filename", { filename: file.title })}</span>
                                            </button>

                                            {!file.description && <div className="h-5 w-5 rounded-full z-5 absolute top-2 left-2 bg-red-500"></div>}
                                            {file.description && <div className="h-5 w-5 rounded-full z-5 absolute top-2 left-2 bg-green-500"></div>}

                                        </div>
                                        <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.original_name}</p>
                                        <p className="pointer-events-none block text-sm font-medium text-gray-500">{(file.size / 1024 / 1024).toFixed(2)} {t("MB")}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>}
            </div>
        </DashboardLayout>
    );
}



import { useEffect, useState } from "react";
import DashboardLayout from "../../components/DashboardLayout";

import passing_with_dog from '../../res/img/passing_with_dog.svg';
import { get_user } from "../../utils/api";

import i18next from "../../i18next";
const { t } = i18next;

export default function Waitlist(){
    let user_global_info = {};
    const [user_status, setUserStatus] = useState("");

    const user_info = async(user_id) => {
        try{
            const user = await get_user(user_id);
            if(user && user.user ){
                if(user.user && user.user.status && user.user.status === 'active'){
                    window.location.href = '/';
                }
                if(user.user && user.user.status && user.user.status === "verify_email"){
                    window.location.href = "/auth/verify/email";
                    return;
                  }
                console.log(user.user);
                setUserStatus(user.user.status);
            }
        }catch(err){
            console.log(err);
        }
    }
    
    if (!localStorage || !localStorage.getItem("token")) {
        window.location.href = "/auth/login";
    }else{
        const user_local = JSON.parse(localStorage.user);
        user_info(user_local._id);
    }
    

    return (
        <DashboardLayout>
                <div className="col-span-12 text-center">
                    <div>
                        <img className="h-40 inline" src={passing_with_dog}/>
                    </div>
                    {
                        user_status === 'wait' && <>
                            <div className="text-xl text-slate-500 text-center py-4">{t("our_bad_we_are_receiving_many_requests")}</div>
                            <div className="text-3xl text-slate-500 text-center py-4">{t("we_will_send_you_an_email")}</div>
                            <div>{t("we_are_receiving_many_requests_working_hard_to_get_you_onboard")}</div>
                            <div className="my-4 mt-8">
                                <a href="mailto:info@pubcm.com" className="py-2 px-4 rounded-md bg-gradient-to-r from-pubcm2 to-pubcm1 text-white">{t("ask_a_question")}</a>
                            </div>
                        </>
                    }

                    {
                        user_status === 'suspended' &&  <>
                            <div className="text-xl text-slate-500 text-center py-4">{t("your_account_has_been_suspended")}</div>
                            <div className="text-3xl text-slate-500 text-center py-4">{t("will_send_you_email_if_enabled")}</div>
                            <div className="my-4 mt-8">
                                <a href="mailto:info@pubcm.com" className="py-2 px-4 rounded-md bg-gradient-to-r from-pubcm2 to-pubcm1 text-white">{t("ask_a_question")}</a>
                            </div>
                        </>
                    }
                    
                </div>

        </DashboardLayout>
    );
}
import { Switch } from "@headlessui/react";
import { useState } from "react";
import i18next from "../../../i18next";
import { Square3Stack3DIcon, ViewfinderCircleIcon } from "@heroicons/react/20/solid";
import { update_branding_actions } from "../../../utils/api";
import { Link } from "react-router-dom";

const { t } = i18next;

export default function DesignPostWidget(props){
    const { org_id, org_name, use_ai_img, add_text_img, has_logo } = props;

    const [branding_actions, set_branding_actions] = useState({
        use_ai_img: use_ai_img,
        add_text_img: add_text_img
    });

    const handle_update_branding_actions = (e) => {
        const { name } = e.target;
        const new_branding_actions = {
            ...branding_actions,
            [name]: !branding_actions[name]
        };

        set_branding_actions(new_branding_actions);

        try{
            update_branding_actions(org_id, new_branding_actions);
        }catch(err){
            console.log(err);
        }
    }

    return <div className="mx-auto max-w-lg text-slate-400 text-center">
        <ul className="text-left space-y-1 divide-y text-slate-700">
            <li className="py-2 px-2">
                <ViewfinderCircleIcon className="h-5 inline mx-2"/>  {t("use_ai_images_on_posts")}
                <ToggleDesign enabled={branding_actions.use_ai_img} on_change={handle_update_branding_actions} name="use_ai_img"/>
            </li>
            <li className="py-2 px-2" disabled={has_logo}>
                <Square3Stack3DIcon className="h-5 inline mx-2"/> {t("add_text_to_images")}
                {has_logo && <ToggleDesign enabled={branding_actions.add_text_img} on_change={handle_update_branding_actions}  name="add_text_img" />}
            </li>
            {!has_logo && <Link to={`/manage/org/branding/${org_id}/${org_name}`}><li className="text-center text-orange-600 py-2 cursor-pointer">
                <div className="text-sm">
                    Veuillez d'abord ajouter votre logo pour ajouter du texte à vos images
                </div>
                <div className="font-bold text-orange-600 py-1">Add logo</div>
            </li>
                </Link>}
        </ul>
    </div>; 
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

function ToggleDesign(props) {
    const [enabled, setEnabled] = useState(props.enabled);
  
    const handleChange = async (e) => {
        setEnabled(e);
        props.on_change({
            target: {
                name: props.name
            },
            value: e        
        });
    }
  
    return (
      <Switch
        checked={enabled}
        onChange={handleChange}
        className={classNames(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative z-300 inline-flex h-6 float-right w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    )
  }
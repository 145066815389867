import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import FilesWidget from "./FilesWidget";
import OrganizationAdmins from "./OrganizationAdmins";
import SelectOrganization from "./SelectOrganization";

import i18next from "../../../i18next";

const { t } = i18next;

export default function LeftNavigation(props) {
    console.log(props.user_org_obj);
    console.log(props);
    const { state, current_org, fn_change_org } = props;
    return <div >
            <div className="bg-white px-1 pb-2 mb-4 py-2 lg:px-2 relative">
            <SelectOrganization orgs={state.user_orgs} fn_change_org={fn_change_org} selected_org={state.selectedOrganisation} />
            <div className='text-xs text-slate-700 my-4'>
                <table>
                    <tbody>
                        {current_org.phone && <tr><td><small>{t("phone")}</small></td><td>{current_org.phone}</td></tr>}
                        {current_org.email && <tr><td><small>{t("email")}</small></td><td>{current_org.email}</td></tr>}
                        {current_org.website && <tr><td><small>{t("website")}</small></td><td>{current_org.website}</td></tr>}
                    </tbody>
                </table>
            </div>
            <div>
                {current_org.tags && current_org.tags.split(",").map((tag, i) => {
                    return tag ? <span key={`tag-${tag}-${i}`} className='inline-block mx-1 my-1 bg-slate-200 rounded-md text-xs px-2 py-1'>{tag}</span> : '';
                })}
            </div>


            <div className='text-xs text-slate-700 text-justify my-4'>{current_org.description}</div>

            <div className='text-md text-bold mt-3'>
                <Link className="right-6 text-slate-600 bg-slate-100 hover:bg-sky-200 px-2 py-2 rounded-md text-xs" to={`/manage/org/${current_org._id}`}> <Cog6ToothIcon className="h-5 inline" /> <span className="">{t("edit_manage")}</span></Link>
            </div>
        </div>

        <div>
            <div className="bg-white px-2 py-2 my-4 mt-2 relative rounded-md">
                <OrganizationAdmins fn_state={fn_change_org} selected_org={props.selected_org} org={current_org} admins={props.user_org_obj && props.user_org_obj.admins}/>
            </div>
        </div>

        <div>
            <div className="bg-white px-2 py-2 my-4 mt-2 relative rounded-md">
                <FilesWidget />
            </div>
        </div>
    </div>;
}
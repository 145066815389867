import i18next from "../../../../i18next";
const { t } = i18next;

export default function PostingLanguage(props){
    const updatePreferedLanguage = (e) => {
        console.log(e.target.value);
        props.update_fn({
            ...props.state,
            prefered_language : e.target.value
        })
    }

    const langs =  [
          t("english"),
          t("spanish"),
          t("french"),
          t("german"),
          t("italian"),
          t("portuguese"),
          t("dutch"),
          t("russian"),
          t("chinese_simplified"),
          t("japanese"),
          t("korean"),
          t("kiswahili")
        ];

    return (
        <div>
            <div className="py-2">{t("select_the_prefered_language_for_your_posts")}</div>
            {/** Get list of languages */}
            <select onChange={updatePreferedLanguage} className="w-full rounded-md border-gray-300 focus:border-pubcm2 focus:accent-pubcm2  focus:ring-pubcm2">
                {
                    langs.map(lang => <option value={lang.toLowerCase()}>{lang}</option>)
                }

            </select>
        </div>
    )
}
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BuildingOfficeIcon, CheckIcon, ChevronRightIcon, UserIcon } from '@heroicons/react/24/outline'
import LinkedinImgIcon from "../../../res/img/comm_channels/linkedin.png";
import { get_linked_accounts } from '../../../utils/api';

import i18next from '../../../i18next';
const { t } = i18next;

export default function SelectLinkedinType(props) {
    //const [open, setOpen] = useState(true);
    const [accounts, set_accounts] = useState([]);
    // const { org_id } = props;
    console.log(props.org);

    const handle_get_account = async (type) => {
        const res = await get_linked_accounts(props.org._id);
        console.log(res);
        set_accounts(res.accounts);
    }

    useEffect(() => {
        handle_get_account();
    }, []);

    return (
        <Transition.Root show={props.state.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={props.fn_state}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-sky-100">
                                        <img src={LinkedinImgIcon} className="h-6 w-6" alt="LinkedIn icon"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base  leading-6 text-gray-900">
                                            {t("select_the_type_of_account_you_want")}
                                        </Dialog.Title>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    {/* <ul role="list" className="divide-y divide-gray-200">
                                        {
                                            accounts.map((account, index) => {
                                                return (
                                                    <li key={account._id} className="flex py-4 hover:bg-slate-50 cursor-pointer">
                                                        <BuildingOfficeIcon className='h-10 w-10 rounded-full flex-auto text-gray-400'/>
                                                        <div className="ml-3 flex-grow">
                                                            <p className="text-sm font-medium text-gray-900">{account.account_info.localizedFirstName + ' ' + account.account_info.localizedLastName} </p>
                                                            <p className="text-sm text-gray-500">My orgs on my account</p>
                                                        </div>
                                                        <ChevronRightIcon className='h-5 w-5 text-gray-400 flex-auto'/>
                                                    </li>
                                                )
                                            })
                                        }
                                        <li onClick={props.actions.organization} className="flex py-4 hover:bg-slate-50 cursor-pointer">
                                            <BuildingOfficeIcon className='h-10 w-10 rounded-full flex-auto text-gray-400'/>
                                            <div className="ml-3 flex-grow">
                                                <p className="text-sm font-medium text-gray-900">Organization account </p>
                                                <p className="text-sm text-gray-500">Your organization account</p>
                                            </div>
                                            <ChevronRightIcon className='h-5 w-5 text-gray-400 flex-auto'/>
                                        </li>
                                        <li onClick={props.actions.personal} className="flex py-4 hover:bg-slate-50 cursor-pointer">
                                            <UserIcon className='h-10 w-10 rounded-full flex-auto text-gray-400'/>
                                            <div className="ml-3 flex-grow">
                                                <p className="text-sm font-medium text-gray-900">Personal account</p>
                                                <p className="text-sm text-gray-500">Your personal account</p>
                                            </div>
                                            <ChevronRightIcon className='h-5 w-5 text-gray-400 flex-auto'/>
                                        </li>
                                    </ul> */}

                                    {/* <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setOpen(false)}
                  >
                    Go back to dashboard
                  </button> */}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

import DashboardLayout from "../../components/DashboardLayout";

import { CheckIcon, ClockIcon } from '@heroicons/react/24/outline'
import { Link, useParams } from "react-router-dom";
import { get_organization_details, get_stripe_session_url } from "../../utils/api";
import { useEffect, useState } from "react";
import human from "human-time";
import failed_pyt from "../../res/img/fall_coming.svg";
import winners from "../../res/img/winners.svg";

import i18next from "../../i18next";
const { t } = i18next;

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function AfterPay(props) {
    const { org_id, org_name } = useParams();
    //get stripe status query param
    const urlParams = new URLSearchParams(window.location.search);
    const cancelled = urlParams.get('cancelled');
    const success = urlParams.get('success');
    const failed = urlParams.get('failed');    

    return (
        <DashboardLayout>
            <div className="mx-auto max-w-7xl bg-white col-span-12 pt-8 pb-24 px-6 lg:px-8">
                <div className="text-center my-2 mb-6">
                    {success && <img className="w-60 inline-block" src={winners}/>}
                    {cancelled && <img className="w-60 inline-block" src={failed_pyt}/>}
                    {failed && <img className="w-60 inline-block" src={failed_pyt}/>}
                </div>
                <div className="flex flex-col lg:flex-row">
                    <div className="flex-1">
                        {success && <h1 className="text-2xl font-bold text-gray-900">{t("thank_you_for_your_purchase")}</h1>}
                        {cancelled && <h1 className="text-2xl font-bold text-gray-900">{t("your_pyt_was_cancelled")}</h1>}
                        {failed && <h1 className="text-2xl font-bold text-gray-900">{t("your_pyt_failed")}</h1>}

                        {success && <p className="mt-1 text-sm text-gray-500">{t("your_pyt_has_been_successfully_processed")}</p>}
                        {cancelled && <p className="mt-1 text-sm text-gray-500">{t("you_can_try_again_later")}</p>}
                        {failed && <p className="mt-1 text-sm text-gray-500">{t("you_can_try_again_later")}</p>}
                    </div>
                    <div className="mt-6 flex lg:mt-0 lg:ml-4">
                        <span className="sm:ml-3">
                            {success && <Link to={`/`}>
                                <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500">
                                    {t("go_to_dashboard")}
                                </button>
                            </Link>}
                            {cancelled && <Link to={`/pay/${org_id}/${org_name}`}>
                                <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500">
                                    {t("try_again")}
                                </button>
                            </Link>}
                            {failed && <Link to={`/pay/${org_id}/${org_name}`}>
                                <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500">
                                {t("try_again")}
                                </button>
                            </Link>}
                        </span>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

import facebook_icon from '../../res/img/comm_channels/facebook.png'
import { save_facebook_page } from '../../utils/api';

import i18next from '../../i18next';
const { t } = i18next;

export default function FacebookPageModal(props) {
  const [open, setOpen] = useState(props.open);

  const cancelButtonRef = useRef(null);
  let selected_page = null;

  const handleSelect = (item) => {
    selected_page = item;
  }

  const handleSave = async () => {
    if(selected_page){
      const channel = {
        channel_id: props.channel_id,
        facebook_page: selected_page
      }

      console.log(channel);
      const res = await save_facebook_page(channel);
      window.location.href="/";
      //props.setOpen(false);
    }else{
      alert(t("please_select_a_page"));
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-left justify-left rounded-full bg-green-100">
                    <img src={facebook_icon} className="rounded-3xl" alt="Facebook icon"/>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-left">
                      {t("please_select_the_pages_to_manage")}
                    </Dialog.Title>
                    <div className="mt-2">
                      {/* Generate a checklist of item */}
                        <div className="mt-2">
                            {
                                props.list.map((item, index) => {
                                    return <div className="flex items-center">
                                                <input
                                                    id="comments"
                                                    name="comments"
                                                    type="radio"
                                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                                    onClick={() => handleSelect(item)}
                                                />
                                                <label htmlFor="comments" className="ml-2 block text-gray-900">
                                                    {item.name} <span className='text-xs'>( {item.category} )</span>
                                                </label>
                                            </div>
                                })
                            }
                            
                            
                        </div>
                        

                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                    onClick={handleSave }
                  >
                    {t("select")}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    {t("close")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import { useState } from "react";

import DashboardLayout from "../../components/DashboardLayout";
import { get_website_info, save_organization } from "../../utils/api";

import { useNavigate } from "react-router-dom";
import { countries } from "../../utils/countries";
import { Dot } from "react-animated-dots";

import i18next from "../../i18next";
// import OnboardingSteps from "./components/OnboardingSteps";
import './style.css';
import Loading from "../../components/Loading";

const { t } = i18next;

export function OnboardingOrg() {
    const [show_new_org_bref, set_show_new_org_bref] = useState(true);
    const [does_nt_have_website_check, set_does_nt_have_website_check] = useState(false);
    const [organization_form_info, set_form_info] = useState({
        org_name: '',
        org_website: ''
    });
    const [show_loading_org_info_webiste, set_show_loading_org_info_webiste] = useState(false);
    // const [organization_website, set_organization_website] = useState('');



    const handleInputChange = (e) => {
        set_form_info({
            ...organization_form_info,
            [e.target.name]: e.target.value
        })
    }

    const handleFormSubmit = async (e) => {
        try{
            e.preventDefault();
            if (does_nt_have_website_check) {
                set_show_new_org_bref(false);
            } else {

                set_show_new_org_bref(false);
                set_show_loading_org_info_webiste(true);
                const resp = await get_website_info({ url: organization_form_info.org_website, name : organization_form_info.org_name });
                set_show_loading_org_info_webiste(false);
                console.log(resp);
                if(resp && resp.response === "ok"){
                    if(resp.result){
                        console.log(organization_form_info);
                        set_form_info({
                            ...organization_form_info,
                            country : resp.result.country,
                            description : resp.result.description, 
                            email : resp.result.email, 
                            phone : resp.result.phone,
                            tags : resp.result.tags
                        });
                    }
                }
                // alert("We have a website");
            }
        }catch(ex){
            set_show_loading_org_info_webiste(false);
        }
    }

    const update_has_website = (e) => {
        console.log(e.target.checked);
        set_does_nt_have_website_check(e.target.checked);
    }

    return (
        <DashboardLayout>
            <div className="col-span-12 text-left bg-white py-8 mx-4 px-4">
                <h1 className="text-xl lg:text-4xl font-medium">{t("new_organization_or_entity")}</h1>
                <p className="text-sm text-slate-600 mb-6">{t("add_your_organization_details")}</p>

                {/* This is the first step form */}

                <div>
                    {(show_new_org_bref && !show_loading_org_info_webiste) && <div>
                        <form onSubmit={handleFormSubmit}>
                            <div className="lg:grid lg:grid-cols-12 gap-2">
                                <div className="col-span-2 flex items-end">{t("organization_name")}</div>
                                <div className="col-span-10 text-left">
                                    <input
                                        type="text"
                                        onChange={handleInputChange}
                                        value={organization_form_info.name}
                                        name="org_name"
                                        required
                                        placeholder={t('enter_org_name')}
                                        className=" text-xl lg:text-4xl placeholder-gray-300 text-slate-800 border border-0 border-pubcm1 border-b-2 w-full focus:ring-0 focus:border-pubcm1 focus:border-b-4"
                                    />
                                </div>
                            </div>
                            <div className="lg:grid lg:grid-cols-12 gap-2 my-4">
                                <div className="col-span-2 flex items-end">{t("enter_your_website")}</div>
                                <div className="col-span-10 text-left">
                                    <input
                                        type="text"
                                        onChange={handleInputChange}
                                        name="org_website"
                                        required={!does_nt_have_website_check}
                                        value={organization_form_info.website} placeholder="https://" className=" text-xl lg:text-4xl placeholder-gray-300 text-slate-800 border border-0 border-pubcm1 border-b-2 w-full focus:ring-0 focus:border-pubcm1 focus:border-b-4" />
                                </div>
                            </div>
                            <div>

                                <div className="relative flex items-start  text-xl my-10">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="org_without_org"
                                            aria-describedby="org_without_org_desc"
                                            name="org_without_org"
                                            type="checkbox"
                                            onChange={update_has_website}
                                            className="h-4 w-4 rounded border-gray-300 text-pubcm1 focus:ring-pubcm1"
                                        />
                                    </div>
                                    <div className="ml-3 leading-6">
                                        <label htmlFor="comments" className="font-medium text-gray-900">
                                            {t("my_org_does_not_hav_website")}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <button type="submit" className="bg-pubcm1 hover:bg-pubcm2 text-white px-4 py-2 rounded-md">Next</button>
                            </div>
                        </form>
                    </div>}

                    {(!show_loading_org_info_webiste && !show_new_org_bref) && <div>
                        <Form input={organization_form_info}/>
                    </div>}

                    {show_loading_org_info_webiste && <div className="text-center py-20">
                        <Loading style={{ display: "inline-block" }} />
                        <div className="my-2">
                            <div className="text-xl">Getting your organization info from your website ...</div>
                            <div>Please wait.</div>
                        </div>
                    </div>}
                </div>
            </div>
        </DashboardLayout>
    );
}


function Form(props) {
    const input_vals = props.input;
    // console.log(input_vals);
    const [name, setName] = useState(input_vals.org_name);
    const [description, setDescription] = useState(input_vals.description);
    const [website, setWebsite] = useState(input_vals.org_website);
    const [email, setEmail] = useState(input_vals.email);
    const [phone, setPhone] = useState(input_vals.phone);
    const [tags, setTags] = useState(input_vals.tags);
    const [country, setCountry] = useState(input_vals.country);
    const [show_saving, set_show_saving] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            set_show_saving(true);
            const save_ = await save_organization({ name, description, website, email, phone, country, tags });
            set_show_saving(false);
            console.log(save_);
            if (save_ && save_.admin) {
                localStorage.setItem("selected_org_id", save_.organization._id);
                navigate(`/schedule/setup/${save_.organization._id}/${save_.organization.name}`);
            }
        } catch (ex) {
            set_show_saving(false);
            console.log(ex);
        }

        //console.log(name, description, website, email, phone, country);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "name":
                setName(value);
                break;
            case "description":
                setDescription(value);
                break;
            case "website":
                setWebsite(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "phone":
                setPhone(value);
                break;
            case "country":
                setCountry(value);
                break;
            case "tags":
                setTags(value);
                break;
            default:
                break;
        }
    }


    return (
        <>
            <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                    <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {t("organization_name")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    name="name"
                                    value={name}
                                    required
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {t("description")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <textarea
                                    type="text"
                                    name="description"
                                    value={description}
                                    onChange={handleChange}
                                    required
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                ></textarea>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {t("tags")}<div><small>({t("comma_separated")})</small></div>
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    name="tags"
                                    value={tags}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <div className="text-left text-xs text-slate-600 mt-1 sm:text-center">{t("eg_peace_tech_my_company_usa")}</div>
                            </div>
                        </div>

                        

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {t("email")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    name="email"
                                    value={email}
                                    onChange={handleChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {t("phone_number")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    name="phone"
                                    value={phone}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>   


                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="region" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {t("country")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <select
                                    id="region"
                                    name="country"
                                    onChange={handleChange}
                                    value={country}
                                    required
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                >
                                    {countries.map(country => <option key={`${country}-onboarding`} value={country}>{country}</option>)}
                                </select>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {t("website")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    name="website"
                                    value={website}
                                    onChange={handleChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>


                        <div>
                            <label htmlFor="region" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">

                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0 text-center">
                                <button disabled={show_saving} type="submit" className=" px-4 py-2 bg-pubcm1 hover:bg-pubcm2 text-white rounded-md">
                                    {show_saving ? <span>{t("saving_organization")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span> : t("save")}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

import human from "human-time";
import { useEffect, useState } from "react";
import DashboardLayout from "../../components/DashboardLayout";

import passing_with_dog from '../../res/img/passing_with_dog.svg';
import { activate_plan, check_user_connection, get_a_user_orgs, get_all_users, get_price_list, get_user, update_user } from "../../utils/api";
import AdminLayout from "./AdminLayout";
import { useParams } from "react-router-dom";

import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import Loading from "../../components/Loading";
import { Dot } from "react-animated-dots";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const people = [
    {
        name: 'Lindsay Walton',
        title: 'Front-end Developer',
        department: 'Optimization',
        email: 'lindsay.walton@example.com',
        role: 'Member',
        image:
            'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
]

export default function Organizations() {
    const { user_id } = useParams();


    const [orgs, set_orgs] = useState({
    });


    const [org_id, set_org_id] = useState(null);
    const [org_name, set_org_name] = useState("");
    const [loading_user_orgs, set_loading_user_orgs] = useState(false);

    const get_sys_user_orgs = async () => {
        try {
            set_loading_user_orgs(true);
            const res = await get_a_user_orgs(user_id);
            set_orgs(res);
            set_loading_user_orgs(false);
        } catch (err) {
            set_loading_user_orgs(false);
            alert(JSON.stringify(err));
        }
    }


    const [show_modal, set_show_modal] = useState(false);

    const activate_user_subscription = async (org_id, org_name) => {
        try {
            set_org_id(org_id);
            set_org_name(org_name);
            set_show_modal(true);
        } catch (err) {
            alert(JSON.stringify(err));
        }
    }

    // 

    useEffect(() => {
        get_sys_user_orgs();
    }, []);


    return (
        <AdminLayout>
            {loading_user_orgs ?
                <div className="col-span-12  text-center w-full bg-white p-10">
                    <Loading style={{ display: "inline" }} className="inline" />
                    <div className="text-xl">Loading user organizations <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></div>
                </div> :
                <div className="col-span-12 bg-white py-2">
                    <ChoosePlanPricing user_id={user_id} org_name={org_name} display={show_modal} org_id={org_id} fn_show_modal={set_show_modal} />
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-xl font-semibold text-gray-900">Organizations</h1>
                                {/* <div>{user_id}</div> */}
                                <p className="mt-2 text-sm text-gray-700">
                                    The list of a users organizations
                                </p>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                {/* <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Add user
                            </button> */}
                            </div>
                        </div>
                        <div className="mt-8 flex flex-col">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Organization
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Subscription
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Schedule
                                                    </th>
                                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {orgs && orgs.orgs && orgs.orgs.length > 0 && orgs.orgs.map(({ org, payement, schedule }) => (
                                                    <tr key={org._id}>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                            <div className="flex items-center">
                                                                <div className="ml-4">
                                                                    <div className="font-medium text-gray-900">{org.name}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td className="whitespace-nowrap py-4 px-3 text-sm">
                                                            <div className="text-gray-900">{payement && payement.status}</div>
                                                            <div className="text-gray-500">{payement && payement.expirationDate}</div>
                                                            <div className="text-gray-500 text-xs overflow-scroll">{payement && payement.title}</div>
                                                        </td>

                                                        <td className="whitespace-nowrap py-4 px-3 text-sm">
                                                            <div className="text-gray-500">{schedule && schedule.number_of_posts} per {schedule && schedule.period}</div>
                                                        </td>

                                                        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium">
                                                            <button onClick={() => activate_user_subscription(org._id, org.name)} type="button" className="hover:bg-blue-200 bg-blue-100 text-blue-800 px-4 py-2 rounded">
                                                                Activate subscription
                                                            </button>
                                                        </td>
                                                    </tr>

                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </AdminLayout>
    )
}


function ChoosePlanPricing(props) {
    const { display, fn_show_modal, org_id, org_name, user_id } = props;
    // const [open, setOpen] = useState(display)

    const cancelButtonRef = useRef(null);

    const [selected_plan, set_selected_plan] = useState(null);
    const [selected_variant, set_selected_variant] = useState(null);
    const [next_pyt_date, set_next_pyt_date] = useState(null);

    const [plan_obj, set_plan_obj] = useState({
        plan: null,
        plan_obj: null,
        variant: "",
        variant_obj: null,
        next_pyt_date: null
    });

    const handle_change_price_plan = (e) => {
        set_plan_obj({
            plan: e.target.value,
            plan_obj: price_list[e.target.value],
            variant: "-",
            next_pyt_date: null
        });

        // alert(price_list[selected_plan].variants);
    }

    const [activating_subscription, set_activating_subscription] = useState(false);
    const [membership_activated, set_membership_activated] = useState(false);

    const handle_activate_subscription = async () => {
        try {
            if (!plan_obj.plan) {
                alert("Please select a plan");
                return;
            }
            if (!plan_obj.variant) {
                alert("Please select a period type");
                return;
            }
            if (plan_obj && plan_obj.next_pyt_date) {
                set_activating_subscription(true);
                console.log("Activating subscription");
                const res = await activate_plan({
                    ...plan_obj,
                    org_id,
                    org_name,
                    user_id
                });
                console.log("Activated subscription");
                console.log(res);
                if(res){
                    set_membership_activated(true);
                    set_activating_subscription(false);
                }
                setTimeout(() => {
                    set_membership_activated(false);
                },2000);
                // fn_show_modal(false);
            } else {
                alert("Please specify next payment date");
            }
        } catch (err) {
            // alert(JSON.stringify(err));
            console.log(err);
            set_activating_subscription(false);
        }
    }

    const handle_change_period = (e) => {
        let selected_val = e.target.value;
        let next_pyt_date = null;
        if (selected_val === "specify") {
        } else if (selected_val === "-") {
            return;
        } else {
            const variant = price_list[plan_obj.plan].variants.filter(v => v.id === e.target.value)[0];
            const interval = variant.attributes.interval;
            const interval_count = variant.attributes.interval_count;
            next_pyt_date = next_payment_date_time(interval_count, interval);

            console.log(next_pyt_date);
        }

        set_plan_obj({
            ...plan_obj,
            variant: e.target.value,
            variant_obj: price_list[plan_obj.plan].variants.filter(v => v.id === e.target.value)[0],
            next_pyt_date: next_pyt_date
        })
    }

    const handle_specify_date = (e) => {
        set_plan_obj({
            ...plan_obj,
            next_pyt_date: e.target.value
        })
    }

    const [price_list, set_price_list] = useState([]);
    // const [next_pyt_date, set_next_pyt_date] = useState(next_payment_date_time(1, "month"));
    const [loading_plans, set_loading_plans] = useState(false);
    const _getting_price_list = async (org_id) => {
        try {
            if (!org_id) return;
            set_loading_plans(true);
            const res = await get_price_list(org_id);
            set_loading_plans(false);
            console.log(res);
            if (res && res.price_list) {
                set_price_list(res.price_list);
            }
        } catch (err) {
            set_loading_plans(false);
            console.log(err);
        }
    }

    const next_payment_date_time = (interval_count, interval) => {
        const currentDate = new Date();

        switch (interval.toLowerCase()) {
            case "day":
                currentDate.setDate(currentDate.getDate() + interval_count);
                break;
            case "week":
                currentDate.setDate(currentDate.getDate() + interval_count * 7);
                break;
            case "month":
                currentDate.setMonth(currentDate.getMonth() + interval_count);
                break;
            case "year":
                currentDate.setFullYear(currentDate.getFullYear() + interval_count);
                break;
            default:
                throw new Error("Invalid interval type");
        }

        return currentDate;
    };

    useEffect(() => {
        _getting_price_list(org_id);
    }, [org_id]);

    return (
        <Transition.Root show={display} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={fn_show_modal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                {loading_plans ?
                                    <div className="col-span-12  text-center w-full bg-white p-10">
                                        <Loading style={{ display: "inline" }} className="inline" />
                                        <div className="text-xl">Loading subscription plans <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></div>
                                    </div> : <div>
                                        <div>

                                            <div className="mt-3 text-center sm:mt-5 mb-3">
                                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                    Plans
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        Choose the plan that the <b>{org_name}</b>  purchased and activate it.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div>
                                            {/* create a two columns grid */}
                                            <table className="space-y-2 w-full">
                                                <tbody>
                                                    <tr>
                                                        <td>Plan</td>
                                                        <td>
                                                            <select onChange={handle_change_price_plan} className="w-full border border-slate-300 rounded-md text-slate-700">
                                                                <option value="-">Choose</option>
                                                                {
                                                                    price_list && price_list.length > 0 && price_list.map((plan, i) => {
                                                                        return <option key={plan.id} value={i}>{plan.attributes.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Period</td>
                                                        <td>
                                                            <select onChange={handle_change_period} value={plan_obj.variant} className="w-full border border-slate-300 rounded-md text-slate-700">
                                                                <option value="-">Choose</option>
                                                                {
                                                                    //    display only variants of selected index plan  

                                                                    plan_obj && plan_obj.plan && price_list && price_list.length > 0 && price_list[plan_obj.plan] && price_list[plan_obj.plan].variants && price_list[plan_obj.plan].variants.map((variant) => {
                                                                        return variant.attributes.status && variant.attributes.status === "published" ? <option value={variant.id}>{variant.attributes.name} </option> : <></>
                                                                    })
                                                                }
                                                                <option value="specify">Specify</option>
                                                            </select>
                                                            {/* <div>{JSON.stringify()}</div> */}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Next payment</td>
                                                        <td>
                                                            {plan_obj && plan_obj.variant && plan_obj.variant === "specify" ?
                                                                <input type="datetime-local" onChange={handle_specify_date} className="w-full border border-slate-300 rounded-md text-slate-700" /> :
                                                                <div className="py-3 text-md text-slate-600">{plan_obj.next_pyt_date && plan_obj.next_pyt_date.toLocaleString()}</div>}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Force payement</td>
                                                        <td>
                                                            <input type="checkbox" className="border border-slate-300 rounded-md text-slate-700" />
                                                        </td>
                                                    </tr>



                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                            <button disabled={activating_subscription} type="button" onClick={handle_activate_subscription} className="hover:bg-blue-200 bg-blue-100 text-blue-800 px-4 py-2 rounded m-1">
                                                                {activating_subscription ? "Activating..." : "Activate"}
                                                            </button>
                                                            {membership_activated ? <div className="text-green-500"> <CheckCircleIcon className="h-5 inline"/> Membership was actived</div> : ""}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

import { useEffect, useState } from "react";
import { Dot } from "react-animated-dots";
import DashboardLayout from "../../components/DashboardLayout";
import { get_linkedin_token, save_linkedin_org_channel } from "../../utils/api";
import LinkedinImgIcon from "../../res/img/comm_channels/linkedin.png";
import i18next from "../../i18next";
const { t } = i18next;

let is_loading_token = false;
let meta_data = null;
export default function AfterSocialLinkedIn() {
    //get code and state from url
    const url = window.location.href;
    const code = url.match(/code=([^&]+)/)[1];
    const state = url.match(/state=([^&]+)/)[1];

    const [orgs, set_orgs] = useState([]);

    const state_json = JSON.parse(atob(decodeURIComponent(state)));

    const handleSelectOrg = (org_channel) => {
        // console.log(org);
        // alert(JSON.stringify(org));
        save_linkedin_org_channel(state_json.org, {
            meta : meta_data,
            channel : org_channel
        });

        window.location.href = "/";
    }

    const getLinkedinToken = async () => {
        try {
            if (is_loading_token) return;
            //Convert state from base64
            //convert state from URL encoded and then from base64



            if (state_json.type && state_json.type === "organization") {
                console.log(state_json);
                console.log(code);
                is_loading_token = true;
                
                const resp1 = {
                    "organizations": {
                        "elements": [
                            {
                                "organizationalTarget~": {
                                    "vanityName": "pubcm",
                                    "localizedName": "PubCM",
                                    "name": {
                                        "localized": {
                                            "en_US": "PubCM"
                                        },
                                        "preferredLocale": {
                                            "country": "US",
                                            "language": "en"
                                        }
                                    },
                                    "primaryOrganizationType": "NONE",
                                    "locations": [],
                                    "id": 89048239,
                                    "localizedWebsite": "https://www.pubcm.com",
                                    "logoV2": {
                                        "cropped": "urn:li:digitalmediaAsset:C4D0BAQF_NFcFRmn54w",
                                        "cropInfo": {
                                            "width": 0,
                                            "x": 0,
                                            "y": 0,
                                            "height": 0
                                        },
                                        "original": "urn:li:digitalmediaAsset:C4D0BAQF_NFcFRmn54w"
                                    }
                                },
                                "organizationalTarget": "urn:li:organization:89048239"
                            },
                            {
                                "organizationalTarget~": {
                                    "vanityName": "imcon-international-inc",
                                    "localizedName": "Imcon International Inc.",
                                    "name": {
                                        "localized": {
                                            "en_US": "Imcon International Inc."
                                        },
                                        "preferredLocale": {
                                            "country": "US",
                                            "language": "en"
                                        }
                                    },
                                    "primaryOrganizationType": "NONE",
                                    "locations": [
                                        {
                                            "locationType": "HEADQUARTERS",
                                            "address": {
                                                "country": "US",
                                                "geographicArea": "Georgia",
                                                "city": "Marietta",
                                                "line1": "2874 Johnson Ferry Rd NE"
                                            },
                                            "streetAddressFieldState": "UNSET_OPT_OUT",
                                            "geoLocation": "urn:li:geo:102746040",
                                            "staffCountRange": "SIZE_1"
                                        }
                                    ],
                                    "id": 28992770,
                                    "localizedWebsite": "http://www.imconintl.com",
                                    "logoV2": {
                                        "cropped": "urn:li:digitalmediaAsset:C4D0BAQEYsXZyjLnRvA",
                                        "cropInfo": {
                                            "width": 0,
                                            "x": 0,
                                            "y": 0,
                                            "height": 0
                                        },
                                        "original": "urn:li:digitalmediaAsset:C4D0BAQEYsXZyjLnRvA"
                                    }
                                },
                                "organizationalTarget": "urn:li:organization:28992770"
                            },
                            {
                                "organizationalTarget~": {
                                    "vanityName": "trotoapp",
                                    "localizedName": "Troto",
                                    "name": {
                                        "localized": {
                                            "en_US": "Troto"
                                        },
                                        "preferredLocale": {
                                            "country": "US",
                                            "language": "en"
                                        }
                                    },
                                    "primaryOrganizationType": "NONE",
                                    "locations": [],
                                    "id": 67752123,
                                    "localizedWebsite": "https://troto.co"
                                },
                                "organizationalTarget": "urn:li:organization:67752123"
                            },
                            {
                                "organizationalTarget~": {
                                    "vanityName": "nfinic",
                                    "localizedName": "nfinic",
                                    "name": {
                                        "localized": {
                                            "en_US": "nfinic"
                                        },
                                        "preferredLocale": {
                                            "country": "US",
                                            "language": "en"
                                        }
                                    },
                                    "primaryOrganizationType": "NONE",
                                    "locations": [
                                        {
                                            "description": {
                                                "localized": {
                                                    "en_US": "Headquarters"
                                                },
                                                "preferredLocale": {
                                                    "country": "US",
                                                    "language": "en"
                                                }
                                            },
                                            "locationType": "HEADQUARTERS",
                                            "address": {
                                                "city": "Goma",
                                                "country": "CD",
                                                "geographicArea": "Nord-Kivu"
                                            },
                                            "localizedDescription": "Headquarters",
                                            "streetAddressFieldState": "OPT_OUT",
                                            "geoLocation": "urn:li:geo:106719817"
                                        }
                                    ],
                                    "id": 43338422,
                                    "localizedWebsite": "https://www.nfinic.com",
                                    "logoV2": {
                                        "cropped": "urn:li:digitalmediaAsset:C4D0BAQHygXWN9qatJQ",
                                        "cropInfo": {
                                            "width": 0,
                                            "x": 0,
                                            "y": 0,
                                            "height": 0
                                        },
                                        "original": "urn:li:digitalmediaAsset:C4D0BAQHygXWN9qatJQ"
                                    }
                                },
                                "organizationalTarget": "urn:li:organization:43338422"
                            }
                        ]
                    },
                    "meta": {
                        "access_token": "AQXYBt9MSjy2OZFEXM2Ad0UdNNSxes2wnL9LVy8XdSGOJzx9sJmQBeKpVkUNMHcwXBc5LL6Dk37rEs6stP88SnudNHqhDN9_rnLH2iH-G7rsEs8Iw3MUfriYFtlT4xijBGtgR0X5ojQ5HR_m7oea696re0HpG8n2sR_CAYeWPe7xhQnVAV0glvVEiOwEOdsdTKTJ_mxSFiQY0IbxpNvHa1o9O3mhsN7VoQicwjiCPXTnFntxnA8eM9CkYGWzU0Z8McHw9fwvqCzlk1tm1HqXmtcLVoRZSvfKP4GT5yauWut5DFc9ykZtW7Vig6UI0s0ImNJdcOuump8_TA5SVO6Z7hLKuUdvsA",
                        "expires_in": 5183999,
                        "refresh_token": "AQXK1Mcb_LvOLBIVsKsAwXBJSpIRZXRzJYnSFnQhG4V5VQ39Xhyv7msKQr4uczfiTT0F5nqZSYll1LR-D1ly7dxkigteGDOpcZMpXC2Z1qP8SQnm-x2IKa1_nWDkWLUxxIVYFjJx_fmWM2RfBEyGQxL6pUaj_MS5MQYs2qzktOgFAOD-6qUuN221HBwJRBFx_A38gn-AqfjPMB4crC8PPIo_r3LtRLm573Rag-tSUmz8m80XK5bC8rfew0wXrFFdj_zfcMY11bAePgjt7UtjwXSnKMPuIJq11whJy-MOC7lqP7-8IWODs0hRaE7hx01V9CZvgIfsXehuZKlPMwgXG8ygksewEA",
                        "refresh_token_expires_in": 31536059,
                        "scope": "r_basicprofile,r_organization_social,rw_organization_admin,w_organization_social"
                    }
                };

                //console.log(resp.organizations.elements);
                const resp = await get_linkedin_token(state_json.org, code, state_json.type);
                console.log(resp);
                meta_data = resp.meta;
                set_orgs(resp.organizations.elements);
                //console.log(resp);
            } else {
                console.log(state_json);
                is_loading_token = true;
                const resp = await get_linkedin_token(state_json.org, code, state_json.type);
                is_loading_token = false;
                if (resp && resp.accessToken && resp.get_linkedin_token) {
                    window.location.href = `/`;
                } else {
                    alert("Something went wrong. Please try again later...");
                    window.location.href = `/`;
                }
            }

        } catch (e) {
            alert("Something went wrong. Please try again later.");
            is_loading_token = false;
            // window.location.href = `/`;
        }

    }

    useEffect(() => {
        getLinkedinToken();
    }, [])


    return (
        <DashboardLayout>
            {state_json && state_json.type && state_json.type !== "organization" ? <div className="col-span-12 bg-white text-center py-20">
                <h1 className="text-xl">{t("finalizing_linkedin_connection")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></h1>
            </div> : <div className="col-span-12 bg-white text-center py-20">
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-sky-50">
                    <img src={LinkedinImgIcon} className="h-8 w-8" alt="LinkedIn icon" />
                </div>
                <div className="font-bold text-xl">{t("select_your_organization")}</div>

                <div className="mt-6 text-left bg-white px-2 py-4 rounded-md">
                    <ul role="list" className="-my-5 divide-y divide-gray-200">
                        {orgs && orgs.map((page, index) => (
                            <li key={page.organizationalTarget} className="py-5 hover:bg-slate-50 px-2" >
                                <div className="">
                                    <div className="text-sm font-semibold text-gray-800">
                                        <span className=" inset-0" aria-hidden="true" />
                                        {page['organizationalTarget~'].localizedName}
                                        <button onClick={() => handleSelectOrg(page, index)} className="mt-2 cursor-pointer float-right text-sm font-medium text-blue-600 bg-sky-100 rounded-md px-2 py-1">
                                            {t("select")}
                                        </button>
                                    </div>
                                    <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                                        {/* {page.category_list.map((cat, i) => {
                                        return `${cat.name} ${i < page.category_list.length - 1 ? ", " : ""}`;
                                    })} */}
                                    </p>
                                    <div>

                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>}
        </DashboardLayout>
    );
}
import { useState } from 'react'
import { Switch } from '@headlessui/react'
import { pause_channel } from '../../../utils/api';
import i18next from '../../../i18next';

const { t } = i18next;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ToggleChannel(props) {
  const [enabled, setEnabled] = useState(props.enabled);

  const handleChange = async (e) => {
    setEnabled(e);
    const { channel } = props.channel;
    const res = await pause_channel(channel._id);
    console.log(res);
  }

  return (
    <Switch
      checked={enabled}
      onChange={handleChange}
      className={classNames(
        enabled ? 'bg-indigo-600' : 'bg-gray-200',
        'relative z-300 inline-flex h-6 float-right w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
      )}
    >
      <span className="sr-only">{t("use_settings")}</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  )
}

import { useEffect, useState } from "react";
import i18next from '../../i18next';

import DashboardLayout from "../../components/DashboardLayout";
import { get_facebook_pages, save_facebook_page, save_organization } from "../../utils/api";
import { handleAjoutFacebookChannel } from "../../utils/channels";
import failed_pyt from "../../res/img/fall_coming.svg";
import { Link } from "react-router-dom";
let pages_loaded = false;

const { t } = i18next;


export function FacebookPages(){
    const [global_state, set_global_state] = useState({error : {},loading_pages : false,pages : [], facebook_org_name : "", channel_id : null});
    const [loading, set_loading] = useState(false);

    const fb_pages = async(payload) => {
        if(payload.code && payload.state && payload.state.org){
            try{
                
                pages_loaded = true;
                set_global_state({
                    ...global_state,
                    loading_pages : true, 
                    facebook_org_name : payload.state.org_name
                });
                
                await handleAjoutFacebookChannel(payload.state.org);
                const pages =  await get_facebook_pages({code:payload.code,org_id:payload.state.org});
                
                set_global_state({
                    ...global_state,
                    loading_pages : false, 
                    pages: pages.pages,
                    channel_id: pages.channel_id
                });                
            }catch(ex){
                console.log(ex);
                pages_loaded = false;
                set_global_state({...global_state,loading_pages : false});
            }
            
        }
        
    }

    const selectFbPage = async (index) => {
        console.log(index);
        console.log(global_state.pages[index]);

        const channel = {
            channel_id: global_state.channel_id,
            facebook_page: global_state.pages[index]
        }
    
        console.log(channel);
        const res = await save_facebook_page(channel);
        window.location.href="/";
    }

    useEffect(() => {
        if(!pages_loaded){
            try{
                const url = new URL(window.location.href);
                const code = url.searchParams.get("code");
                const state = url.searchParams.get("state") ? JSON.parse(url.searchParams.get("state")) : null;
                const error = url.searchParams.get("error");
                if(error){
                    // === "access_denied"
                    const error_description = url.searchParams.get("error_description");
                    const error_reason = url.searchParams.get("error_reason");
                    set_global_state({...global_state, error : {error, error_description, error_reason}});
                }else{
                    fb_pages({code, state});
                }
            }catch(ex){
                console.log(ex);
            }
        }        
    });

    return (
        <DashboardLayout>
            {global_state.error && global_state.error.error ? <div className="col-span-12 text-center">
                <div className=" ml-auto mr-auto">
                    <div className=" text-center bg-white px-2 py-4 rounded-md my-10 py-20">
                        <div><img src={failed_pyt} alt={t("adding_page_failed")} className="w-72 inline"/></div>
                        
                        <div className="text-xl">{t('there_was_an_issue_adding_the_page')}</div>
                        <div className="mt-6">{global_state.error.error_description}</div>
                        <div>
                            <Link to="/">
                                <button className="mt-4 cursor-pointer font-medium bg-pubcm2 hover:bg-pubcm1 text-white rounded-md px-4 py-2">
                                    {t("go_to_dashboard")}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div> : <div className="col-span-12 text-center">
                <h1 className="text-xl">{t("select_your_facebook_page")} {global_state.facebook_org_name ? `${t("for")} ${global_state.facebook_org_name}` : ''}</h1>
                {/* Add list of facebook pages */}
                {
                    global_state.loading_pages ? <div>{t("loading")}</div> : <div></div>
                }
                <div className=" w-72 md:w-96 ml-auto mr-auto">
                    <div className="mt-6 text-left bg-white px-2 py-4 rounded-md">
                        <ul role="list" className="-my-5 divide-y divide-gray-200">
                        {global_state.pages.map((page, index) => (
                            <li key={page.id} className="py-5" onClick={() => selectFbPage(index)}>
                            <div className="">
                                <div className="text-sm font-semibold text-gray-800">
                                        <span className=" inset-0" aria-hidden="true" />
                                        {page.name}
                                        <button className="mt-2 cursor-pointer float-right text-sm font-medium text-blue-600 bg-sky-100 rounded-md px-2 py-1">
                                            {t("select")}
                                        </button>
                                </div>
                                <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                                    {page.category_list.map((cat, i) => {
                                        return `${cat.name} ${i < page.category_list.length - 1 ? ", " : ""}`;
                                    })}
                                </p>
                                <div>
                                    
                                </div>
                            </div>
                            </li>
                        ))}
                        </ul>
                    </div>
                    {/* <div className="mt-6">
                        <a
                        href="#"
                        className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                        >
                        Change Facebook Account
                        </a>
                    </div> */}
                </div>
            </div>}
        </DashboardLayout>
    );
}


 function Form() {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [website, setWebsite] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [tags, setTags] = useState("");
    const [country, setCountry] = useState("United States");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const save_ = await save_organization({name, description, website, email, phone, country, tags});
            console.log(save_);
            if(save_ && save_.admin){
                alert(t("success"));
                window.location.href = "/";
            }
        }catch(ex){
            console.log(ex);
        }
       
        //console.log(name, description, website, email, phone, country);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "name":
                setName(value);
                break;
            case "description":
                setDescription(value);
                break;
            case "website":
                setWebsite(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "phone":
                setPhone(value);
                break;
            case "country":
                setCountry(value);
                break;
            case "tags":
                setTags(value);
                break;
            default:
                break;
        }
    }


    return (
      <>
        <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
            <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t("organization_name")}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                    type="text"
                    onChange={handleChange}
                    name="name"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t("description")}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <textarea
                    type="text"
                    name="description"
                    onChange={handleChange}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    ></textarea>
                </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t("tags")}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                    type="text"
                    onChange={handleChange}
                    name="tags"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t("website")}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                    type="text"
                    name="website"
                    onChange={handleChange}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t("website")}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t("phone_number")}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                    type="text"
                    onChange={handleChange}
                    name="phone"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                </div>

                

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="region" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t("country")}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                        id="region"
                        name="country"
                        onChange={handleChange}
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                        >
                        <option value="United States">United States</option>
                        <option value="Congo">Congo</option>
                        <option value="Mexico">Mexico</option>
                    </select>
                </div>
                </div>

                
                <div>
                <label htmlFor="region" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <button type="submit" className=" px-4 py-2 bg-blue-600 text-white rounded-md">
                        {t("save")}
                    </button>
                </div>
                    
                </div>
            </div>
            </div>
        </form>
      </>
    )
  }
  